import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';

export type TimerProps = {
    date: Date
}

const Timer: React.FunctionComponent<TimerProps> = (props: TimerProps) => {
    
    var [now, setNow] = useState<Date>(new Date())

    useEffect(() => {
        setTimeout(() => {
          setNow(new Date());
        }, 1000);
      });
    
    return <Fragment>{moment.duration(moment(now).diff(moment(props.date))).format("hh:mm'ss\"")}</Fragment>
}

export default Timer