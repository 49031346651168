// Users
export const USERS_COL = "users"

export const CARTS_COL = "carts"
export const CARTS_ITEMS_COL = "cart_items"

export const SMARTEREVENTS_COL = "smarterEvents"
export const DATA_DOC = "data"


// Events
export const EVENTS_COL = "events"
export const EVENTS_EDITIONS_COL = "editions"

// Attendees
export const RACES_COL = "races";
export const RACE_MODULE_PROP = "raceModule";
export const RACE_CATEGORIES_COL = "race_categories";
// export const RACE_SOLO_CATEGORIES_COL = "race_solo_categories";
// export const RACE_TEAM_CATEGORIES_COL = "race_team_categories";
export const RACE_PARTICIPANTS_COL = "race_participants";
export const RACE_INDIVIDUALS_COL = "race_individuals";
export const RACE_TEAMS_COL = "race_teams";
export const RACE_LINECROSS_COL = "race_linecross";
export const RACE_RESULTS_COL = "race_results";

// POS
export const POS_MODULE_PROP = "posModule";
export const POS_CATEGORIES_COL = "pos_categories";
export const POS_ITEMS_COL = "pos_items";
export const POS_SALES_COL = "pos_sales";

export const BILLS_COL = "bills"

