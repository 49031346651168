

type BreadcrumbProps = {
    children: any
}

type BreadcrumbItemProps = {
    children: any;
}

interface BreadcrumbSubComponents {
    Item: React.FunctionComponent<BreadcrumbItemProps>
}

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> & BreadcrumbSubComponents = (props: BreadcrumbProps) => {
    return <ul className="breadcrumb">
        {props.children}
    </ul>
}

const Item = (props: BreadcrumbItemProps) => {
    return <li>{props.children}</li>
}

Breadcrumb.Item = Item

export default Breadcrumb
