import { yupResolver } from '@hookform/resolvers/yup';
import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Form from '../../../components/Form';
import { UserProfileContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import * as SMARTEREVENT from '../../../constants/smarterEvent';
import { SmarterEvent, SmarterEventEdition } from '../../../types/smarterEvent';
import { getIdFromName } from '../../../utils';

type Props = {
    handleSubmit: (smarterEvent: SmarterEvent, smarterEventEdition: SmarterEventEdition) => void,
}

const SmarterEventForm: React.FunctionComponent<Props> = (props: Props) => {

    const testEventIdTaken = (value: string) => {
        return firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(value).get()
            .then((docSN) => {
                return !docSN.exists
            })
    }

    const validationSchema = Yup.object({
        eventName: Yup.string()
            .required('Nom requis')
            .min(4, 'Au moins 4 caractères'),
        eventId: Yup.string()
            .required('Identifiant requis')
            .min(4, 'Au moins 4 caractères')
            .test("event-id-taken", "Identifiant non disponible", testEventIdTaken),
        editionName: Yup.string()
            .required('Nom requis'),
        editionId: Yup.string()
            .required('Identifiant requis'),
        fromDate: Yup.date()
            .typeError("Date requise")
            .required("Date requise"),
        toDate: Yup.date()
            .typeError("Date requise")
            .required("Date requise")
    });

    const { register, handleSubmit, errors, watch } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const userProfile = useContext(UserProfileContext)!;

    const onSubmit = (values: any) => {
        var smarterEvent: SmarterEvent = {
            id: values.eventId,
            name: values.eventName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            createdBy: userProfile.id!,
            confidentiality: SMARTEREVENT.EVENT_EDITION_UNLISTED,
            raceModule: {
                enabled: true,
            },
            posModule: {
                enabled: false
            },
            authz: {
                owner: userProfile.id!,
                admins: [userProfile.id!]
            },
            paymentModes: values.paymentModes
        }
        var smarterEventEdition: SmarterEventEdition = {
            id: values.editionId,
            name: values.editionName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            createdBy: userProfile.id!,
            fromDate: values.fromDate,
            toDate: values.toDate,
            confidentiality: SMARTEREVENT.EVENT_EDITION_UNLISTED,
            references: {
                eventId: values.eventId,
                eventName: values.eventName,
            },
            raceModule: {
                enabled: true,
                requireAddress: false,
                requirePhoneNumber: true,
                races: {}
            }
        }
        props.handleSubmit(smarterEvent, smarterEventEdition)
    }

    const eventName = watch("eventName")
    const editionName = watch("editionName")
    const eventId = watch("eventId")
    const editionId = watch("editionId")

    return (
        <Form onSubmit={handleSubmit(onSubmit)} id="newSmarterEventForm" register={register} errors={errors} yupSchema={validationSchema}>
            <Form.InputText id="eventName" label="Nom du SmarterEvent" />
            <Form.InputText id="eventId" label="Identifiant du SmarterEvent" placeholder="Nom court sans espace ni caractères spéciaux (URL)" defaultValue={getIdFromName(eventName)} />
            {eventId?.length >= 3 ? <span>Votre adresse sera: http://{eventId}.smarterevents.ch</span> : null}
            <Form.InputText id="editionName" label="Nom de la première édition" placeholder="par exemple '2021' ou '1ère édition'" />
            <Form.InputText id="editionId" label="Identifiant de cette édition" placeholder="Nom court sans espace ni caractères spéciaux (URL)" defaultValue={getIdFromName(editionName)} />
            {eventId?.length >= 3 && editionId?.length >= 1 ? <span>L'adresse de votre première édition sera: http://{eventId}.smarterevents.ch/{editionId}</span> : null}
            <Form.InputDate id="fromDate" label="Début de la première édition" defaultValue={getIdFromName(editionName)} />
            <Form.InputDate id="toDate" label="Fin de la première édition" defaultValue={getIdFromName(editionName)} />
        </Form>
    );
}

export default SmarterEventForm;