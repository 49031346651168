import moment from 'moment';
import { Fragment } from 'react';

export type DateRangeProps = {
    from: Date | null,
    to: Date | null,
}

const DateRange: React.FunctionComponent<DateRangeProps> = (props: DateRangeProps) => {

    if ((props.from != null) && (props.to != null)) {
        if (props.from.getDate() === 1 && props.from.getMonth() === 0 )
            return <Fragment>{moment(props.from).format("YYYY")} - {moment(props.to).format("YYYY")}</Fragment>
        else
            return <Fragment>{moment(props.from).format("DD/MM/YYYY")} - {moment(props.to).format("DD/MM/YYYY")}</Fragment>
    }

    if ((props.from != null) && (props.to == null)) {
        if (props.from.getDate() === 1 && props.from.getMonth() === 0 )
            return <Fragment>Né à partir de {moment(props.from).format("YYYY")}</Fragment>
        else
            return <Fragment>Né à partir de {moment(props.from).format("DD/MM/YYYY")}</Fragment>
    }

    if ((props.from == null) && (props.to != null)) {
        if (props.to.getDate() === 31 && props.to.getMonth() === 11 )
            return <Fragment>Né avant {moment(props.to).format("YYYY")}</Fragment>
        else
            return <Fragment>Né le {moment(props.to).format("DD/MM/YYYY")} ou avant</Fragment>
    }

    return <Fragment>Tout âge</Fragment>
}

export default DateRange