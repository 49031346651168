import "firebase/firestore";
import React, { Fragment, useContext } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { UserProfileContext } from '../../constants/context';
import * as ROUTES from '../../constants/routes';
import Footer from '../../layouts/Footer';
import TopBarMenu from '../../layouts/user/TopBarMenu';
import Console from '../../pages/console/Index';
import SmarterEventEditionRouter from './SmarterEventEditionRouter';

const ConsoleRouter: React.FunctionComponent = () => {

    const userProfile = useContext(UserProfileContext)!;

    const location = useLocation()

    // if (userProfile === null) return <Redirect to={{
    //     pathname: ROUTES.SIGNIN,
    //     state: { redirect: location.pathname }
    // }} />

    if (userProfile === null) return <Link to={{
        pathname: ROUTES.SIGNIN,
        state: { redirect: location.pathname }
    }}>Vous devez vous connecter</Link>

    return <Fragment>
        <TopBarMenu isAdmin={true} />
        <Switch>
            <Route path={`/:eventId/:editionId`} component={SmarterEventEditionRouter} />
            <Route exact path={ROUTES.LANDING} component={Console} />
        </Switch>
        <Footer />
    </Fragment >
}

export default ConsoleRouter