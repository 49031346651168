import InLineEditor from '@ckeditor/ckeditor5-build-inline';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import parse from 'html-react-parser';
import React, { Fragment, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseUploadAdapter from '../ckEditor/firebaseUploadAdapter';
import Loader from './Loader';

type Props = {
    cmsReference: firebase.firestore.DocumentReference
    allowedToEdit: boolean
    defaultText: string
}

const Cms: React.FunctionComponent<Props> = (props: Props) => {

    const [cmsContent, loading] = useDocumentData<{ data: string }>(props.cmsReference)
    const [editable, setEditable] = useState<boolean>(false)
    const [editor, setEditor] = useState<any>(false)

    if (loading) {
        return <Loader/>
    }

    const saveContent = () => {
        props.cmsReference.set({ data: editor.getData() }).then(() => {setEditable(false)}).catch((error) => {console.log(error)})
    }

    var config = {
        // extraPlugins: [ImageResize],
        // removePlugins: ['ImageUpload'],
        // plugins: ['Image', 'EasyImage', 'Heading', 'ImageUpload']
        mediaEmbed: {
            previewsInData: true
        },
        image: {
            styles: [
                // This option is equal to a situation where no style is applied.
                'full',

                // This represents an image aligned to the left.
                'alignLeft',

                // This represents an image aligned to the right.
                'alignRight',
                'side',
                'alignCenter'
            ]
        }
    }

    return <Fragment>
        <div className="cms">
            {!editable ?
                cmsContent?.data ?
                    <div className="ck-content">{parse(cmsContent?.data!)}</div> :
                    <h1>{props.defaultText}</h1> : 
            <CKEditor
                editor={InLineEditor}
                config={config}
                data={cmsContent?.data || "<h1>" + props.defaultText + "</h1>"}
                //     toolbar="[ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]",
                // heading= {
                //     options: [
                //         {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                //         {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                //         {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                //     ]
                // }

                onReady={(value: any) => {
                    if (value != null) {
                        value.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                            return new FirebaseUploadAdapter(loader, props.cmsReference.path);
                        };
                        setEditor(value)
                    }
                }}
                // onChange={(event: any, editor: any) => {
                //     const data = editor.getData();
                // }}
                // onBlur={(event: any, editor: any) => {
                //     // setEditable(false)
                //     saveContent()
                //     console.log(cmsContent?.data)
                //     console.log(editor.getData())
                //     // setEditable(false)
                //     if (cmsContent?.data !== editor.getData())
                //         alert("N'oubliez pas d'enregistrer")
                // }}
                // onFocus={(event: any, editor: any) => {
                // }}
            />
            }
            {props.allowedToEdit ?
                editable ?
                    <button className="cms-button" onClick={() => {
                        setEditable(false)
                        saveContent()
                    }}>Enregistrer</button>
                    : <button className="cms-button" onClick={() => {
                        console.log(editor)
                        // editor.isReadOnly = false
                        // editor.editing.view.focus()
                        setEditable(true)
                    }
                    }>Éditer</button>
                : null}


        </div>
    </Fragment >
}

export default Cms