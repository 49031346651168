import 'moment/locale/fr';
import React, { Fragment } from "react";
import { Calendar } from 'react-bootstrap-icons';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import Card from "../../../components/Card";
import { SmarterEventEdition } from "../../../types/smarterEvent";
import { getDateFromTimestampOrDate } from "../../../utils";
type Props = {
    smarterEventEdition: SmarterEventEdition,
    isAdmin: boolean
}

const SmarterEventEditionCard: React.FunctionComponent<Props> = (props: Props) => {

    return <Card>
        <Card.Header>
            <h1>{props.smarterEventEdition.name}</h1>
        </Card.Header>
        <Card.Data>
            <Calendar /> <Moment local locale="fr" format="LL" className="date">{getDateFromTimestampOrDate(props.smarterEventEdition.fromDate!)!}</Moment>{(props.smarterEventEdition.toDate !== undefined && (props.smarterEventEdition.fromDate! !== props.smarterEventEdition.toDate!)) ? <Fragment> - <Moment local locale="fr" format="LL" className="date">{getDateFromTimestampOrDate(props.smarterEventEdition.toDate!)!}</Moment></Fragment> : null}
        </Card.Data>
        {props.smarterEventEdition.shortDescription !== undefined ?
            <Card.Body>
                {/* <h2 className="event-type">Événement sportif</h2> */}
                <p>{props.smarterEventEdition.shortDescription}</p>
            </Card.Body>
            : null}
        <Card.Footer>
            {/* <button>Modifier</button> */}
            <Link to={`/${props.smarterEventEdition.references.eventId}/${props.smarterEventEdition.id}`}><Button>Accéder</Button></Link>
        </Card.Footer>
    </Card>


    // <Card className="mt-3 smarterEventEdition">
    //     <div>
    //         {props.isAdmin ?
    //             <Card.Header className="text-light d-flex">
    //                 <div>
    //                     <Card.Title>{props.smarterEventEdition.smarterEvent.name}</Card.Title>
    //                     <Card.Subtitle>{props.smarterEventEdition.name}</Card.Subtitle>
    //                 </div>
    //                 <Link to={`/${props.smarterEventEdition.smarterEvent.id}/${props.smarterEventEdition.id}`} className="ml-auto"><Button>Admin</Button></Link>
    //             </Card.Header>
    //             :
    //             <a href={window.location.protocol + `//${props.smarterEventEdition.smarterEvent.id}.${domain}/${props.smarterEventEdition.id}`}>
    //                 <Card.Header className="text-light">
    //                     <Card.Title>{props.smarterEventEdition.smarterEvent.name}</Card.Title>
    //                     <Card.Subtitle>{props.smarterEventEdition.name}</Card.Subtitle>
    //                 </Card.Header>
    //             </a>
    //         }
    //     </div>
    //     <Card.Body>
    //         <div className="card-data">
    //             {props.smarterEventEdition.fromDate != null ?
    //                 <Card.Text className="text-primary small date"><CalendarEvent /> <Moment local locale="fr" format="LL" className="date">{getDateFromTimestampOrDate(props.smarterEventEdition.fromDate)}</Moment></Card.Text>
    //                 : null}
    //             <Card.Text className="text-primary small location"><Geo /> <span className="location">Charmey</span></Card.Text>
    //         </div>
    //         <Card.Text>Evénement sportif</Card.Text>
    //         <Card.Text className="small">{props.smarterEventEdition.shortDescription}</Card.Text>
    //     </Card.Body>
    // </Card>
}

export default SmarterEventEditionCard