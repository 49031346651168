import "firebase/auth";
import React, { useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import { RaceContext } from '../../constants/context';
import * as ROUTES from '../../constants/routes';
import { getDateFromTimestampOrDate } from "../../utils";

type Props = {
}

const TopBarRaceUserMenu: React.FunctionComponent<Props> = (props: Props) => {

    const race = useContext(RaceContext);
    const location =useLocation();

    // const [menuOpen, setMenuOpen] = useState<boolean>(false)// 

    // const toggleMenu = () => {
    //     setMenuOpen(!menuOpen)
    // }

    var now: number = new Date().getTime()

    var registrations = false
    var registrationsOpened = false
    if (race != null && race.registrations) {
        registrations = true;
        var startRegistrationTime = getDateFromTimestampOrDate(race.registrations.startTime)!.getTime()
        var endRegistrationTime = getDateFromTimestampOrDate(race.registrations.endTime)!.getTime()
        if (now < endRegistrationTime && now > startRegistrationTime) {
            registrationsOpened = true
        }
    }
    return <div id="user-menubar">
        <nav id="user-menubar-nav">
            <ul>
                <li><Link to={`/${race!.references.editionId}${ROUTES.EVENTS_RACES}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                    &lt; Toutes les courses
                        </Link></li>
                        { !location.pathname.endsWith(race!.id) ?
                <li><Link to={`/${race!.references.editionId}${ROUTES.EVENTS_RACE}/${race!.id}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                    Infos
                        </Link></li>: null}
                {!location.pathname.endsWith(ROUTES.EVENTS_RACE_SIGNUP) ? (registrations && !registrationsOpened) ?
                    <li>Les inscriptions sont actuellement fermées</li>
                    :
                    <li><Link to={`/${race!.references.editionId}${ROUTES.EVENTS_RACE}/${race!.id}${ROUTES.EVENTS_RACE_SIGNUP}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                        Inscriptions
                        </Link></li>
                :null}
                {/* <li>Test</li> */}
                {!location.pathname.endsWith(ROUTES.EVENTS_RACE_PARTICIPANTS) ? race != null ?
                    <li><Link to={`/${race!.references.editionId}${ROUTES.EVENTS_RACE}/${race!.id}${ROUTES.EVENTS_RACE_PARTICIPANTS}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                        Liste de départ
                    </Link></li> : null : null}
                {!location.pathname.endsWith(ROUTES.EVENTS_RACE_LIVERESULTS) ? race!.chrono?.enabled ?
                    <li><Link to={`/${race!.references.editionId}${ROUTES.EVENTS_RACE}/${race!.id}${ROUTES.EVENTS_RACE_LIVERESULTS}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                        Résultats live
                    </Link></li> : null : null}
            </ul>
        </nav>
    </div>
}

export default TopBarRaceUserMenu