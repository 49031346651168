import React, { Fragment } from "react";
import { Calendar, Cash, Person } from 'react-bootstrap-icons';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import DateRange from "../../../../components/DateRange";
import { SoloCategory } from "../../../../types/race";
import { getDateFromTimestampOrDate } from "../../../../utils";

type Props = {
    category: SoloCategory,
    editCategory?: (category: SoloCategory) => void
    deleteCategory?: (category: SoloCategory) => void
}

const CategoryCard: React.FunctionComponent<Props> = (props: Props) => {

    const { editCategory, deleteCategory } = props

    return <Fragment>
        <Card>
            <Card.Header>
                <h1>{props.category.name}</h1>
            </Card.Header>
            <Card.Data>
                <p><Person/> {props.category.gender === "male" ? "Hommes" : props.category.gender === "female" ? "Femmes" : props.category.gender === "any" ? "Mixte" : "???"}</p>
                <p><Calendar /> <DateRange from={getDateFromTimestampOrDate(props.category.birthDateFrom)} to={getDateFromTimestampOrDate(props.category.birthDateFrom)}/></p>
                <p><Cash /> Frais d'inscription: {props.category.price} CHF</p>
            </Card.Data>
            <Card.Footer>
                    {editCategory != null ?
                        <Button onClick={() => editCategory(props.category)}>Editer</Button>
                        : null}
                    {deleteCategory != null ?
                        <Button onClick={() => deleteCategory(props.category)}>Effacer</Button>
                        : null}
            </Card.Footer>
        </Card>
    </Fragment>
}

export default CategoryCard