import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { RaceContext, SmarterEventEditionContext, UserProfileContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import * as ROUTES from '../../constants/routes';
import RaceConsolePage from '../../pages/race/console/RaceConsolePage';
import { Race } from '../../types/race';

const RaceConsoleRouter: React.FunctionComponent = () => {

    const smarterEventEdition = useContext(SmarterEventEditionContext);
    const { raceId } = useParams<{ editionId: string, raceId: string }>();

    const [race, loadingRace] = useDocumentDataOnce<Race>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition!.references.eventId)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
        .collection(FIRESTORE.RACES_COL).doc(raceId), { idField: "id" });

    const userProfile = useContext(UserProfileContext)!;

    if (userProfile === null) return <Redirect to={ROUTES.SIGNIN} />
    if (loadingRace) return null

    return <Fragment>
        <RaceContext.Provider value={race!}>
            <Switch>
                <Route path={`/`} component={RaceConsolePage} />
            </Switch>
        </RaceContext.Provider>
    </Fragment >
}

export default RaceConsoleRouter