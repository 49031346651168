import React, { Fragment } from "react";
import Button from "../../../../components/Button";
import Card from '../../../../components/Card';
import { SoloCategory } from "../../../../types/race";
import CategoryCard from "./CategoryCard";

type Props = {
    categories: SoloCategory[],
    newRegistration?: (category: SoloCategory) => void
    newContactRegistration?: (category: SoloCategory) => void
    editCategory?: (category: SoloCategory) => void
    deleteCategory?: (category: SoloCategory) => void
    addCategory?: () => void
}

const CategoriesList: React.FunctionComponent<Props> = (props: Props) => {

    return <Fragment>
        <h2>Catégories Solo</h2>
        <Card.Deck>
            {(props.categories.length === 0) ? <p>Il n'existe pas encore de catégorie</p> :
                props.categories.map((category: SoloCategory) => {
                    return <CategoryCard key={category.id} editCategory={props.editCategory} deleteCategory={props.deleteCategory} category={category} />
                })}
        </Card.Deck>
        <Button onClick={props.addCategory}>Ajouter une catégorie</Button>
    </Fragment>

}

export default CategoriesList