import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useContext } from 'react';
import Cms from '../../components/Cms';
import { RaceContext, SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import { hasAdminAccess } from '../../services/authorization';

const RacePage: React.FunctionComponent = () => {

    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const userProfile = useContext(UserProfileContext);

    const race = useContext(RaceContext)!;

    return <Fragment>
        <Cms
            allowedToEdit={userProfile != null && hasAdminAccess(smarterEvent, userProfile.id!)!}
            defaultText={`${smarterEvent?.name} ${smarterEventEdition?.name} - ${race?.name}`}
            cmsReference={firebase.firestore()
                .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
                .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
                .collection(FIRESTORE.RACES_COL).doc(race!.id)
                .collection("cms").doc("frontpage")} />
    </Fragment>
}

export default RacePage