import firebase from 'firebase/app';
import "firebase/firestore";
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { UserProfileContext } from '../../constants/context';
import * as ROUTES from '../../constants/routes';
import Footer from '../../layouts/Footer';
import TopBarMenu from '../../layouts/user/TopBarMenu';
import BillsManagement from "../../pages/admin/BillsManagement";
import Admin from "../../pages/admin/Index";

const AdminRouter: React.FunctionComponent = () => {

    const userProfile = useContext(UserProfileContext)!;

    const location = useLocation()

    const [isAdmin, setIsAdmin] = useState<boolean>()

    useEffect(() => {
      firebase.auth().currentUser?.getIdTokenResult()
        .then((idTokenResult) => {
          console.log(idTokenResult.claims.role)
          // Confirm the user is an Admin.
          if (idTokenResult.claims.role === "admin") {
            setIsAdmin(true)
          } else {
            setIsAdmin(false)
          }
        })
    }, [])
    
    if (userProfile === null) return <Link to={{
        pathname: ROUTES.SIGNIN,
        state: { redirect: location.pathname }
    }}>Vous devez vous connecter</Link>

    
    if (isAdmin != null) {
        if (isAdmin)
    return <Fragment>
        <TopBarMenu isAdmin={true} />
        <Switch>
            <Route exact path={ROUTES.LANDING} component={Admin} />
            <Route exact path={ROUTES.BILLS} component={BillsManagement} />
        </Switch>
        <Footer />
    </Fragment >
        else return <Link to={{
            pathname: ROUTES.SIGNIN,
            state: { redirect: location.pathname }
        }}>Vous devez vous connecter</Link>
    } else return null
}

export default AdminRouter