import classNames from 'classnames';
import { ChangeEvent, Children, cloneElement, isValidElement, ReactNode, useState } from 'react';
import '../assets/stylesheets/components/dropdown.scss';

export type DropdownProps = {
    children: ReactNode | ReactNode[],
    className?: string
}

export type DropdownChildProps = {
    children: ReactNode | ReactNode[],
    className?: string
    _toggleListActive?: () => void,
    _listActive?: boolean
}

export type DropdownItemProps = {
    children: ReactNode | ReactNode[],
    className?: string,
    _listActive?: boolean,
    onClick?: () => void
    _setListActive?: (active: boolean) => void,
}

export type DropdownSearchProps = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string
    _setListActive?: (active: boolean) => void,

}

interface DropdownSubComponents {
    Toggle: React.FunctionComponent<DropdownChildProps>
    List: React.FunctionComponent<DropdownChildProps>
    Item: React.FunctionComponent<DropdownItemProps>
    Search: React.FunctionComponent<DropdownSearchProps>
}

const Dropdown: React.FunctionComponent<DropdownProps> & DropdownSubComponents = (props: DropdownProps) => {

    const [listActive, setListActive] = useState<boolean>(false);

    const toggleListActive = () => {
        setListActive(!listActive)
    }

    const augmentChild = (child: ReactNode): any => {
        if (isValidElement(child)) {
            if (child!.props?.children) {
                return cloneElement(child, { _toggleListActive: toggleListActive, _listActive: listActive, _setListActive: setListActive } as Partial<unknown>, augmentChildren(child.props?.children));
            }
            else {
                return cloneElement(child, { _toggleListActive: toggleListActive, _listActive: listActive, _setListActive: setListActive } as Partial<unknown>);
            }
        }
        else return child
    }

    const augmentChildren = (children: ReactNode[]): any => {
        if (!Array.isArray(children)) {
            return augmentChild(children)
        }
        if (Array.isArray(children)) {
            const childrenWithProps: any = Children.map(children, (child) => {
                if (Array.isArray(child)) {
                    return augmentChildren(child)
                }
                else {
                    return augmentChild(child)
                }
            })
            return childrenWithProps
        }
    }

    const childrenWithProps = (Array.isArray(props.children)) ?
        augmentChildren(props.children)
        : augmentChild(props.children);

    return <div className={classNames(props.className, "dropdown")}>
        {childrenWithProps}
    </div>
}

const Toggle = (props: DropdownChildProps) => {
    return <button className="dropdown__toggle" onClick={props._toggleListActive}>
        {props.children}
    </button>
}

const List = (props: DropdownChildProps) => {
    return <ul className={classNames("dropdown__list", { "active": props._listActive })}>
        {props.children}
    </ul>
}

const Item = (props: DropdownItemProps) => {
    return <li className={classNames("dropdown__item", { "active": props._listActive })} onClick={() => { if (props.onClick) props.onClick(); props._setListActive!(false)}}>
        {props.children}
    </li>
}

const Search = (props: DropdownSearchProps) => {
    return <input className={classNames("dropdown__search")} onChange={(event) => props.onChange(event)} placeholder={props.placeholder}  onClick={() => props._setListActive!(true)}/>
}

Dropdown.Toggle = Toggle
Dropdown.List = List
Dropdown.Item = Item
Dropdown.Search = Search

export default Dropdown
