import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useContext, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Redirect } from 'react-router-dom';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { UserProfileContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import * as ROUTES from '../../constants/routes';
import { SmarterEvent, SmarterEventEdition } from '../../types/smarterEvent';
import SmarterEventForm from '../smarterEvent/components/SmarterEventAndEditionForm';
import SmarterEventContainer from '../smarterEvent/components/SmarterEventContainer';

const Console: React.FunctionComponent = () => {

    const userProfile = useContext(UserProfileContext)!;
    const [smarterEventId, setSmarterEventId] = useState<string>();
    const [showModal, setShowModal] = useState<boolean>(false);

    const [smarterEvents] = useCollectionData<SmarterEvent>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).where("authz.admins", "array-contains", userProfile.id).orderBy("name", "asc"), { idField: "id" });

    if (smarterEventId) {
        return <Redirect to={`${ROUTES.EVENT}/${smarterEventId}`} />
    }

    const handleAddSmarterEvent = (smarterEvent: SmarterEvent, smarterEventEdition: SmarterEventEdition) => {
        let eventRef = firebase.firestore()
            .collection(FIRESTORE.EVENTS_COL)
            .doc(smarterEvent.id)
        let editionRef = eventRef
            .collection(FIRESTORE.EVENTS_EDITIONS_COL)
            .doc(smarterEventEdition.id)

        eventRef.set(smarterEvent).then(() => {
            editionRef.set(smarterEventEdition).then(() => {
                setSmarterEventId(smarterEventId)
                setShowModal(false)
                // props.handleSubmit();
            }).catch(error => {
            })
        }).catch(error => {
        })
    }

    return <Fragment>
        <main>
            <h1>Console</h1>
            {smarterEvents?.map((smarterEvent) => {
                return <SmarterEventContainer key={smarterEvent.id} smarterEvent={smarterEvent} />
            })}
            <div className="center">
                <Button onClick={() => setShowModal(true)}>Créer un nouveau SmarterEvent</Button>
            </div>
            <Modal show={showModal}>
                <Modal.Header>
                    <h1>Nouveau SmarterEvent</h1>
                </Modal.Header>
                <Modal.Body>
                    <SmarterEventForm handleSubmit={handleAddSmarterEvent} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={() => setShowModal(false)}>
                        Annuler
                </Button>
                    <Button type="submit" form="newSmarterEventForm">Créer le SmarterEvent</Button>
                </Modal.Footer>
            </Modal>
        </main>
    </Fragment>
}

export default Console