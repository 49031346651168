import classnames from 'classnames';
import firebase from "firebase/app";
import "firebase/auth";
import React, { useContext, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Burger from '../../components/Burger';
import CartButton from '../../components/CartButton';
import Dropdown from '../../components/Dropdown';
import { RaceContext, SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../constants/context';
import * as ROUTES from '../../constants/routes';
import { hasAdminAccess } from '../../services/authorization';
import TopBarRaceUserMenu from './TopBarRaceUserMenu';

type Props = {
    isAdmin?: boolean;
}

const TopBarMenu: React.FunctionComponent<Props> = (props: Props) => {

    const userProfile = useContext(UserProfileContext);
    const smarterEvent = useContext(SmarterEventContext);
    const smarterEventEdition = useContext(SmarterEventEditionContext);
    const race = useContext(RaceContext);
    const history = useHistory();

    const [menuOpen, setMenuOpen] = useState<boolean>(false)// 


    const signOut = () => {
        firebase.auth().signOut().then(() => {
            document.cookie = `customToken=;expires = Thu, 01 Jan 1970 00:00:00 GMT;domain=localhost.localdomain;SameSite=Strict`

            return <Redirect to={{
                pathname: ROUTES.LANDING,
            }} />
        })
    }

    const checkin = () => {
        history.push("/" + smarterEventEdition!.id + ROUTES.CHECKIN)
    }

    const clmmanagement = () => {
        history.push("/" + smarterEventEdition!.id + ROUTES.EVENTS_RACES + ROUTES.EVENTS_RACE_CLMMANAGEMENT)
    }

    const registrations = () => {
        history.push(ROUTES.MYPARTICIPANTS)
    }


    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    return <header className="userPage">
        <nav id="topbar-nav" className={classnames({ "menu-active": menuOpen })}>
            <div className="logo-menu">
                <Link to="/"><img id="logo" src="/images/logo/logo_grey.svg" alt="Logo" /></Link>
                <div className="cart-and-menu">
                    {(userProfile != null) ?
                        (!props.isAdmin && smarterEventEdition !== null) ? <CartButton userId={userProfile.id!} smarterEventId={smarterEvent?.id!} smarterEventEditionId={smarterEventEdition?.id!} /> : null
                        : null}
                    <Burger menuOpen={menuOpen} toggleMenu={toggleMenu} />
                    <div id="topbar-menu">
                        {(userProfile != null) ?
                            <Dropdown>
                                <Dropdown.Toggle>{userProfile?.firstName} {userProfile?.lastName}</Dropdown.Toggle>
                                <Dropdown.List>
                                    <Dropdown.Item onClick={registrations}>
                                        Mes inscriptions
                                    </Dropdown.Item>
                                    {
                                        smarterEventEdition != null && hasAdminAccess(smarterEvent!, userProfile.id!) ?
                                            <Dropdown.Item onClick={checkin}>
                                                Check in
                                            </Dropdown.Item>
                                            : null
                                    }
                                    {
                                        smarterEventEdition != null && hasAdminAccess(smarterEvent!, userProfile.id!) ?
                                            <Dropdown.Item onClick={clmmanagement}>
                                                Départs CLM
                                            </Dropdown.Item>
                                            : null
                                    }
                                    <Dropdown.Item onClick={signOut}>
                                        Deconnexion
                                    </Dropdown.Item>
                                </Dropdown.List>
                            </Dropdown>
                            // : <div><Link to={ROUTES.SIGNIN} className="nav-item nav-link ml-auto">Se connecter</Link></div>}
                            : <div><a href={ROUTES.SIGNIN} className="nav-item nav-link ml-auto">Se connecter</a></div>}
                    </div>
                </div>
            </div>
            {smarterEventEdition != null ? 
            <div>{smarterEvent?.name} - {smarterEventEdition?.name!}</div>
            : null}
        </nav>
        {race != null ? <TopBarRaceUserMenu /> : null}
    </header>
}

export default TopBarMenu