import React, { Fragment } from "react";
import Card from '../../../components/Card';
import { Race, TeamCategory } from "../../../types/race";
import TeamCategoryCard from './TeamCategoryCard';

type Props = {
    categories: TeamCategory[],
    races: Race[] | undefined,
    newTeamRegistration?: (category: TeamCategory) => void
}

const TeamCategoriesList: React.FunctionComponent<Props> = (props: Props) => {

    var getRaces = () => {
        var data: Record<string, Race> = {}
        if (props.races != null) {
            props.races.forEach((race) => {
                data[race.id] = race
            })
        }
        return data
    }

    return <Fragment>
        <h2>Catégories par équipes</h2>
        <Card.Deck>
            {(props.categories.length === 0) ? <p>L'organisateur n'a pas encore créé de catégorie</p> :
                props.categories.map((category: TeamCategory) => {
                    return <TeamCategoryCard key={category.id} races={getRaces()} newTeamRegistration={props.newTeamRegistration} category={category} />
                })}
        </Card.Deck>
    </Fragment>

}

export default TeamCategoriesList