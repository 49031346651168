import moment from "moment";
import React, { Fragment } from "react";
import { Bookmark, Flag, Geo, Person, PersonBadge, Trophy } from "react-bootstrap-icons";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Timer from "../../../components/Timer";
import { RaceIndividual } from "../../../types/race";
import { getDateFromTimestampOrDate } from "../../../utils";
import RacerStatus from "./RacerStatus";

type Props = {
    participant: RaceIndividual,
    actions?: [{text: string, action: () => any}]
}

const ParticipantCard: React.FunctionComponent<Props> = (props: Props) => {

    const {participant} = props

    return <Fragment>
        <Card>
            <Card.Body>
                {participant.bibNumber != null ?
                    <p><PersonBadge /> {participant.bibNumber} (<RacerStatus participant={participant}/>)</p>
                : null}
                <p><Trophy /> {participant.race.name}</p>
                <p><Person /> {participant.firstName} {participant.lastName} - {participant.gender === "male" ? "Homme" : participant.gender === "female" ? "Femme" : "???"} {participant.birthdayYear}</p>
                <p><Bookmark /> {participant.category.name}</p>
                <p><Geo/> {participant.address.zipcode} {participant.address.city} {participant.address.country}</p>
                { (participant.results != null) ? 
                <p><Flag/> {
                    (participant.results?.finishedAt == null) ? 
                    <Fragment>En course: <Timer date={getDateFromTimestampOrDate(participant.results?.startedAt!)!}/></Fragment>
                    : <Fragment>Arrivé: {moment.duration(moment(getDateFromTimestampOrDate(participant.results?.finishedAt)).diff(moment(getDateFromTimestampOrDate(participant.results?.startedAt!)))).format("hh:mm'ss\"")}</Fragment>
                }</p> : null}
            </Card.Body>
            {(props.actions) ? 
            <Card.Footer>
                {props.actions.map((action) => {
                    return <Button key={action.text} onClick={action.action}>{action.text}</Button>
                })}
            </Card.Footer>
            : null}
        </Card>
    </Fragment>
}

export default ParticipantCard