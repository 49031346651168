import { SmarterEvent } from "../types/smarterEvent"


const isSmarterEventOwner = (smarterEvent: SmarterEvent, userId: string) => {
    return smarterEvent.authz?.owner === userId
}

const isSmarterEventAdmin = (smarterEvent: SmarterEvent, userId: string) => {
    return smarterEvent.authz?.admins?.includes(userId)
}

export const hasAdminAccess = (smarterEvent: SmarterEvent, userId: string) => {
    return isSmarterEventOwner(smarterEvent, userId) || isSmarterEventAdmin(smarterEvent, userId)
}