import { Fragment } from 'react';

export type BillingStatusProps = {
    value: string,
}

const BillingStatus: React.FunctionComponent<BillingStatusProps> = (props: BillingStatusProps) => {

    if (props.value === "BILLED") return <Fragment>Facturé</Fragment>
    if (props.value === "PAID") return <Fragment>Payé</Fragment>
    return <Fragment>???</Fragment>
}

export default BillingStatus