import { yupResolver } from '@hookform/resolvers/yup';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import * as Yup from 'yup';
import Form from '../../../components/Form';
import { Category, ParticipantCartItem, Race, SoloCategory } from '../../../types/race';
import { Address, Option, SmarterEventEdition, UserProfile } from '../../../types/smarterEvent';
import { getDateFromTimestampOrDate, upperCaseFirstLetter } from '../../../utils';

export type RegistrationFormData = {
    // billingStatus?: PARTICIPANT_BILLING_STATUS,
    firstName: string,
    lastName: string,
    email: string,
    gender?: "male" | "female",
    dateOfBirth?: Date,
    phoneNumber?: string,
    password?: string,
    confirmPassword?: string,
    options?: Record<string, boolean>,
    address?: Address,
    teamMates?: any,
    raceId?: string,
    raceName?: string
}

type Props = {
    id: string;
    smarterEventEdition: SmarterEventEdition,
    category: Category,
    defaultValues?: RegistrationFormData,
    restrictEditingUserInfo?: boolean,
    newUserAccount?: boolean
    handleSubmit: (registrationFormData: RegistrationFormData) => void,
    races?: { id: string, name: string }[]
    options?: Option[]
}

export const getParticipantCartItem = (race: Race, category: Category, registrationFormData: RegistrationFormData, userId?: string) => {

    var soloCategory = category as SoloCategory

    let participantCartItem: ParticipantCartItem = {
        race: {
            id: race.id,
            name: race.name,
            type: soloCategory.type
        },
        dateOfBirth: registrationFormData.dateOfBirth!,
        address: {
            zipcode: registrationFormData.address!.zipcode!,
            city: registrationFormData.address!.city!,
            country: registrationFormData.address!.country!
        },
        category: {
            id: category.id!,
            name: category.name,
            price: category.price,
            teamOrSolo: category.teamOrSolo,
        },
        firstName: upperCaseFirstLetter(registrationFormData["firstName"]),
        lastName: upperCaseFirstLetter(registrationFormData["lastName"]),
        gender: registrationFormData["gender"]!,
        phoneNumber: registrationFormData.phoneNumber!,
        email: registrationFormData.email.trim(),
    }

    if (userId != null) participantCartItem.userId = userId

    var options: Option[] = []
    if (registrationFormData.options != null) {
        race.options?.forEach((option) => {
            var fdOption: Record<string, boolean> = registrationFormData.options!
            var optionId: string = option.id
            if (fdOption[optionId]) {
                options.push({
                    quantity: 1,
                    price: option.price,
                    name: option.name,
                    id: option.id
                })
            }
        })
        participantCartItem.options = options
    }
    return participantCartItem
}

export const getRegistrationFormDataFromUserProfile = (userProfile: UserProfile | undefined) => {
    if (userProfile === undefined) return undefined
    var registrationFormData: RegistrationFormData = {
        email: userProfile.email,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        address: userProfile.address,
        gender: userProfile.gender!,
        phoneNumber: userProfile.phoneNumber,
    }
    if (userProfile.dateOfBirth != null) {
        registrationFormData.dateOfBirth = getDateFromTimestampOrDate(userProfile.dateOfBirth)!
    }
    return registrationFormData
}

const RegistrationBaseForm: React.FunctionComponent<Props> = (props: Props) => {

    // const [races, setRaces] = useState<{ id: string, name: string }[]>([]);

    // Yup.addMethod(Yup.string, 'dateFormat', function (formats, parseStrict) {
    //     return this.test((value: string) => {
    //         console.log(value)


    //     });
    // });

    var [isLoading, setLoading] = useState(false)

    function dateInputText(_value: any, originalValue: Date | firebase.firestore.Timestamp) {
        return moment(getDateFromTimestampOrDate(originalValue)).format('YYYY-MM-DD').toString()
    }

    function valid(value: Date | undefined) {
        var test = moment(value, true);
        let year = test.year()
        return test.isValid() && year > 1900 && year < new Date().getUTCFullYear() ? true : false;
    }    

    const validationSchema = useMemo(() => {

        function checkUserAccount(value: string | undefined) {
            return new Promise((resolve, reject) => {
                    if (!props.newUserAccount || (props.id !== "form-0" && props.id !== "participantForm")) {
                        resolve(true)
                    }
                if (value === undefined || (value.indexOf("@") === -1) || (value.indexOf("@") === value.length - 1)) {
                    resolve(false)
                }
                else
                    firebase.auth().fetchSignInMethodsForEmail(value).then((result) => {
                        if (result.length > 0) {
                            resolve(false)
                        } else {
                            resolve(true)
                        }
                    }).catch((error) => {
                        resolve(false)
                    })
            })
        }

        function checkGender(gender: string | undefined) {
            if (props.category.teamOrSolo === "solo") {
                var soloCategory = props.category as SoloCategory
                return soloCategory.gender === "any" || soloCategory.gender === gender
            } else
            return true;
        }


        function checkDateOfBirth(date: Date | undefined) {
            if (props.category.teamOrSolo === "solo") {
                var soloCategory = props.category as SoloCategory
                if ((soloCategory.birthDateFrom != null) && (soloCategory.birthDateTo != null)) {
                    return date!.getTime() >= getDateFromTimestampOrDate(soloCategory.birthDateFrom)!.getTime()
                    && date!.getTime() <= getDateFromTimestampOrDate(soloCategory.birthDateTo)!.getTime()    
                }
                if ((soloCategory.birthDateFrom == null) && (soloCategory.birthDateTo != null)) {
                    return date!.getTime() <= getDateFromTimestampOrDate(soloCategory.birthDateTo)!.getTime()    
                }
                if ((soloCategory.birthDateFrom != null) && (soloCategory.birthDateTo == null)) {
                    return date!.getTime() >= getDateFromTimestampOrDate(soloCategory.birthDateFrom)!.getTime()
                }
                return true                
            } else
            return true;
        }

        return Yup.object({
            raceId: Yup.string(),
            email: Yup.string()
                .trim()
                .required('Adresse email requise')
                .email('Adresse Email invalide')
                .test('emailAvailable', "Compte déjà existant", checkUserAccount),
            firstName: Yup.string()
                .min(2, 'Prénom requis')
                .required('Prénom requis'),
            lastName: Yup.string()
                .min(2, 'Nom requis')
                .required('Nom requis'),
            gender: Yup.string()
                .required('Genre requis')
                .test('genderCompatible', "Impossible pour cette catégorie", checkGender),
            dateOfBirth: Yup.date()
                .required("Date de naissance requise")
                .typeError("Date impossible")
                .test('dateFormat', "Date non valide", valid)
                .test('dateCategoryCompatible', "Impossible pour cette catégorie", checkDateOfBirth),
            phoneNumber: props.smarterEventEdition.raceModule?.requirePhoneNumber ? Yup.string().required('Numéro de téléphone requis') : Yup.string(),
            address: Yup.object().shape({
                addressLine1: props.smarterEventEdition.raceModule?.requireAddress ? Yup.string().required('Addresse requise') : Yup.string(),
                addressLine2: Yup.string(),
                zipcode: Yup.string()
                    .required('Code postal requis'),
                city: Yup.string()
                    .required('Ville requise'),
                country: Yup.string()
                    .required('Pays requis'),
            }),
            password: props.newUserAccount ?
                Yup.string()
                    .min(8, 'Le mot de passe doit faire au moins 8 caractères')
                    .required('Mot de passe requis')
                : Yup.string(),
            confirmPassword: props.newUserAccount ?
                Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Les mots de passe sont différents')
                    .required('Mot de passe requis')
                : Yup.string()
        })
    }, [props.smarterEventEdition, props.newUserAccount, props.id, props.category])

    const { register, handleSubmit, errors, reset, clearErrors } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        const serializeSchema = validationSchema.shape({
            dateOfBirth: Yup.string().transform(dateInputText),
        })

        reset(serializeSchema.cast(props.defaultValues))
    }, [props.defaultValues, reset, validationSchema])

    const onSubmit = (registrationFormData: RegistrationFormData) => {
        setLoading(true)
        // const serializeSchema = validationSchema.shape({
        //     dateOfBirth: Yup.string().transform(dateInputText)
        // })
        // reset(serializeSchema.cast(props.defaultValues))
        if (props.races) {
            props.races.forEach((race: { id: string, name: string }) => {
                if (race.id === registrationFormData.raceId) registrationFormData.raceName = race.name
            })
        }
        props.handleSubmit(registrationFormData);
        if (!errors) {
            reset()
            clearErrors()
        }
        // setLoading(false)
    }

    return (
        <LoadingOverlay
            active={isLoading}
            spinner
        >
        <Form onSubmit={handleSubmit(onSubmit)} id={props.id} errors={errors} register={register} yupSchema={validationSchema}>
            <Form.InputText id="email" label="Email" readOnly={props.restrictEditingUserInfo && props.defaultValues?.email != null}
                placeholder="Adresse email du participant" />
            <Form.InputText id="firstName" label="Prénom" readOnly={props.restrictEditingUserInfo && props.defaultValues?.firstName != null}
                placeholder="Prénom du participant" />
            <Form.InputText id="lastName" label="Nom" readOnly={props.restrictEditingUserInfo && props.defaultValues?.lastName != null}
                placeholder="Nom du participant" />
            <Form.InputSelect id="gender" label="Genre" values={[{ id: "male", name: "Homme" }, { id: "female", name: "Femme" }]}
                readOnly={props.restrictEditingUserInfo && props.defaultValues?.lastName != null}
            // disabled={props.restrictEditingUserInfo && props.defaultValues?.gender != null} 
            />
            <Form.InputDate id="dateOfBirth" label="Date de naissance"
                readOnly={props.restrictEditingUserInfo && props.defaultValues?.dateOfBirth != null} />
            <Form.InputText id="phoneNumber" label="Numéro de téléphone portable" placeholder="Numéro de portable valable" />
            {props.options != null ?
                <div className="input-group">
                    <label>Option{props.options.length > 1 ? "s" : null}</label>
                    {props.options?.map((option) => {
                        return <Form.InputCheckbox id={`options[${option.id}]`}
                            label={`${option.name}: +${option.price}CHF`}
                        />
                    })}
                </div>
                : null}
            <Form.InputText id="address.addressLine1" label="Adresse" placeholder="Rue et numéro" />
            <Form.InputText id="address.addressLine2" label="Adresse (suite)" placeholder="Complément d'adresse si besoin" />
            <Form.InputText id="address.zipcode" label="Code Postal" />
            <Form.InputText id="address.city" label="Ville" />
            <Form.InputText id="address.country" label="Pays" defaultValue={props.defaultValues?.address?.country || "Suisse"} />
            {props.newUserAccount ? <Fragment>
                <Form.InputPassword id="password" label="Définir un mot de passe" placeholder="Mot de passe" />
                <Form.InputPassword id="confirmPassword" label="Mot de passe (répétition)" placeholder="Répétition du mot de passe" />
            </Fragment>
                : null}
        </Form>
        </LoadingOverlay>

    );
}

export default RegistrationBaseForm