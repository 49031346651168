import { yupResolver } from '@hookform/resolvers/yup';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { FunctionComponent, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingOverlay from 'react-loading-overlay';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/images/logo/logo.svg';
import Button from '../../components/Button';
import Form from '../../components/Form';
import * as FIRESTORE from "../../constants/firestore";
import * as ROUTES from '../../constants/routes';
import { Address } from "../../types/smarterEvent";

interface LocationState {
    redirect: string
}

export const newUser = (email: string, password: string, firstName: string, lastName: string, gender?: string, dateOfBirth?: Date, address?: Address, phoneNumber?: string, createdBy?: string) => {

    return firebase.auth().createUserWithEmailAndPassword(email, password).then((userCredentials) => {
        var userProfile: any = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
        }
        if (gender) { userProfile.gender = gender }
        if (dateOfBirth) { userProfile.dateOfBirth = dateOfBirth }
        if (address) { userProfile.address = address }
        if (phoneNumber) { userProfile.phoneNumber = phoneNumber }
        if (createdBy) { userProfile.createdBy = createdBy } else { userProfile.createdBy = userCredentials?.user?.uid }
        return firebase.firestore().collection(FIRESTORE.USERS_COL).doc(userCredentials?.user?.uid).set(userProfile).then(() => {
            return userCredentials.user!.uid!
        })
    })

    // var userId: string = await firebase.auth().createUserWithEmailAndPassword(email, password).then(async (userCredentials) =>  {
    //     var userProfile: any = {
    //         email: email,
    //         firstName: firstName,
    //         lastName: lastName,
    //         createdAt: firebase.firestore.FieldValue.serverTimestamp()
    //     }
    //     if (gender) { userProfile.gender = gender }
    //     if (dateOfBirth) { userProfile.dateOfBirth = dateOfBirth }
    //     if (address) { userProfile.address = address }
    //     if (phoneNumber) { userProfile.phoneNumber = phoneNumber }
    //     if (createdBy) { userProfile.createdBy = createdBy } else { userProfile.createdBy = userCredentials?.user?.uid }

    //     await firebase.firestore().collection(FIRESTORE.USERS_COL).doc(userCredentials?.user?.uid).set(userProfile)
    //     return userCredentials.user!.uid
    // })
    // return userId
}

const SignUpPage: FunctionComponent = () => {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('Adresse email requise')
            .email('Adresse Email invalide'),
        firstName: Yup.string()
            // .min(2, 'Prénom requis')
            .required('Prénom requis'),
        lastName: Yup.string()
            // .min(2, 'Nom requis')
            .required('Nom requis'),
        password: Yup.string()
            .min(8, 'Le mot de passe doit faire au moins 8 caractères')
            .required('Mot de passe requis'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Les mots de passe sont différents')
            .required('Mot de passe requis'),
    });

    const { register, handleSubmit, errors, watch } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const [state, setState] = useState<{ error: any, loading: boolean }>({ error: null, loading: false })

    const password = useRef({})
    password.current = watch("password", "")

    const location = useLocation<LocationState>()
    const history = useHistory()

    const onSubmit = (values: any) => {
        setState({ error: null, loading: true })
        newUser(values.email.trim(), values.password, values.firstName, values.lastName)
            .then(() => {
                var redirectURL: string | null = location.state?.redirect
                if (redirectURL != null) {
                    history.push(redirectURL)
                } else {
                    history.push(ROUTES.LANDING)
                }
                // setState({ error: null, redirect: true, loading: false })
            })
            .catch((error) => {
                console.log("ERROR")
                console.log(error)
                setState({ error: error, loading: false })
            })
    }

    return <LoadingOverlay
        style={{ height: "100%" }}
        active={state.loading}
        spinner>
        <main>
            <section className="splash-screen">
                <img className="logo" src={logo} alt="SmarterEvents logo" />
                <div>
                    <h2>Bienvenue,</h2>
                    <h3>Il suffit de <span className="text-success">quelques secondes</span> pour créer un compte</h3>
                </div>
                <Form id="registerForm" errors={errors} onSubmit={handleSubmit(onSubmit)} register={register} yupSchema={validationSchema}>
                    {state.error && state.error.code === "auth/email-already-in-use" && <div style={{ color: "#f00" }}><p>Ce compte existe déjà</p></div>}
                    {state.error && state.error.code !== "auth/email-already-in-use" && <div style={{ color: "#f00" }}><p>{state.error.code}</p></div>}
                    <Form.InputText id="email" label="Email" />
                    <Form.InputText id="firstName" label="Prénom" />
                    <Form.InputText id="lastName" label="Nom" />
                    <Form.InputPassword id="password" label="Mot de passe" autoComplete="new-password" />
                    <Form.InputPassword id="confirmPassword" label="Mot de passe (répéter)" autoComplete="new-password" />
                    <Button type="submit">Créer compte</Button>
                    <div>Déjà un compte ? <Link to={ROUTES.SIGNIN}>Se connecter</Link></div>
                </Form>
            </section>
        </main >
    </LoadingOverlay>
}

export default SignUpPage