import { Children } from 'react';
import '../assets/stylesheets/components/button.scss';

export type WizardProps = {
    children: any[],
    step: number;
    // onSubmit: ((event: React.FormEvent<HTMLFormElement>) => void);
    // errors: any;
    // register: any;
}

export type WizardStepProps = {
    children: any
}

interface WizardSubComponents {
    Step: React.FunctionComponent<WizardStepProps>
}

const Wizard: React.FunctionComponent<WizardProps> & WizardSubComponents = (props: WizardProps) => {

    return <div>
        {Children.toArray(props.children)[props.step]}
    </div>
}

const Step = (props: WizardStepProps) => {
    return <div className="wizard__step">
        {props.children}
    </div>
}

Wizard.Step = Step;

export default Wizard;
