import { yupResolver } from '@hookform/resolvers/yup';
import firebase from "firebase/app";
import "firebase/auth";
import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingOverlay from 'react-loading-overlay';
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Yup from 'yup';
import logo from '../../assets/images/logo/logo.svg';
import Button from '../../components/Button';
import Form from '../../components/Form';
import * as ROUTES from '../../constants/routes';
import { getDomain } from "../../utils";

interface LocationState {
    redirect: string
}

const SignInPage: FunctionComponent = () => {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('Adresse email requise')
            .email('Adresse Email invalide'),
        password: Yup.string()
            .required('Mot de passe requis'),
    });

    const [error, setError] = useState<any>()
    const [totoLoading, setLoading] = useState<boolean>(false)

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const history = useHistory()
    const location = useLocation<LocationState>()
    
    var redirect: string | null = location.state?.redirect

    const onSubmit = (values: any) => {
        console.log("onSubmit in")
        setLoading(true);
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(async () => {
            firebase.auth().signInWithEmailAndPassword(values.email.trim(), values.password)
                .then((userCredentials) => {
                    fetch("https://us-central1-smarter-events.cloudfunctions.net/session/sessionLogin", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ userId: userCredentials.user?.uid })
                    }).then((response) => {
                        response.json().then((data) => {
                            document.cookie = `customToken=${data.customToken};max-age=600;domain=${getDomain(true)};SameSite=Strict`
                            console.log("onSubmit out")
                            if (redirect != null)
                                history.push(redirect)
                            else
                                history.push(ROUTES.LANDING)
                        })
                    })
                })
                .catch((error) => {
                    setError(error)
                    setLoading(false)
                });
        })
    }

    console.log(totoLoading)
    if (totoLoading) return null


    return <LoadingOverlay
        style={{ height: "100%" }}
        active={totoLoading}
        spinner>
        <main>
            <section className="splash-screen">
                <img className="logo" src={logo} alt="SmarterEvents logo" />
                <div>
                    <h2>Bienvenue,</h2>
                    <h3>Veuillez vous authentifier.</h3>
                </div>
                <p>Pas encore de compte ?{' '}
                    <Link to={{
                        pathname: ROUTES.SIGNUP,
                        state: { redirect: redirect }
                    }}>Créer un compte</Link>
                </p>
                <Form onSubmit={handleSubmit(onSubmit)} errors={errors} id="signInForm" register={register} yupSchema={validationSchema}>
                    <Form.InputText id="email" label="Email" autoComplete="username" placeholder="Email ici..." />
                    <Form.InputPassword id="password" label="Mot de passe" autoComplete="current-password" placeholder="Mot de passe ici..." />
                    {error && (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") && <div className="error"><p>Utilisateur inconnu ou mot de passe incorrect</p></div>}
                    {error && (error.code === "auth/too-many-requests") && <div className="error"><p>Vous avez fait trop de tentatives, merci de patienter quelques secondes</p></div>}
                    {error && (error.code !== "auth/user-not-found" && error.code !== "auth/wrong-password" && error.code !== "auth/too-many-requests") && <div className="error"><p>{error.code}</p></div>}

                    <Button type="submit">Accéder à SmarterEvents</Button>
                    <Link to={ROUTES.FORGOT_PASSWORD} className="forgot-password">J'ai oublié mon mot de passe</Link>{' '}{' '}
                </Form>
            </section>
        </main>
    </LoadingOverlay>
}

export default SignInPage;