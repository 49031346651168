import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { RaceContext, SmarterEventContext, SmarterEventEditionContext } from '../constants/context';
import * as FIRESTORE from '../constants/firestore';
import * as ROUTES from '../constants/routes';
import TopBarMenu from "../layouts/user/TopBarMenu";
import LiveResultsPage from "../pages/race/LiveResultsPage";
import ParticipantsPage from '../pages/race/ParticipantsPage';
import RacePage from '../pages/race/RacePage';
import RaceSignUpPage from "../pages/race/RaceSignUpPage";
import { Race } from '../types/race';

const RaceRouter: React.FunctionComponent = () => {

    const smarterEvent = useContext(SmarterEventContext);
    const smarterEventEdition = useContext(SmarterEventEditionContext);
    const { raceId } = useParams<{ editionId: string, raceId: string }>();

    const [race] = useDocumentDataOnce<Race>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition!.references.eventId)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
        .collection(FIRESTORE.RACES_COL).doc(raceId), { idField: "id" });

    if (!race) return null
    else if (race === undefined)
        return <Redirect to={ROUTES.ERROR_404} />
    else {
        window.document.title = smarterEvent?.name + " " + smarterEventEdition?.name + " " + race?.name + " - SmarterEvents.ch"
        return <Fragment>
            <RaceContext.Provider value={race}>
                {/* <RaceBreadcrumb /> */}
                <TopBarMenu />
                <main className="main">
                    <h1>{race.name}</h1>
                    <Switch>
                        <Route path={`/:editionId${ROUTES.EVENTS_RACE}/:raceId${ROUTES.EVENTS_RACE_PARTICIPANTS}`} component={ParticipantsPage} />
                        <Route path={`/:editionId${ROUTES.EVENTS_RACE}/:raceId${ROUTES.EVENTS_RACE_SIGNUP}`} component={RaceSignUpPage} />
                        <Route path={`/:editionId${ROUTES.EVENTS_RACE}/:raceId${ROUTES.EVENTS_RACE_LIVERESULTS}`} component={LiveResultsPage} />
                        <Route path={`/:editionId${ROUTES.EVENTS_RACE}/:raceId`} component={RacePage} />
                    </Switch>
                </main>
            </RaceContext.Provider>
        </Fragment >
    }
}
export default RaceRouter