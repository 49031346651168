import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { SmarterEventContext, SmarterEventEditionContext } from '../constants/context';
import * as FIRESTORE from '../constants/firestore';
import * as ROUTES from '../constants/routes';
import Footer from '../layouts/Footer';
import CLMManagementPage from '../pages/race/CLMManagementPage';
import RacesPage from '../pages/race/RacesPage';
import CartPage from '../pages/user/CartPage';
import { SmarterEventEdition } from '../types/smarterEvent';
import CheckInRouter from "./CheckInRouter";
import RaceRouter from './RaceRouter';

const SmarterEventEditionRouter: React.FunctionComponent = () => {

    const smarterEvent = useContext(SmarterEventContext);

    const { editionId } = useParams<{ editionId: string, raceId: string }>();

    const [smarterEventEdition, loadingSmarterEventEdition] = useDocumentDataOnce<SmarterEventEdition>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(editionId), { idField: "id" })


    if (loadingSmarterEventEdition) return null
    else if (smarterEventEdition === undefined)
        return <Redirect to={ROUTES.ERROR_404} />
    else {
        window.document.title = smarterEvent?.name + " " + smarterEventEdition?.name + " - SmarterEvents.ch"
        return <Fragment>
            <SmarterEventEditionContext.Provider value={smarterEventEdition}>
                <Switch>
                    <Route path={`/:editionId${ROUTES.USER_CART}`} component={CartPage} />
                    <Route path={`/:editionId${ROUTES.EVENTS_RACE}/:raceId`} component={RaceRouter} />
                    <Route path={`/:editionId${ROUTES.EVENTS_RACES}${ROUTES.EVENTS_RACE_CLMMANAGEMENT}`} component={CLMManagementPage} />
                    <Route path={`/:editionId${ROUTES.EVENTS_RACES}`} component={RacesPage} />
                    <Route path={`/:editionId${ROUTES.CHECKIN}`} component={CheckInRouter} />
                    <Route exact path={`/:editionId`} render={(props) => (
                        <Redirect to={`/${props.match.params.editionId}${ROUTES.EVENTS_RACES}`} />
                    )} />
                    {/* <Route path={`/:editionId${ROUTES.CHECKIN}`} component={WithoutSidebarPage} />
                        <Route path={`/:editionId${ROUTES.EVENTS_RACE}/:raceId`} component={WithSidebarPage} />
                        <Route path={`/:editionId${ROUTES.EVENTS_POS}`} component={WithSidebarPage} />
                        <Route path="*" component={RedirectToLatestEdition} /> */}
                </Switch>
                <Footer />
            </SmarterEventEditionContext.Provider>
        </Fragment >
    }
}

export default SmarterEventEditionRouter