import { Fragment } from 'react';

export type GenderProps = {
    value: string,
}

const Gender: React.FunctionComponent<GenderProps> = (props: GenderProps) => {

    if (props.value === "male") return <Fragment>Homme</Fragment>
    if (props.value === "female") return <Fragment>Femme</Fragment>
    if (props.value === "any") return <Fragment>Mixte</Fragment>
    return <Fragment>???</Fragment>
}

export default Gender