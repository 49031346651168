import React, { Fragment } from "react";
import { ArrowUp, FileEarmarkText, InfoCircle, Map, PersonPlus, Trophy } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import * as ROUTES from '../../../constants/routes';
import { SPORTS } from "../../../constants/sports";
import { Race } from "../../../types/race";
import { getDateFromTimestampOrDate } from "../../../utils";

type Props = {
    race: Race,
    isAdmin?: boolean
}

const RaceCard: React.FunctionComponent<Props> = (props: Props) => {

    var now: number = new Date().getTime()
    var registrations = false
    var registrationsOpened = false
    if (props.race.registrations) {
        registrations = true;
        var startRegistrationTime = getDateFromTimestampOrDate(props.race.registrations.startTime)!.getTime()
        var endRegistrationTime = getDateFromTimestampOrDate(props.race.registrations.endTime)!.getTime()
        if (now < endRegistrationTime && now > startRegistrationTime) {
            registrationsOpened = true
        }
    }

    // const getLink = () => {
    //     if (props.isAdmin) {
    //         return window.location.protocol + "//" + props.race.smarterEvent.eventId + "." + getDomain()
    //     } else {
    //         return ""
    //     }
    // }

    // const resolveSportImageURL = (sportName: string) => {
    //     if (SPORTS[sportName] !== undefined)
    //         return "/images/sports/" + sportName + ".jpg"
    //     else
    //         return "/images/sports/default.jpg"
    // }

    return <Card>
        <Card.Header>
            <h1><Link to={`/${props.race.references.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>{props.race.name}</Link></h1>
        </Card.Header>
        <Card.Data>
            <p><Trophy />{SPORTS[props.race?.sport] ? SPORTS[props.race?.sport].name : null}</p>
            {props.race?.distance ? 
                <p><Map />{props.race?.distance / 1000}km  </p>
                : null
            }
            {props.race?.elevationChange ? 
                <p><ArrowUp />{props.race?.elevationChange}m  </p>
                : null
            }

        </Card.Data>
        {/* <div style={{ position: "relative" }}>
            <Card.Img src={resolveSportImageURL(props.race.sport)} alt="Card image" style={{ objectFit: "cover", height: "110px", filter: "brightness(50%)" }} />
            <Card.ImgOverlay className="text-light" >
                <Card.Title>{props.race.name}</Card.Title>
                <Card.Subtitle>{SPORTS[props.race?.sport] ? SPORTS[props.race?.sport].name : null}</Card.Subtitle>
            </Card.ImgOverlay>
        </div> */}

        <Card.Body>
            {registrations && !registrationsOpened ? <p>Les inscriptions sont actuellement fermées</p> : null}
            {registrations && registrationsOpened ? <Fragment>
                <InfoCircle /><Link to={`/${props.race.references.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}`} className="clearBg">Informations</Link><br />
                <PersonPlus /><Link to={`/${props.race.references.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}${ROUTES.EVENTS_RACE_SIGNUP}`} className="clearBg">Inscription</Link><br />
                <FileEarmarkText /><Link to={`/${props.race.references.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}${ROUTES.EVENTS_RACE_PARTICIPANTS}`} className="clearBg">Liste de départ</Link>
                {props.race.chrono?.enabled ?
                    <Fragment>
                        <br />
                        <Trophy /><Link to={`/${props.race.references.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}${ROUTES.EVENTS_RACE_LIVERESULTS}`} className="clearBg">Résultats live</Link>
                    </Fragment>
                    : null}
            </Fragment>
                : null}
        </Card.Body>
        {/* {props.isAdmin ?
            </Card.Body>
            :
            <Card.Body>
                {registrations && !registrationsOpened ? <Card.Text className="text-muted small">Les inscriptions sont actuellement fermées</Card.Text> : null}
                <ListGroup>
                    {registrations && registrationsOpened ?
                        <ListGroupItem className="border-0 px-0 py-0">
                            <Link to={`/${props.race.smarterEvent.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}><PersonPlus /> Inscription</Link><br />
                        </ListGroupItem>
                        : null}
                    <ListGroupItem className="border-0 px-0 py-0">
                        <Link to={`/${props.race.smarterEvent.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}${ROUTES.EVENTS_RACE_PARTICIPANTS}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                            <FileEarmarkText /> Liste de départ
                        </Link>
                    </ListGroupItem>
                    {props.race.chrono?.enabled ?
                        <ListGroupItem className="border-0 px-0 py-0">
                            <Link to={`/${props.race.smarterEvent.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}${ROUTES.EVENTS_RACE_LIVERESULTS}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                                <Trophy /> Résultats live
                            </Link>
                        </ListGroupItem>
                        : null}
                </ListGroup>
            </Card.Body>
        } */}
    </Card>

}

export default RaceCard