import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useHistory } from 'react-router-dom';
import Button from "../../components/Button";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import { SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import TopBarMenu from "../../layouts/user/TopBarMenu";
import { ParticipantCartItem, TeamCartItem } from "../../types/race";
import { Bill, CartItem } from "../../types/smarterEvent";


type Props = {
}

const CartPage: React.FunctionComponent<Props> = () => {
    const history = useHistory()

    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const userProfile = useContext(UserProfileContext)!;

    const [cartItems, loading] = useCollectionData<CartItem>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
        .collection(FIRESTORE.CARTS_COL).doc(userProfile?.id)
        .collection(FIRESTORE.CARTS_ITEMS_COL), { idField: "id" })


    const [total, setTotal] = useState<number>(0)
    const [confirmedModal, setConfirmedModal] = useState<{open: boolean, paymentMode:string|null}>({open:false, paymentMode: null})
        

    const handleDelete = (cartItem: CartItem) => {
        let isLast = cartItems?.length! === 1
        firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.CARTS_COL).doc(userProfile.id)
            .collection(FIRESTORE.CARTS_ITEMS_COL).doc(cartItem.id).delete().then(() => {
                if (isLast) {
                    history.goBack()
                }
            })
    }

    // Used if something is deleted
    useEffect(() => {
        var total = 0
        cartItems?.forEach((cartItem) => {
            total += cartItem.price;
            cartItem.options?.forEach((option) => {
                total += option.price * option.quantity!
            })
        })
        setTotal(total)
    }, [cartItems])

    const getBill = (paymentMode: string) => {
        var bill: Bill = {
            smarterEventName: smarterEvent.name,
            smarterEventEditionName: smarterEventEdition.name,
            createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            createdBy: {
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                address: userProfile.address!,
                email: userProfile.email,
                userId: userProfile.id!
            },
            items: cartItems!,
            paymentMode: paymentMode,
            references: {
                eventId: smarterEvent.id,
                editionId: smarterEventEdition.id,
            },
            // status: SMARTEREVENT.BILL_STATUS.OPEN
        }
        return bill
    }

    // const saveTeam = async (formValues: any) => {

    //     var userId = userProfile?.id

    //     var teamMatesFormValues: any[] = []

    //     Object.entries(formValues).forEach((formValue: any, idx) => {
    //         teamMatesFormValues.push(formValue[1])
    //     })

    //     var batch = firebase.firestore().batch();
    //     var teamMates: { firstName: string, lastName: string, gender: "male" | "female", userId: string }[] = []
    //     var teamMatesCartItems: ParticipantCartItem[] = []


    //     teamMatesFormValues.forEach((teamMate) => {
    //         // Save UserProfiles
    //         console.log(userId)
    //         var contact = getUserProfile(teamMate, userId!)
    //         var teamMateUserRef = firebase.firestore().collection(FIRESTORE.USERS_COL).doc();

    //         batch.set(teamMateUserRef, contact)

    //         // To add in team definition
    //         teamMates.push({
    //             userId: teamMateUserRef.id,
    //             firstName: contact.firstName!,
    //             lastName: contact.lastName!,
    //             gender: contact.gender!,
    //         })

    //         // To add in cart
    //         teamMatesCartItems.push({
    //             raceId: teamMate.raceId,
    //             userId: teamMateUserRef.id,
    //             address: teamMate.address,
    //             category: {
    //                 id: category.id,
    //                 name: category.name,
    //                 price: category.price,
    //                 type: category.type
    //             },
    //             dateOfBirth: teamMate.dateOfBirth,
    //             email: teamMate.email,
    //             phoneNumber: teamMate.phoneNumber,
    //             raceName: teamMate.raceName,
    //             firstName: teamMate.firstName,
    //             lastName: teamMate.lastName,
    //             gender: teamMate.gender
    //         })
    //     })


    //         batch.commit().then()
    // }



    const createMissingUserProfiles = (batch: firebase.firestore.WriteBatch, cartItems: CartItem[]) => {
        cartItems.forEach((cartItem) => {
            if (cartItem.type === "raceIndividual") {
                var participantCartItem = cartItem.data as ParticipantCartItem
                if (participantCartItem.userId == null) {
                    var contactProfile: any = {
                        email: participantCartItem.email,
                        firstName: participantCartItem.firstName,
                        lastName: participantCartItem.lastName,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        gender: participantCartItem.gender,
                        dateOfBirth: participantCartItem.dateOfBirth,
                        address: participantCartItem.address,
                        phoneNumber: participantCartItem.phoneNumber,
                        createdBy: userProfile.id
                    }

                    var userRef = firebase.firestore().collection(FIRESTORE.USERS_COL).doc();
                    batch.set(userRef, contactProfile)
                    var cartItemRef = firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
                    .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
                    .collection(FIRESTORE.CARTS_COL).doc(userProfile.id)
                    .collection(FIRESTORE.CARTS_ITEMS_COL).doc(cartItem.id)
                    batch.update(cartItemRef, { "data.userId": userRef.id })
                }

            } else if (cartItem.type === "raceTeam") {
                var teamCartItem = cartItem.data as TeamCartItem
                var teamMates: any[] = []
                console.log(teamCartItem)

                teamCartItem.teamMates.forEach((teamMateCartItem) => { 
                    if (teamMateCartItem.userId == null) {
                        var contactProfile: any = {
                            email: teamMateCartItem.email,
                            firstName: teamMateCartItem.firstName,
                            lastName: teamMateCartItem.lastName,
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                            gender: teamMateCartItem.gender,
                            dateOfBirth: teamMateCartItem.dateOfBirth,
                            address: teamMateCartItem.address,
                            phoneNumber: teamMateCartItem.phoneNumber,
                            createdBy: userProfile.id
                        }
    
                        var userRef = firebase.firestore().collection(FIRESTORE.USERS_COL).doc();
                        teamMateCartItem.userId = userRef.id
                        batch.set(userRef, contactProfile)
                    }
                    teamMates.push(teamMateCartItem)
                })

                cartItemRef = firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
                .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
                .collection(FIRESTORE.CARTS_COL).doc(userProfile.id)
                .collection(FIRESTORE.CARTS_ITEMS_COL).doc(cartItem.id)
                batch.update(cartItemRef, { "data.teamMates": teamMates })
            }
        })
    }

    const confirm = (paymentMode: string) => {


        var batch = firebase.firestore().batch();

        createMissingUserProfiles(batch, cartItems!)

        var bill = getBill(paymentMode)

        var billRef = firebase.firestore()
            .collection("smarterEvents").doc("data")
            .collection(FIRESTORE.BILLS_COL).doc()

        batch.set(billRef, bill)

        cartItems?.forEach((cartItem: CartItem) => {
            batch.delete(firebase.firestore()
            .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.CARTS_COL).doc(userProfile.id)
            .collection(FIRESTORE.CARTS_ITEMS_COL).doc(cartItem.id))
        })

        batch.commit().then(() => {
            setConfirmedModal({open:true, paymentMode:paymentMode})        
        })
    }

    return <Fragment>
        <TopBarMenu />
        <main className="main">
            <h1>Panier</h1>
            <Card>
                <Card.Body>
                    <h2>Rappel de la commande</h2>
                    <div className="order-list">
                        <table>
                            <thead>
                                <tr>
                                    <td>Description</td>
                                    <td>Prix</td>
                                    <td></td>
                                </tr>
                            </thead>
                            {loading ? <div>Loading...</div> :
                            cartItems?.map((cartItem, idx) => {
                                return <Fragment key={idx}>
                                    <tbody className="cart">
                                        <tr>
                                            <td>
                                                {cartItem.description}
                                            </td>
                                            <td>{cartItem.price} CHF</td>
                                            <td className="buttons" rowSpan={(cartItem.options) ? cartItem.options.length + 1 : 1}>
                                                <Button onClick={() => { handleDelete(cartItem) }}>
                                                    {/* <Trash size="0.75rem" />  */}
                                                Effacer
                                            </Button>{' '}
                                            </td>
                                        </tr>
                                        {
                                            cartItem.options?.map((option) => {
                                                return <Fragment key={option.name}><tr className="option"><td>{option.quantity} * {option.name}</td><td>{option.price}CHF</td></tr></Fragment>
                                            })
                                        }
                                    </tbody>
                                </Fragment>
                            })
                            }
                            <tfoot>
                                <tr>
                                    <td><b>Total</b></td>
                                    <td><b>{total} CHF</b></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <h2>Adresse de facturation</h2>
                    <div className="address">
                        <div>
                            <div>{userProfile?.firstName} {userProfile?.lastName}</div>
                            <div>{userProfile?.address?.addressLine1}</div>
                            <div>{userProfile?.address?.addressLine2}</div>
                            <div>{userProfile?.address?.zipcode} {userProfile?.address?.city}</div>
                            <div>{userProfile?.address?.country}</div>
                        </div>
                        <div className="text-right">
                            {/* <Link to={{ pathname: ROUTES.USER + ROUTES.USER_EDIT_PROFILE, state: { redirectURL: location.pathname } }}><button>Modifier</button></Link> */}
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    {smarterEvent.paymentModes?.includes("onsite") ?
                        <Button onClick={() => confirm("onsite")}>{total === 0 ? "Confirmer les inscriptions" : "Confirmer (Paiement sur site)"}</Button> : null }
                    {smarterEvent.paymentModes?.includes("bill") ?
                        <Button onClick={() => confirm("bill")}>{total === 0 ? "Confirmer les inscriptions" : "Confirmer (Paiement sur facture)"}</Button> : null }

                </Card.Footer>
            </Card>
            <Modal show={confirmedModal.open}>
                <Modal.Header>Merci</Modal.Header>
                <Modal.Body>
                    {confirmedModal.paymentMode === "onsite" ?
                        <div>Merci, le paiement se fera sur place</div>: null}
                    {confirmedModal.paymentMode === "bill" ?
                        <div>Merci, une facture vous a été envoyé par email, si votre commande a été faite à moins d'une semaine avant l'événement merci de prévoir une preuve de paiement.</div>: null}
                </Modal.Body>
                <Modal.Footer><Button onClick={() => {setConfirmedModal({open:false, paymentMode:null}); history.goBack()}}>Ok</Button></Modal.Footer>
            </Modal>
        </main>
    </Fragment >
}

export default CartPage;