import * as FileSaver from 'file-saver';
import 'firebase/firestore';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FileEarmarkExcel } from 'react-bootstrap-icons';
import Button from '../../../components/Button';
import { Race, RaceIndividual } from '../../../types/race';
import { SmarterEventEdition } from '../../../types/smarterEvent';
import { getDateFromTimestampOrDate } from '../../../utils';

type Props = {
    race?: Race,
    fileName: string,
    smarterEventEdition?: SmarterEventEdition,
    participants: RaceIndividual[]
}

export const exportToSheet = (participants: RaceIndividual[], fileName: string, race?: Race) => {
    var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    var fileExtension = '.xlsx';

    import('xlsx').then((XLSX) => {
        const wb = XLSX.utils.book_new();

        var exportparticipants = [] as any[]

        participants?.forEach((participant, index) => {
            var spreadsheetLine : any = {
                Position: index+1,
                Temps: participant.results?.finishedAt!= null ? moment.duration(moment(getDateFromTimestampOrDate(participant.results?.finishedAt!)).diff(moment(getDateFromTimestampOrDate(participant.results?.startedAt!)))).format("hh:mm'ss\"") : "",
                Dossard: participant.bibNumber,
                Prenom: participant.firstName,
                Nom: participant.lastName,
                Sexe: participant.gender,
                "Course": participant.race.id,
                Category: participant.category.name,
                "Année de naissance": participant.birthdayYear,
                "Code postal": participant.address.zipcode,
                Ville: participant.address.city,
                Pays: participant.address.country,
                "Date d'inscription": moment(getDateFromTimestampOrDate(participant.createdAt)).format("DD/MM/YYYY HH:mm"),
                // "Inscription par": participant.createdBy,
                // "Facture": participant.references.billId,
                ParticipantId: participant.id,
                Statut: participant.status.billing,
                "EPC": participant.epc,
                // UserId: participant.references.userId,
            }
            exportparticipants.push(spreadsheetLine)
        })
        const sheet = XLSX.utils.json_to_sheet(exportparticipants);
        XLSX.utils.book_append_sheet(wb, sheet, ("Participants").slice(0, 31));

        // var fileName = "Participants"
        // if (props.race != null)
        //     fileName = "Participants " + props.race.references.eventId + " " + props.race.smarterEvent.editionName + " " + props.race.name
        // else if (props.smarterEventEdition != null)
        //     fileName = "Participants " + props.smarterEventEdition.smarterEvent.eventName + " " + props.smarterEventEdition.name


        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    })
}

const ExportResultsButton: React.FunctionComponent<Props> = (props: Props) => {

    if (props.participants && props.participants.length > 0)
        return <Fragment><Button className="exportButton" onClick={() => exportToSheet(props.participants, props.fileName, props.race)}><FileEarmarkExcel/>Exporter vers Excel</Button ></Fragment>
    else
        return null
}

export default ExportResultsButton;