import firebase from 'firebase/app';
import 'firebase/storage';
import Resizer from 'react-image-file-resizer'

class FireabaseUploadAdapter {

    loader: any = null
    path: string = ""
    uploadTask: any = null
    reader: FileReader | null = null

    constructor(loader: any, path: string) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.path = path;
        console.log(this.path)
    }

    // Starts the upload process.
    async upload() {


        var storageRef = firebase.storage().ref();
        var imageRef: any = null

        return new Promise((resolve: any, reject: any) => {
            const reader = this.reader = new window.FileReader();

            // reader.addEventListener('load', () => {
            //     resolve({ default: reader.result });
            // });

            // reader.addEventListener('error', err => {
            //     reject(err);
            // });

            reader.addEventListener('abort', () => {
                this.uploadTask.cancel();
            });

            this.loader.file.then((file: any) => {
                console.log(file)
                imageRef = storageRef.child("cms/" + this.path + "/" + file.name)
                // imageRef = storageRef.child("test.jpg")
                console.log(imageRef)

                try {


                    Resizer.imageFileResizer(
                        file,
                        1024,
                        1024,
                        'JPEG',
                        100,
                        0,
                        uri => {
                            this.uploadTask = imageRef.put(uri).then(async function (snapshot: firebase.storage.UploadTaskSnapshot) {
                                console.log("Saving")

                                var url: string = await imageRef.getDownloadURL()
                                console.log(url)
                                resolve({
                                    default: url
                                })
                            }).catch((error: any) => {
                                console.log(error)
                            });
                            console.log(uri)
                        },
                        'blob'
                    );

                } catch (error) {
                    console.log(error)
                }

                reader.readAsDataURL(file);
            });

            console.log(this.loader);
        })
    }

    // Aborts the upload process.
    abort() {
        if (this.uploadTask != null) {
            var uploadT: any = this.uploadTask!
            uploadT.cancel()
        }
        // Reject the promise returned from the upload() method.
        // server.abortUpload();
    }
}

export default FireabaseUploadAdapter