import React from "react";
import { Race } from "../types/race";
import { SmarterEvent, SmarterEventEdition, UserProfile } from "../types/smarterEvent";

export const SmarterEventContext: React.Context<SmarterEvent | null> = React.createContext<SmarterEvent | null>(null);
export const SmarterEventEditionContext: React.Context<SmarterEventEdition | null> = React.createContext<SmarterEventEdition | null>(null);
export const RaceContext: React.Context<Race | null> = React.createContext<Race | null>(null);
export const RacesContext: React.Context<Race[] | null> = React.createContext<Race[] | null>(null);

export type NavigationContextType = {
    sideMenu: {
        opened: boolean, 
        setOpened: (opened: boolean) => void
    }
}
export const NavigationContext: React.Context<NavigationContextType | null> = React.createContext<NavigationContextType | null>(null)

export const UserProfileContext: React.Context<UserProfile | null> = React.createContext<UserProfile | null>(null);