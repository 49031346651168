import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Dropdown from '../../../components/Dropdown';
import * as FIRESTORE from '../../../constants/firestore';
import { ParticipantCartItem, Race, SoloCategory } from "../../../types/race";
import { SmarterEventEdition, UserProfile } from "../../../types/smarterEvent";
import RegistrationBaseForm, { getParticipantCartItem, getRegistrationFormDataFromUserProfile, RegistrationFormData } from './RegistrationBaseForm';

type Props = {
    smarterEventEdition: SmarterEventEdition,
    race: Race,
    category: SoloCategory,
    userProfile: UserProfile,
    handleAddParticipantInCart: (participantCartItem: ParticipantCartItem, createdBy: string) => void
}

const RegistrationAddContactForm: React.FunctionComponent<Props> = (props: Props) => {

    const { smarterEventEdition, race, category, userProfile } = props
    const [filteredContacts, setFilteredContacts] = useState<UserProfile[]>();
    const [contact, setContact] = useState<UserProfile>();

    const [contacts] = useCollectionData<UserProfile>(firebase.firestore()
        .collection(FIRESTORE.USERS_COL)
        .where("createdBy", "==", userProfile.id)
        .orderBy("firstName", "asc"), { idField: "id" });

    // const getUserProfile = (registrationFormData: RegistrationFormData) => {
    //     var userProfile: Partial<UserProfile> = {
    //         email: registrationFormData.email,
    //         firstName: registrationFormData.firstName,
    //         lastName: registrationFormData.lastName,
    //         address: registrationFormData.address,
    //         dateOfBirth: registrationFormData.dateOfBirth,
    //         gender: registrationFormData.gender,
    //         phoneNumber: registrationFormData.phoneNumber,
    //         createdBy: props.userProfile.id,
    //         createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp
    //     }
    //     return userProfile
    // }

    // const updateUserProfile = (registrationFormData: RegistrationFormData) => {
    //     firebase.firestore().collection(FIRESTORE.USERS_COL).doc(userProfile!.id).update(getUserProfile(registrationFormData))
    // }

    // const updateExistingUserAndAddToCart = (registrationFormData: RegistrationFormData) => {
    //     updateUserProfile(registrationFormData)
    //     props.handleAddParticipant(getParticipant(race, category, registrationFormData, userProfile!.id!))
    // }

    const addContactToCart = (registrationFormData: RegistrationFormData) => {

        var participantCartItem: ParticipantCartItem = getParticipantCartItem(race, category, registrationFormData)
        if (contact != null)
            participantCartItem.userId = contact?.id
        props.handleAddParticipantInCart(participantCartItem, userProfile!.id!)
    }

    const getFilteredContacts = useCallback((userProfiles: UserProfile[] | undefined, searchText: string) => {
        return contacts?.filter((user: UserProfile) => {
            if (user.firstName && user.lastName)
                return (user.id !== userProfile.id) && ((user.firstName.toLowerCase().indexOf(searchText) !== -1) || (user.lastName.toLowerCase().indexOf(searchText) !== -1))
            else return false
        })
    }, [contacts, userProfile.id])

    const filterContacts = (event: React.ChangeEvent<any>) => {
        var search = event.currentTarget.value.toLowerCase()
        setFilteredContacts(getFilteredContacts(contacts, search))
    }

    useEffect(() => {
        setFilteredContacts(getFilteredContacts(contacts, ""))
    }, [contacts, getFilteredContacts])

    return <Fragment>
        <Dropdown>
            <Dropdown.Search onChange={(event) => filterContacts(event)} placeholder="Chercher" />
            <Dropdown.List>
            {filteredContacts?.map((contact) => {
                return <Dropdown.Item key={contact.id} onClick={() => setContact(contact)}>{contact.firstName}</Dropdown.Item>
            })}
            </Dropdown.List>
        </Dropdown>
        <RegistrationBaseForm id="participantForm" restrictEditingUserInfo={contact !== null} defaultValues={getRegistrationFormDataFromUserProfile(contact)} smarterEventEdition={smarterEventEdition} category={category} handleSubmit={addContactToCart} />
    </Fragment>
}

export default RegistrationAddContactForm