import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import { default as React, useContext, useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Button from '../../../components/Button';
import Gender from '../../../components/Gender';
import Modal from "../../../components/Modal";
import Wizard from '../../../components/Wizard';
import { SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import { PARTICIPANT_BILLING_STATUS } from '../../../constants/smarterEvent';
import { Category, Race, RaceIndividual, Team, TeamCategory } from '../../../types/race';
import { SmarterEvent, SmarterEventEdition, UserProfile } from '../../../types/smarterEvent';
import { getDateFromTimestampOrDate } from '../../../utils';
import RegistrationBaseForm, { RegistrationFormData } from '../../race/components/RegistrationBaseForm';
import TeamCategoriesList from '../../race/components/TeamCategoriesList';
import TeamRegistrationModal from '../../race/components/TeamRegistrationModal';

type Props = {
    show: boolean,
    handleCancel: () => void
}

const AddTeamModal: React.FunctionComponent<Props> = (props: Props) => {

    const smarterEvent: SmarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition: SmarterEventEdition = useContext(SmarterEventEditionContext)!;
    const userProfile: UserProfile = useContext(UserProfileContext)!;
    const [category, setCategory] = useState<TeamCategory | null>()
    const [teamCategories, setTeamCategories] = useState<TeamCategory[]>([])

    const [step, setStep] = useState<number>(0)
    const [formValues, setFormValues] = useState<Record<string, RegistrationFormData>>({})

    const [categories] = useCollectionData<Category>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_CATEGORIES_COL).where("teamOrSolo", "==", "team"), { idField: "id" })

    const [races] = useCollectionData<Race>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACES_COL), { idField: "id" })

    const handleCancel = () => {
        setCategory(null);
        setStep(0)
        props.handleCancel()
    }

    const newRegistration = (category: TeamCategory) => {
        setCategory(category)
    }

    useEffect(() => {
        if (categories != null)
            setTeamCategories(categories.map((category) => {
                var newCategory = category as TeamCategory
                return newCategory
            }))
    }, [categories])

    const onSubmit = (registrationFormData: RegistrationFormData) => {
        console.log("onSubmit")
        setFormValues({
            ...formValues,
            [`form-${step}`]: registrationFormData
        })
        setStep(step + 1)
        // var raceParticipant: RaceIndividual = {
        //     address: {
        //         city: formData.address?.city!,
        //         country: formData.address?.country!,
        //         zipcode: formData.address?.zipcode!
        //     },
        //     category: category!,
        //     createdAt: firebase.firestore.FieldValue.serverTimestamp() as unknown as firebase.firestore.Timestamp,
        //     createdBy: userProfile.id!,
        //     firstName: formData.firstName,
        //     gender: formData.gender!,
        //     lastName: formData.lastName,
        //     birthdayYear: formData.dateOfBirth!.getUTCFullYear(),
        //     status:{ billing: PARTICIPANT_BILLING_STATUS.BILLED },
        //     references:{
        //         raceId: race!.id,
        //         categoryId: category!.id,
        //         eventId: smarterEvent.id,
        //         editionId: smarterEventEdition.id,
        //     }
        // }

        // firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        //     .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        //     .collection(FIRESTORE.RACE_INDIVIDUALS_COL).add(raceParticipant).then(() => {
        //         handleCancel()
        //     }
        // )

    }

    const teamMatesSteps: any[] = []

    // <RegistrationBaseForm category={category} handleSubmit={handleSubmit} id="participantForm" smarterEventEdition={smarterEventEdition} />


    const saveTeam = async (formValues: any, category: TeamCategory) => {

        console.log(formValues)

        var teamName = formValues["form-0"].teamName

        var teamMates: {
            firstName: string;
            lastName: string;
            gender: "male" | "female";
            participantId: string,
            type: "CLM_FIXED_TIME" | "CLM_FIXED_DISTANCE" | "INLINE_FIXED_TIME" | "INLINE_FIXED_DISTANCE" | "NONE"
        }[] = []

        var batch = firebase.firestore().batch()


        Object.entries(formValues).forEach((participant: any, idx: number) => {

            console.log(category)
            console.log(category!.team[idx])

            if (idx > 0) {
                var participantRef = firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
                    .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
                    .collection(FIRESTORE.RACE_INDIVIDUALS_COL).doc()


                var raceIndividual: RaceIndividual = {
                    address: {
                        city: participant[1].address?.city!,
                        country: participant[1].address?.country!,
                        zipcode: participant[1].address?.zipcode!
                    },
                    category: category!,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp() as unknown as firebase.firestore.Timestamp,
                    createdBy: userProfile.id!,
                    firstName: participant[1].firstName,
                    gender: participant[1].gender!,
                    lastName: participant[1].lastName,
                    birthdayYear: participant[1].dateOfBirth!.getUTCFullYear(),
                    status: { billing: PARTICIPANT_BILLING_STATUS.BILLED },
                    references: {
                        eventId: smarterEvent.id,
                        editionId: smarterEventEdition.id,
                    },
                    race: participant.race
                }

                batch.set(participantRef, raceIndividual)

                teamMates.push({
                    firstName: participant[1].firstName,
                    lastName: participant[1].lastName,
                    gender: participant[1].gender,
                    participantId: participantRef.id,
                    type: category!.team[idx].type
                })
            }
        })

        var team: Team = {
            name: teamName,
            createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
            createdBy: userProfile.id!,
            teamMates: teamMates,
            status: { billing: PARTICIPANT_BILLING_STATUS.BILLED },
            references: {
                eventId: smarterEvent.id,
                editionId: smarterEventEdition.id
            }
        }

        var teamRef = firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.RACE_TEAMS_COL).doc()

        batch.set(teamRef, team)

        batch.commit().then(() => {
            handleCancel()
        })

        // var userId = userProfile?.id

        // if (userProfile === null) {
        //     console.log("userProfile is null")
        //     await newUser(
        //         formValues["form-0"].email,
        //         formValues["form-0"].password,
        //         formValues["form-0"].firstName,
        //         formValues["form-0"].lastName,
        //         formValues["form-0"].gender,
        //         formValues["form-0"].dateOfBirth,
        //         formValues["form-0"].address,
        //         formValues["form-0"].phoneNumber)
        //         .then((userId2) => {
        //             console.log(userId2)
        //             userId = userId2
        //             console.log(userId)
        //         })
        //         .catch((error) => {
        //             if (error.code === "auth/email-already-in-use") {

        //                 console.log("Account exists !!!")
        //                 // The account already exists
        //                 // if (props.handleAccountAlreadyExists)
        //                 //     props.handleAccountAlreadyExists()
        //             } else {
        //                 console.log(error.message)
        //             }
        //         })
        // }
        // console.log(userId)

        // var teamMatesFormValues: any[] = []

        // Object.entries(formValues).forEach((formValue: any, idx) => {
        //     teamMatesFormValues.push(formValue[1])
        // })

        // var batch = firebase.firestore().batch();
        // var teamMatesCartItems: ParticipantCartItem[] = []


        // teamMatesFormValues.forEach((teamMate) => {
        //     // To add in cart
        //     teamMatesCartItems.push({
        //         raceId: teamMate.raceId,
        //         address: teamMate.address,
        //         category: {
        //             id: category.id,
        //             name: category.name,
        //             price: category.price,
        //             type: category.type
        //         },
        //         dateOfBirth: teamMate.dateOfBirth,
        //         email: teamMate.email,
        //         phoneNumber: teamMate.phoneNumber,
        //         raceName: teamMate.raceName,
        //         firstName: teamMate.firstName,
        //         lastName: teamMate.lastName,
        //         gender: teamMate.gender
        //     })
        // })

        // var teamName = formValues["form-0"].teamName
        // firebase.firestore()
        //     .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
        //     .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
        //     .collection(FIRESTORE.CARTS_COL).doc(userId)
        //     .collection(FIRESTORE.CARTS_ITEMS_COL).add({
        //         type: "raceTeam",
        //         description: "Inscription équipe - " + teamName,
        //         price: category.price,
        //         data: {
        //             teamName: teamName,
        //             teamMates: teamMatesCartItems,
        //             category: {
        //                 id: category.id,
        //                 name: category.name,
        //                 price: category.price,
        //                 type: "team"
        //             }
        //         }
        //     })

        // batch.commit()
    }

    if (category != null)
        category.team?.forEach((teamMateCategory, idx) => {
            teamMatesSteps.push(<Wizard.Step key={`form-${idx + 1}`}>
                <h3>{teamMateCategory.raceName}: <Gender value={teamMateCategory.gender} /> {moment(getDateFromTimestampOrDate(teamMateCategory.birthDateFrom)).format("DD/MM/YYYY")} à {moment(getDateFromTimestampOrDate(teamMateCategory.birthDateTo)).format("DD/MM/YYYY")}</h3>
                <RegistrationBaseForm id={`form-${idx + 1}`} restrictEditingUserInfo={false} smarterEventEdition={smarterEventEdition!} category={category} handleSubmit={onSubmit} defaultValues={formValues[`form-${idx + 1}`]} />
            </Wizard.Step>)
        })

        if (category == null)
            return <Modal show={props.show}>
        <Modal.Header>
            <h1>Choisir catégorie</h1>
        </Modal.Header>
        <Modal.Body>
            <TeamCategoriesList races={races} categories={teamCategories!} newTeamRegistration={newRegistration} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" onClick={handleCancel}>Annuler</Button>
            <Button type="submit" form="teamForm">Suivant</Button>
        </Modal.Footer>
    </Modal>
    else 
    return <TeamRegistrationModal showModal={props.show} category={category!} handleCancel={handleCancel} handleSubmit={saveTeam} />
}

export default AddTeamModal;
