import firebase from "firebase/app";
import "firebase/firestore";
import React from 'react';
import { useCollectionData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { RacesContext, SmarterEventContext, SmarterEventEditionContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import * as ROUTES from '../../constants/routes';
import SmarterEventEditionConsolePage from '../../pages/smarterEvent/console/SmarterEventEditionConsolePage';
import { Race } from '../../types/race';
import { SmarterEvent, SmarterEventEdition } from '../../types/smarterEvent';
import RaceConsoleRouter from './RaceConsoleRouter';

const SmarterEventEditionRouter: React.FunctionComponent = () => {

    const { eventId, editionId } = useParams<{ eventId: string, editionId: string }>();

    const [smarterEvent, loadingSmarterEvent] = useDocumentDataOnce<SmarterEvent>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(eventId), { idField: "id" })

    const [smarterEventEdition, loadingSmarterEventEdition] = useDocumentDataOnce<SmarterEventEdition>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(eventId)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(editionId), { idField: "id" })

    const [races, loadingRaces] = useCollectionData<Race>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(eventId)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(editionId)
        .collection(FIRESTORE.RACES_COL), { idField: "id" })

    if (loadingSmarterEventEdition || loadingRaces || loadingSmarterEvent) return null
    else if (smarterEvent === undefined || smarterEventEdition === undefined)
        return <Redirect to={ROUTES.ERROR_404} />
    else {
        window.document.title = smarterEventEdition.references.eventName + " " + smarterEventEdition?.name + " - SmarterEvents.ch"

        return <SmarterEventContext.Provider value={smarterEvent}>
            <SmarterEventEditionContext.Provider value={smarterEventEdition}>
                <RacesContext.Provider value={races!}>
                    <Switch>
                        <Route exact path={`/:eventId/:editionId${ROUTES.EVENTS_RACE}/:raceId`} component={RaceConsoleRouter} />
                        <Route exact path="/:eventId/:editionId" component={SmarterEventEditionConsolePage} />
                    </Switch>
                </RacesContext.Provider>
            </SmarterEventEditionContext.Provider>
        </SmarterEventContext.Provider>
    }
}

export default SmarterEventEditionRouter