import React, { Fragment, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import { RaceContext, SmarterEventContext, SmarterEventEditionContext } from '../constants/context';
import * as ROUTES from '../constants/routes';


type Props = {
    isAdmin?: boolean
}

const RaceBreadcrumb: React.FunctionComponent<Props> = (props: Props) => {

    const smarterEvent = useContext(SmarterEventContext);
    const smarterEventEdition = useContext(SmarterEventEditionContext);
    const race = useContext(RaceContext);
    const location = useLocation();

    var smarterEventLink: string | null = null;
    if (smarterEvent !== null) {
        smarterEventLink = (props.isAdmin) ? "/" + smarterEvent.id : "/"
    }

    var smarterEventEditionLink: string | null = null;
    if (smarterEventEdition !== null) {
        smarterEventEditionLink = (props.isAdmin) ? `/${smarterEvent!.id}/${smarterEventEdition.id}` : `/${smarterEventEdition.id}`
    }

    var smarterEventEditionRaceLink: string | null = null;
    if (race !== null) {
        smarterEventEditionRaceLink = (props.isAdmin) ? `/${smarterEvent!.id}/${smarterEventEdition!.id}${ROUTES.EVENTS_RACE}/${race.id}` : `/${smarterEventEdition!.id}${ROUTES.EVENTS_RACE}/${race.id}`
    }

    return <Fragment>
        <Breadcrumb>
            {(smarterEventLink !== null) ?
                (smarterEventLink === location.pathname) ?
                <Breadcrumb.Item>{smarterEvent!.name}</Breadcrumb.Item> :
                <Breadcrumb.Item><Link to={smarterEventLink}>{smarterEvent!.name}</Link></Breadcrumb.Item>
                : null}
            {smarterEventEditionLink !== null ?
                (smarterEventEditionLink === location.pathname) ?
                <Breadcrumb.Item>{smarterEvent!.name}</Breadcrumb.Item> :
                <Breadcrumb.Item><Link  to={smarterEventEditionLink}>{smarterEventEdition!.name}</Link></Breadcrumb.Item>
                : null}
            {smarterEventEditionRaceLink !== null ?
                (smarterEventEditionRaceLink === location.pathname) ?
                <Breadcrumb.Item>{race!.name}</Breadcrumb.Item> :
                <Breadcrumb.Item><Link  to={smarterEventEditionRaceLink}>{race!.name}</Link></Breadcrumb.Item>
                : null}
        </Breadcrumb>
    </Fragment>
}

export default RaceBreadcrumb