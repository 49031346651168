import 'firebase/firestore';
import moment from 'moment';
import React, { Fragment } from "react";
import { Calendar2, Cash, Person } from 'react-bootstrap-icons';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateRange from '../../../components/DateRange';
import Gender from '../../../components/Gender';
import { Race, TeamCategory } from '../../../types/race';
import { getDateFromTimestampOrDate } from "../../../utils";

type Props = {
    category: TeamCategory,
    races: Record<string, Race>,
    newTeamRegistration?: (category: TeamCategory) => void,
}

const SoloCategoryCard: React.FunctionComponent<Props> = (props: Props) => {

    const { newTeamRegistration, category, races } = props

    const getData = () => {
        var data: any[] = [];
        category.team.forEach((teamMemberCategory) => {
            data.push({
                ...teamMemberCategory,
                raceName: (races[teamMemberCategory.raceId] != null) ? races[teamMemberCategory.raceId].name : teamMemberCategory.raceId
            })
        })
        return data;
    }
    var data = getData();
    return <Fragment>
        <Card className="category">
            <Card.Header>
                <h1>{props.category.name}</h1>
            </Card.Header>
            <Card.Data>
                {props.category.date ?
                    <p><Calendar2/>{moment(getDateFromTimestampOrDate(props.category.date)).format('LL')}</p>
                : null }
                {data.map((teamMemberCategory, idx) => {
                    return <Fragment key={idx}>
                        <p><Person />{teamMemberCategory.raceName} <Gender value={teamMemberCategory.gender}/> <DateRange from={getDateFromTimestampOrDate(teamMemberCategory.birthDateFrom)} to={getDateFromTimestampOrDate(teamMemberCategory.birthDateTo)}/></p>
                    </Fragment>
                })}
                {/* <p><Person/> {props.category.gender === "male" ? "Hommes" : props.category.gender === "female" ? "Femmes" : props.category.gender === "both" ? "Mixte" : "???"}</p>
                <p><Calendar /> {moment(getDateFromTimestampOrDate(props.category.birthDateFrom)).format("YYYY") + " - " + moment(getDateFromTimestampOrDate(props.category.birthDateTo)).format("YYYY")}</p> */}
                <p><Cash />Frais d'inscription: {props.category.price} CHF</p>
            </Card.Data>
            <Card.Footer>
                {newTeamRegistration != null ?
                    <Button onClick={() => newTeamRegistration(props.category)}>Inscrire une équipe</Button>
                    : null}
            </Card.Footer>
        </Card>
    </Fragment>
}

export default SoloCategoryCard