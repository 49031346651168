import classNames from 'classnames';
import { ReactNode } from 'react';
import '../assets/stylesheets/components/button.scss';

export type ButtonProps = {
    size?: 'small' | 'normal' | 'large',
    variant?: 'primary' | 'secondary' | 'link'
    children: ReactNode[] | string,
    type?: 'submit' | 'reset' | 'button';
    form?:string
    onClick?: () => any
    className?: string
}

const Button: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {

    const {size, variant, children, onClick, type, form} = props;

    return  <button type={type} form={form} onClick={onClick} className={classNames(`btn--${variant || 'primary'} btn--${size || 'normal'}`, props.className)}>{children}</button>
    
}

export default Button;