import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { useContext } from 'react';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { RaceContext, SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import { ParticipantCartItem, SoloCategory } from '../../../types/race';
import { CartItem } from '../../../types/smarterEvent';
import RegistrationAddContactForm from './RegistrationAddContactForm';
import RegistrationAddMyselfForm from './RegistrationAddMyselfForm';

type Props = {
    showModal: boolean,
    category: SoloCategory,
    addContact?: boolean
    handleCancel: () => void
    handleSubmit: () => void
}

const RegistrationModal: React.FunctionComponent<Props> = (props: Props) => {

    const { addContact, category } = props

    const smarterEvent = useContext(SmarterEventContext);
    const smarterEventEdition = useContext(SmarterEventEditionContext);
    const race = useContext(RaceContext);
    const userProfile = useContext(UserProfileContext);

    // const location = useLocation();

    // const [showCreateAccount, setShowCreateAccount] = useState<boolean | undefined>(undefined)

    // if (userProfile === null && showCreateAccount === undefined) setShowCreateAccount(true)

    // if (userProfile === null && redirectToSignUp) {
    //     return <Redirect to={{
    //         pathname: ROUTES.SIGNUP,
    //         state: { redirect: location.pathname }
    //     }} />
    // }

    // if (userProfile === null && redirectToSignIn) {
    //     return <Redirect to={{
    //         pathname: ROUTES.SIGNIN,
    //         state: { redirect: location.pathname }
    //     }} />
    // }

    // if (redirectToCart) {
    //     return <Redirect push to={`${ROUTES.USER}${ROUTES.USER_CART}`} />
    // }
    // if (redirectToRaces) {
    //     return <Redirect to={`/${smarterEventEdition!.id}${ROUTES.EVENTS_RACES}`} />
    // }

    const handleAddParticipantInCart = (participantCartItem: ParticipantCartItem, createdBy: string) => {

        var raceIndividual: CartItem = {
            type: "raceIndividual",
            description: "Inscription individuelle " + participantCartItem.firstName,
            price: participantCartItem.category.price,
            data: participantCartItem
        }

        if (participantCartItem.options != null) {
            raceIndividual.options = participantCartItem.options
        }

        firebase.firestore()
            .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
            .collection(FIRESTORE.CARTS_COL).doc(createdBy)
            .collection(FIRESTORE.CARTS_ITEMS_COL).add(raceIndividual).then(() => {
                props.handleSubmit()
            })
    }

    return <Modal show={props.showModal}>
        <Modal.Header>
            <h1>Inscriptions à {smarterEvent?.name} {smarterEventEdition?.name} - {race?.name}</h1>
        </Modal.Header>
        <Modal.Body>
            <h2>{props.category.name}</h2>
            {addContact ?
                <RegistrationAddContactForm
                    userProfile={userProfile!}
                    smarterEventEdition={smarterEventEdition!}
                    race={race!}
                    category={category}
                    handleAddParticipantInCart={handleAddParticipantInCart} />
                :
                <RegistrationAddMyselfForm
                    userProfile={userProfile}
                    smarterEventEdition={smarterEventEdition!}
                    race={race!}
                    category={category}
                    handleAddParticipantInCart={handleAddParticipantInCart} />
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" onClick={props.handleCancel}>Annuler</Button>
            <Button type="submit" form="participantForm">Dans le panier</Button>
        </Modal.Footer>
    </Modal>
}

export default RegistrationModal;
