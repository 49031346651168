import React from 'react';

const Footer: React.FunctionComponent = () => {

    return <footer>
        <div className="body">
            <div className="left">
                <h1>A propos</h1>
                <p>SmarterEvents a pour vocation de vous aider dans l'organisation d'événements sportifs, culturels...
                L'idée a germée depuis quelques années après avoir contribué à l'organisation de plusieurs
                événements, les solutions existantes ne répondant pas aux besoins de petits événements.</p>
            </div>
            <div className="right">
                <img src="/images/logo/logo_grey.svg" alt="Logo"></img>
                <p>Val-de-Charmey, Suisse</p>
                <p>smarterevents.ch &copy; 2019-{new Date().getFullYear()}</p>
                <p>info@smarterevents.ch</p>
            </div>
        </div>
    </footer>
}

export default Footer   