import firebase from 'firebase/app';
import 'firebase/firestore';
import { default as React, useContext, useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Button from '../../../components/Button';
import Modal from "../../../components/Modal";
import { SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import { PARTICIPANT_BILLING_STATUS } from '../../../constants/smarterEvent';
import { Category, Race, RaceIndividual, SoloCategory } from '../../../types/race';
import { SmarterEvent, SmarterEventEdition, UserProfile } from '../../../types/smarterEvent';
import RegistrationBaseForm, { RegistrationFormData } from '../../race/components/RegistrationBaseForm';
import SoloCategoriesList from '../../race/components/SoloCategoriesList';

type Props = {
    show: boolean,
    handleCancel: () => void
    handleSubmit: (raceIndividual: RaceIndividual) => void
}

const AddUserModal: React.FunctionComponent<Props> = (props: Props) => {

    const smarterEvent: SmarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition: SmarterEventEdition = useContext(SmarterEventEditionContext)!;
    const userProfile: UserProfile = useContext(UserProfileContext)!;
    const [race, setRace] = useState<Race | null>()
    const [category, setCategory] = useState<Category | null>()
    const [soloCategories, setSoloCategories] = useState<SoloCategory[]>([])

    // const [soloCategories] = useCollectionData<SoloCategory>(firebase.firestore()
    //     .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
    //     .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
    //     .collection(FIRESTORE.RACE_CATEGORIES_COL).where("raceId", "==", race.id).orderBy("birthDateFrom"), { idField: "id" })

    const [races] = useCollectionData<Race>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACES_COL), { idField: "id" })

    useEffect(() => {
        if (race != null)
            firebase.firestore()
                .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
                .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
                .collection(FIRESTORE.RACE_CATEGORIES_COL).where("raceId", "==", race.id).orderBy("birthDateFrom").get().then((qs: firebase.firestore.QuerySnapshot) => {
                    setSoloCategories(qs.docs.map((doc) => {
                        var newDoc = doc.data() as SoloCategory
                        newDoc.id = doc.id
                        return newDoc
                    }))
                })

    }, [race, smarterEvent.id, smarterEventEdition.id])

    const handleCancel = () => {
        setRace(null);
        setCategory(null);
        props.handleCancel()
    }

    const newRegistration = (category: Category) => {
        setCategory(category)
    }

    const handleSubmit = (formData: RegistrationFormData) => {
        var raceParticipant: RaceIndividual = {
            address: {
                city: formData.address?.city!,
                country: formData.address?.country!,
                zipcode: formData.address?.zipcode!
            },
            category: {
                id: category!.id,
                name: category!.name,
                price: category!.price,
                teamOrSolo: category!.teamOrSolo,
            },
            createdAt: firebase.firestore.FieldValue.serverTimestamp() as unknown as firebase.firestore.Timestamp,
            createdBy: userProfile.id!,
            firstName: formData.firstName,
            gender: formData.gender!,
            lastName: formData.lastName,
            birthdayYear: formData.dateOfBirth!.getUTCFullYear(),
            status: { billing: PARTICIPANT_BILLING_STATUS.BILLED },
            race: {
                id: race!.id,
                name: race!.name,
                type: (category as SoloCategory).type,
            },
            references: {
                eventId: smarterEvent.id,
                editionId: smarterEventEdition.id,
            }
        }

        firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.RACE_INDIVIDUALS_COL).add(raceParticipant).then(() => {
                // setRace(null);
                // setCategory(null);
                props.handleSubmit(raceParticipant)
            }
            )
    }

    return <Modal show={props.show}>
        <Modal.Header>
            <h1>Ajouter un participant</h1>
        </Modal.Header>
        <Modal.Body>
            {race != null ?
                category != null ?
                    <RegistrationBaseForm category={category} handleSubmit={handleSubmit} id="participantForm" smarterEventEdition={smarterEventEdition} />
                    :
                    <SoloCategoriesList categories={soloCategories!} handleRegistrations={[{ function: newRegistration, buttonText: "Inscrire" }]} />
                :
                <div className="buttonBox">
                    {races?.map((race) => {
                        return <Button key={race.id} onClick={() => { setRace(race) }}>{race.name}</Button>
                    })}
                </div>
            }
        </Modal.Body>
        <Modal.Header>
            <Button variant="link" onClick={handleCancel}>Annuler</Button>
            {race != null && category != null ?
                <Button type="submit" form="participantForm">Inscrire</Button>
                : null}
        </Modal.Header>
    </Modal>

}

export default AddUserModal;
