import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useContext } from "react";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Column, useTable } from 'react-table';
import Card from '../../../components/Card';
import Gender from '../../../components/Gender';
import Loader from '../../../components/Loader';
import { SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import { hasAdminAccess } from '../../../services/authorization';
import { Race, RaceIndividual } from '../../../types/race';
import ExportParticipantsButton from './ExportParticipantsButton';
import ParticipantCard from './ParticipantCard';

type Props = {
    race: Race,
}

const ParticipantsList: React.FunctionComponent<Props> = (props: Props) => {

    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const userProfile = useContext(UserProfileContext);

    const [participants, loading] = useCollectionData<RaceIndividual>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(props.race.references.eventId)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.race.references.editionId)
        .collection(FIRESTORE.RACE_INDIVIDUALS_COL).where("race.id", "==", props.race.id).orderBy("lastName"), { idField: "id" })

    const columns: Column<any>[] = React.useMemo(
        () => [
            //   {
            //   // Build our expander column
            //   id: 'expander', // Make sure it has an ID
            //   Header: '👇',
            //   Cell: ({ row }) =>
            //     // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            //     // to build the toggle for expanding a row
            //     row.canExpand ? (
            //       <span
            //         {...row.getToggleRowExpandedProps({
            //           style: {
            //             // We can even use the row.depth property
            //             // and paddingLeft to indicate the depth
            //             // of the row
            //             paddingLeft: `${row.depth * 2}rem`,
            //           },
            //         })}
            //       >
            //         {row.isExpanded ? '👇' : '👉'}
            //       </span>
            //     ) : null,
            // },
            {
                Header: 'Prénom',
                accessor: 'firstName',
            },
            {
                Header: 'Nom',
                accessor: 'lastName',
            },
            {
                Header: 'Genre',
                accessor: (row) => { return <Gender value={row.gender}/> },
            },
            {
                Header: 'Catégorie',
                accessor: 'category.name',
            },
            {
                Header: 'Année',
                accessor: (row) => { return row.birthdayYear }
            },
            {
                Header: 'Ville',
                accessor: (row) => { return row.address.zipcode + " " + row.address.city }
            },
        ],
        []
    )

    var data: RaceIndividual[] = []
    if (participants != null)
        data = participants;

    const { headerGroups, rows, prepareRow } = useTable({
        columns: columns,
        data: data,
    })

    return <Fragment>
        <div className="participants">
            {loading ? <Loader/> :
            participants != null && participants.length !== 0 ?
            <Fragment>
                {userProfile != null && hasAdminAccess(smarterEvent, userProfile.id!) ?
            <ExportParticipantsButton participants={participants} fileName={"Participants" + smarterEvent.name + " " + smarterEventEdition.name + " " + props.race.name}/>
            : null }
            <Card.Deck>
                {participants?.map((participant: RaceIndividual) => {
                    return <ParticipantCard key={participant.id} participant={participant} />
                })}
            </Card.Deck>
            
            <table>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()} className="py-0" style={{ verticalAlign: "middle" }}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>

            </table>
            </Fragment>
            :
            <div>Il n'y a pas encore de participants inscrits</div>}
        </div>
    </Fragment>
}

export default ParticipantsList