import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ArrowDown } from 'react-bootstrap-icons';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Column, useTable } from 'react-table';
import Dropdown from '../../components/Dropdown';
import Loader from '../../components/Loader';
import { RaceContext, SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import { hasAdminAccess } from '../../services/authorization';
import { Category, RaceIndividual } from '../../types/race';
import { getDateFromTimestampOrDate } from '../../utils';
import ExportResultsButton from './components/ExportResultsButton';

const LiveResultsPage: React.FunctionComponent = () => {

    const race = useContext(RaceContext)!;
    const [participants, setParticipants] = useState<RaceIndividual[]>([])
    const [category, setCategory] = useState<Category | undefined>()
    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const [loading, setLoading] = useState(false)
    const userProfile = useContext(UserProfileContext);

    const [categories] = useCollectionData<Category>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_CATEGORIES_COL).where("raceId", "==", race.id).orderBy("name"), { idField: "id" })

    const [teamCategories] = useCollectionData<Category>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_CATEGORIES_COL).where("races", "array-contains", race.id).orderBy("name"), { idField: "id" })

    const columns: Column<RaceIndividual>[] = React.useMemo(
        () => [
            //   {
            //   // Build our expander column
            //   id: 'expander', // Make sure it has an ID
            //   Header: '👇',
            //   Cell: ({ row }) =>
            //     // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            //     // to build the toggle for expanding a row
            //     row.canExpand ? (
            //       <span
            //         {...row.getToggleRowExpandedProps({
            //           style: {
            //             // We can even use the row.depth property
            //             // and paddingLeft to indicate the depth
            //             // of the row
            //             paddingLeft: `${row.depth * 2}rem`,
            //           },
            //         })}
            //       >
            //         {row.isExpanded ? '👇' : '👉'}
            //       </span>
            //     ) : null,
            // },
            {
                Header: '#',
                accessor: (row, index) => { return row.results!.finishedAt != null ? index + 1 : null },
            },
            {
                Header: 'Nom',
                accessor: (row) => { return row.firstName + " " + row.lastName },
            },
            {
                Header: 'Année',
                accessor: (row) => { return row.birthdayYear }
            },
            {
                Header: 'Ville',
                accessor: (row) => { return row.address.zipcode + " " + row.address.city }
            },
            {
                Header: 'Temps',
                accessor: (row) => { return row.results != null ? row.results.finishedAt != null ? moment.duration(moment(getDateFromTimestampOrDate(row.results?.finishedAt!)).diff(moment(getDateFromTimestampOrDate(row.results?.startedAt!)))).format("hh:mm'ss\"") : "Parti à " + moment(getDateFromTimestampOrDate(row.results?.startedAt!)!).format("hh:mm'ss\"") : null }
            },
        ],
        []
    )

    const compare = (individual1: RaceIndividual, individual2: RaceIndividual): number => {
        if ((individual1.results?.startedAt == null) && (individual2.results?.startedAt == null)) {
            return 0
        } else if ((individual1.results?.startedAt == null) && (individual2.results?.startedAt != null)) {
            return 1
        } else if ((individual1.results?.startedAt != null) && (individual2.results?.startedAt == null)) {
            return -1
        } else if ((individual1.results?.finishedAt == null) && (individual2.results?.finishedAt == null)) {
            return getDateFromTimestampOrDate(individual1.results!.startedAt!)!.getTime() - getDateFromTimestampOrDate(individual2.results?.startedAt!)!.getTime()
        } else if ((individual1.results?.finishedAt == null) && (individual2.results?.finishedAt != null)) {
            return 1
        } else if ((individual1.results?.finishedAt != null) && (individual2.results?.finishedAt == null)) {
            return -1
        } else
            return getDateFromTimestampOrDate(individual1.results!.finishedAt!)!.getTime() - getDateFromTimestampOrDate(individual2.results?.finishedAt!)!.getTime()
    }

    useEffect(() => {
        console.log(category)
        setLoading(true)
        if (category != null) {
            let data: any[] = []
            firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(race.references.eventId)
                .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(race.references.editionId)
                .collection(FIRESTORE.RACE_INDIVIDUALS_COL).where("category.id", "==", category.id).get().then((results) => {
                    results.docs.forEach((doc) => {
                        let participant: any = doc.data()
                        if (participant.results?.startedAt) {
                            data.push(participant)
                        }
                    })
                    setParticipants(data.sort((participant1, participant2) => {
                        return compare(participant1, participant2)
                    }))
                    setLoading(false)
                })
        } else {
            let data: any[] = []
            firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(race.references.eventId)
                .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(race.references.editionId)
                .collection(FIRESTORE.RACE_INDIVIDUALS_COL).get().then((results) => {
                    results.docs.forEach((doc) => {
                        let participant: any = doc.data()
                        if (participant.results?.startedAt) {
                            data.push(participant)
                        }
                    })
                    setParticipants(data.sort((participant1, participant2) => {
                        return compare(participant1, participant2)
                    }))
                    setLoading(false)
                })

        }
    }, [race, category])

    // Use the state and functions returned from useTable to build your UI
    const { headerGroups, rows, prepareRow } = useTable({
        columns: columns,
        data: participants,
    })

    return <Fragment>
        <h2>Résultats provisoires</h2>
        <Dropdown>
            <Dropdown.Toggle><ArrowDown />Catégorie</Dropdown.Toggle>
            <Dropdown.List>
                <Dropdown.Item key="scratch" onClick={() => setCategory(undefined)}>
                    Scratch
                </Dropdown.Item>
                {categories?.map((category) => {
                    return <Dropdown.Item key={category.id} onClick={() => setCategory(category)}>
                        {category.name}
                    </Dropdown.Item>
                })
                }
                {teamCategories?.map((category) => {
                    return <Dropdown.Item key={category.id} onClick={() => setCategory(category)}>
                        {category.name}
                    </Dropdown.Item>
                })
                }
            </Dropdown.List>
        </Dropdown>
        {userProfile != null && hasAdminAccess(smarterEvent, userProfile.id!) ?
        <ExportResultsButton fileName={`${race.name} ${category ? category.name : "Scratch"}`} participants={participants}/>
        :null }
        {loading ? <Loader /> :
            <Fragment>
                {category != null ?
                    <h2>{category?.name}</h2> : <h2>Scratch</h2>}
                {participants.length > 0 ?
                    <table>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {rows.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()} className="py-0" style={{ verticalAlign: "middle" }}>
                                                    {cell.render('Cell')}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                    : <Fragment>Aucun participant de cette catégorie n'est encore parti</Fragment>}
            </Fragment>}
    </Fragment>

}

export default LiveResultsPage