import { yupResolver } from '@hookform/resolvers/yup';
import 'firebase/firestore';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import DateRange from '../../../components/DateRange';
import Form from '../../../components/Form';
import Gender from '../../../components/Gender';
import Modal from '../../../components/Modal';
import Wizard from '../../../components/Wizard';
import { SmarterEventEditionContext } from '../../../constants/context';
import { TeamCategory } from '../../../types/race';
import { SmarterEventEdition } from '../../../types/smarterEvent';
import { getDateFromTimestampOrDate } from '../../../utils';
import RegistrationBaseForm, { RegistrationFormData } from './RegistrationBaseForm';

type Props = {
    showModal: boolean,
    category: TeamCategory,
    addContact?: boolean
    handleCancel: () => void
    handleSubmit: (formValues: any, category: TeamCategory) => void
}

const TeamRegistrationModal: React.FunctionComponent<Props> = (props: Props) => {
    const smarterEventEdition: SmarterEventEdition = useContext(SmarterEventEditionContext)!;
    // const [races, setRaces] = useState<{ id: string, name: string }[]>([])

    const [step, setStep] = useState<number>(0)
    const [formValues, setFormValues] = useState<Record<string, RegistrationFormData>>({})

    const validationSchema = useMemo(() => {
        return Yup.object({
            teamName: Yup.string().required("Nom d´équipe requis")
        })
    }, [])

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handleCancel = () => {
        setStep(0)
        props.handleCancel()
    }

    const onSubmit = (registrationFormData: RegistrationFormData) => {
        console.log("onSubmit")
        setFormValues({
            ...formValues,
            [`form-${step}`]: registrationFormData
        })
        setStep(step + 1)
        // var raceParticipant: RaceIndividual = {
        //     address: {
        //         city: formData.address?.city!,
        //         country: formData.address?.country!,
        //         zipcode: formData.address?.zipcode!
        //     },
        //     category: category!,
        //     createdAt: firebase.firestore.FieldValue.serverTimestamp() as unknown as firebase.firestore.Timestamp,
        //     createdBy: userProfile.id!,
        //     firstName: formData.firstName,
        //     gender: formData.gender!,
        //     lastName: formData.lastName,
        //     birthdayYear: formData.dateOfBirth!.getUTCFullYear(),
        //     status:{ billing: PARTICIPANT_BILLING_STATUS.BILLED },
        //     references:{
        //         raceId: race!.id,
        //         categoryId: category!.id,
        //         eventId: smarterEvent.id,
        //         editionId: smarterEventEdition.id,
        //     }
        // }

        // firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        //     .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        //     .collection(FIRESTORE.RACE_INDIVIDUALS_COL).add(raceParticipant).then(() => {
        //         handleCancel()
        //     }
        // )

    }

    const teamMatesSteps: any[] = []

    if (props.category != null)
    props.category.team?.forEach((teamMateCategory, idx) => {
            teamMatesSteps.push(<Wizard.Step key={`form-${idx + 1}`}>
                <h3>{teamMateCategory.raceName}: <Gender value={teamMateCategory.gender} /> <DateRange from={getDateFromTimestampOrDate(teamMateCategory.birthDateFrom)} to={getDateFromTimestampOrDate(teamMateCategory.birthDateTo)}/></h3>
                <RegistrationBaseForm id={`form-${idx + 1}`} restrictEditingUserInfo={false} smarterEventEdition={smarterEventEdition!} category={props.category} handleSubmit={onSubmit} defaultValues={formValues[`form-${idx + 1}`]} />
            </Wizard.Step>)
        })

    return <Modal show={props.showModal}>
        <Modal.Header>
            <h1>Ajouter une équipe</h1>
        </Modal.Header>
        <Modal.Body>
            {
                <Wizard step={step}>
                    <Wizard.Step>
                        <Form register={register} id="teamForm" errors={errors} onSubmit={handleSubmit(onSubmit)} yupSchema={validationSchema}>
                            <Form.InputText id="teamName" label="Nom d'équipe" />
                        </Form>
                    </Wizard.Step>
                    {teamMatesSteps.map((teamMatesStep) => {
                        return teamMatesStep
                    })}
                    <Wizard.Step>
                        <h2>Confirmation</h2>
                        {Object.entries(formValues).map((formValue: any, idx) => {
                            if (idx !== 0) {
                                return <Fragment key={idx}><h3>{props.category.races[idx-1]}</h3> {formValue[1].firstName} {formValue[1].lastName}</Fragment>
                            } else {
                                return null
                            }
                        })}
                    </Wizard.Step>
                </Wizard>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="link" onClick={handleCancel}>Annuler</Button>
                <Fragment>
                    {step !== 0 ?
                        <Button variant="link" type="button" onClick={() => { setStep(step) }}>Retour</Button>
                        : null}
                    {step === 0 ?
                        <Button type="submit" form="teamForm">Suivant</Button>
                        : <Fragment>
                            {step < props.category.team?.length + 1 ?
                                <Button type="submit" form={`form-${step}`}>Suivant</Button>
                                : null}
                            {step === props.category.team?.length + 1 ?
                                <Button onClick={() => props.handleSubmit(formValues, props.category)}>Dans le panier</Button>
                                : null}
                        </Fragment>}
                </Fragment>
        </Modal.Footer>
    </Modal>
}

export default TeamRegistrationModal;
