import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Modal from '../../../components/Modal';
import * as FIRESTORE from '../../../constants/firestore';
import { SmarterEvent, SmarterEventEdition } from '../../../types/smarterEvent';
import SmarterEventEditionCard from './SmarterEventEditionCard';


type Props = {
    smarterEvent: SmarterEvent,
}
const SmarterEventContainer: React.FunctionComponent<Props> = (props: Props) => {

    const [modal, showModal] = useState<boolean>(false);

    const [smarterEventEditions] = useCollectionData<SmarterEventEdition>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(props.smarterEvent.id).collection(FIRESTORE.EVENTS_EDITIONS_COL), { idField: "id" })

    return <Fragment>
        <div className="spread-apart">
            <h2>{props.smarterEvent.name}</h2>
            <Button onClick={() => showModal(true)}>Nouvelle édition</Button>
        </div>
        <Card.Deck>
            {smarterEventEditions?.map((smarterEventEdition) => {
                return <SmarterEventEditionCard isAdmin={true} key={smarterEventEdition.id} smarterEventEdition={smarterEventEdition} />
            })}
        </Card.Deck>
        <Modal show={modal}>
            <Modal.Body>
                À venir...
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={() => showModal(false)}>
                    Annuler
                </Button>
                {/* <Button type="submit" form="participantForm">S'inscrire</Button> */}
            </Modal.Footer>
        </Modal>
    </Fragment>
}

export default SmarterEventContainer