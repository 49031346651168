import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useContext, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Redirect, useLocation } from 'react-router';
import { RaceContext, SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import * as ROUTES from '../../constants/routes';
import { ParticipantCartItem, Race, SoloCategory, TeamCategory } from '../../types/race';
import { CartItem } from '../../types/smarterEvent';
import RegistrationModal from './components/RegistrationModal';
import SoloCategoriesList from './components/SoloCategoriesList';
import TeamCategoriesList from './components/TeamCategoriesList';
import TeamRegistrationModal from './components/TeamRegistrationModal';

const RaceSignUpPage: React.FunctionComponent = () => {

    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const userProfile = useContext(UserProfileContext);
    const location = useLocation()

    const [redirectToSignIn, setRedirectToSignIn] = useState<boolean>(false)

    const race = useContext(RaceContext)!;
    const [newRegistrationModal, setNewRegistrationModal] = useState<{ show: boolean, category: SoloCategory | null, addContact: boolean }>({ show: false, category: null, addContact: false });
    const [newTeamRegistrationModal, setNewTeamRegistrationModal] = useState<{ show: boolean, category: TeamCategory | null }>({ show: false, category: null });

    const [soloCategories, soloCategoriesLoading] = useCollectionData<SoloCategory>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_CATEGORIES_COL).where("raceId", "==", race.id).orderBy("name"), { idField: "id" })

    const [teamCategories, teamCategoriesLoading] = useCollectionData<TeamCategory>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_CATEGORIES_COL).where("races", "array-contains", race.id), { idField: "id" })

    const [races] = useCollectionData<Race>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACES_COL), { idField: "id" })

    // var now: number = new Date().getTime()

    if (race === undefined) return null

    // var registrationsOpened = false
    // if (race!.registrations) {
    //     var startRegistrationTime = getDateFromTimestampOrDate(race!.registrations.startTime).getTime()
    //     var endRegistrationTime = getDateFromTimestampOrDate(race!.registrations.endTime).getTime()
    //     if (now < endRegistrationTime && now > startRegistrationTime) {
    //         registrationsOpened = true
    //     }
    // }

    const newRegistration = (category: SoloCategory) => {
        setNewRegistrationModal({ show: true, category: category, addContact: false })
    }

    const newTeamRegistration = (category: TeamCategory) => {
        setNewTeamRegistrationModal({ show: true, category: category })
    }

    const newContactRegistration = (category: SoloCategory) => {
        setNewRegistrationModal({ show: true, category: category, addContact: true })
    }

    const redirectToSignup = () => {
        setRedirectToSignIn(true)

    }

    if (redirectToSignIn) return <Redirect to={{
        pathname: ROUTES.SIGNIN,
        state: { redirect: location.pathname }
    }} />


    const saveTeam = (formValues: any, category: TeamCategory) => {
        var userId = userProfile?.id

        var teamMatesFormValues: any[] = []

        Object.entries(formValues).forEach((formValue: any, idx) => {
            teamMatesFormValues.push(formValue[1])
        })

        var batch = firebase.firestore().batch();
        var teamMatesCartItems: ParticipantCartItem[] = []

        teamMatesFormValues.forEach((teamMate, idx) => {

            if (idx !== 0) {
                // To add in cart
                teamMatesCartItems.push({
                    race:
                    {
                        id: category.team[idx - 1].raceId,
                        name: category.team[idx - 1].raceName,
                        type: category.team[idx - 1].type
                    },
                    address: teamMate.address,
                    category: {
                        id: category.id,
                        name: category.name,
                        price: category.price,
                        teamOrSolo: category.teamOrSolo,
                    },
                    dateOfBirth: teamMate.dateOfBirth,
                    email: teamMate.email,
                    phoneNumber: teamMate.phoneNumber,
                    firstName: teamMate.firstName,
                    lastName: teamMate.lastName,
                    gender: teamMate.gender
                })
            }
        })

        var teamName = formValues["form-0"].teamName

        var cartItem: CartItem = {
            type: "raceTeam",
            description: "Inscription équipe - " + teamName,
            price: category.price,
            data: {
                teamName: teamName,
                teamMates: teamMatesCartItems,
                category: {
                    id: category.id,
                    name: category.name,
                    price: category.price,
                    teamOrSolo: "team",
                }
            }
        }


        firebase.firestore()
            .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
            .collection(FIRESTORE.CARTS_COL).doc(userId)
            .collection(FIRESTORE.CARTS_ITEMS_COL).add(cartItem)

        batch.commit()
        setNewTeamRegistrationModal({ show: false, category: null })
    }

    return <Fragment>
        {userProfile != null ?
            <Fragment>
                {soloCategoriesLoading ? <p>Loading</p> :
                    <SoloCategoriesList handleRegistrations={[{ function: newRegistration, buttonText: "S'inscrire" }, { function: newContactRegistration, buttonText: "Inscrire un contact" }]} categories={soloCategories ? soloCategories : []} />}
                {teamCategoriesLoading ? <p>Loading</p> :
                    <TeamCategoriesList newTeamRegistration={newTeamRegistration} races={races} categories={teamCategories ? teamCategories : []} />}
            </Fragment>
            :
            <Fragment>
                {soloCategoriesLoading ? <p>Loading</p> :
                    <SoloCategoriesList handleRegistrations={[{ function: newRegistration, buttonText: "S'inscrire" }, { function: redirectToSignup, buttonText: "Inscrire un contact" }]} categories={soloCategories ? soloCategories : []} />}
                {teamCategoriesLoading ? <p>Loading</p> :
                    <TeamCategoriesList newTeamRegistration={redirectToSignup} races={races} categories={teamCategories ? teamCategories : []} />}
            </Fragment>
        }
        {/* <Link to={`/${smarterEventEdition!.id}${ROUTES.EVENTS_RACES}`}>Retour à toutes les courses</Link> */}
        {newRegistrationModal.category != null ?
            <RegistrationModal addContact={newRegistrationModal.addContact} showModal={newRegistrationModal.show} category={newRegistrationModal.category} handleCancel={() => setNewRegistrationModal({ show: false, category: null, addContact: false })} handleSubmit={() => setNewRegistrationModal({ show: false, category: null, addContact: false })} />
            : null}
        {newTeamRegistrationModal.category != null ?
            <TeamRegistrationModal showModal={newTeamRegistrationModal.show} category={newTeamRegistrationModal.category} handleCancel={() => setNewTeamRegistrationModal({ show: false, category: null })} handleSubmit={saveTeam} />
            : null}
    </Fragment>

}

export default RaceSignUpPage