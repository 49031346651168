import classNames from 'classnames';
import '../assets/stylesheets/components/card.scss';

export type CardProps = {
    children: any,
    className?: string
}

interface CardSubComponents {
    Deck: React.FunctionComponent<CardProps>
    Header: React.FunctionComponent<CardProps>
    Data: React.FunctionComponent<CardProps>
    Body: React.FunctionComponent<CardProps>
    Footer: React.FunctionComponent<CardProps>
}

const Card: React.FunctionComponent<CardProps> & CardSubComponents = (props: CardProps) => {
    return <div className={classNames(props.className, "card")}>
        {props.children}
    </div>
}

const Deck = (props: CardProps) => {
    return <div className={classNames("card__deck", props.className)}>
        {props.children}
    </div>
}

const Header = (props: CardProps) => {
    return <div className={classNames("card__header", props.className)}>
        {props.children}
    </div>
}

const Data = (props: CardProps) => {
    return <div className={classNames("card__data", props.className)}>
        {props.children}
    </div>
}

const Body = (props: CardProps) => {
    return <div className={classNames("card__body", props.className)}>
        {props.children}
    </div>
}

const Footer = (props: CardProps) => {
    return <div className={classNames("card__footer", props.className)}>
        {props.children}
    </div>
}

Card.Deck = Deck
Card.Header = Header
Card.Data = Data
Card.Body = Body
Card.Footer = Footer

export default Card
