export const SERVICE = '/_service';

export const CMS_UPLOAD = '/cms/upload';


export const HOME = 'http://welcome.smarterevents.ch';

export const LANDING = '/';

export const PUBLIC = '/public';

export const SIGNUP = '/signup';
export const SIGNIN = '/signin';
export const FORGOT_PASSWORD = '/forgotPassword';

export const CONSOLE = 'console'
export const ADMIN = 'admin'

export const CHECKIN = '/checkin';

export const EVENTS = '/events';
export const EVENT = '/event';
export const EVENT_EDIT = '/edit';

export const EVENTS_RACES = '/races';
export const EVENTS_RACE = '/race';
export const EVENTS_RACE_SIGNUP = '/signup';
export const EVENTS_RACE_CATEGORIES = '/categories';
export const EVENTS_RACE_PARTICIPANTS = '/participants';
export const EVENTS_RACE_MANAGEMENT = '/management';
export const EVENTS_RACE_CLMMANAGEMENT = '/clmmanagement';
export const EVENTS_RACE_RESULTS = '/results';
export const EVENTS_RACE_LIVERESULTS = '/liveresults';

export const EVENTS_POS = '/pos';
export const EVENTS_POS_CATEGORIES = '/categories';
export const EVENTS_POS_SALES = '/sales';

export const USER = '/user';
export const USER_EDIT_PROFILE = '/editProfile';
export const BILLS = '/bills';
export const USER_CART = '/cart';
export const MYPARTICIPANTS = '/registrations';

export const ERROR_404 = LANDING

