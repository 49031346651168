import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SmarterEventContext } from '../constants/context';
import * as FIRESTORE from '../constants/firestore';
import * as ROUTES from '../constants/routes';
import { SmarterEvent } from '../types/smarterEvent';
import { getSubdomain } from '../utils';
import RedirectToLatestEdition from './RedirectToLatestEdition';
import SmarterEventEditionRouter from './SmarterEventEditionRouter';

const SmarterEventRouter: React.FunctionComponent = () => {

    // TODO: Cache/Retrieve the result in/from local storage
    const [smarterEvent, loadingSmarterEvent] = useDocumentDataOnce<SmarterEvent>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(getSubdomain()), { idField: "id" })

    if (loadingSmarterEvent) return null
    else if (smarterEvent === undefined) {
        return <Redirect to={ROUTES.ERROR_404} />
    }
    else {
        window.document.title = smarterEvent.name + " - SmarterEvents.ch"
        return <Fragment>
            <SmarterEventContext.Provider value={smarterEvent}>
                <Switch>
                    <Route path="/:editionId" component={SmarterEventEditionRouter} />
                    <Route path="*" component={RedirectToLatestEdition} />
                </Switch>
            </SmarterEventContext.Provider>
        </Fragment >
    }
}

export default SmarterEventRouter