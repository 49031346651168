import React from "react";
import { Bookmarks, Calendar2, Eye } from 'react-bootstrap-icons';
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Card from "../../../../components/Card";
import List from "../../../../components/List";
import * as ROUTES from '../../../../constants/routes';
import { Race } from "../../../../types/race";
import { getDateFromTimestampOrDate, getDomain } from "../../../../utils";

type Props = {
    race: Race,
    isAdmin?: boolean
}

const RaceCard: React.FunctionComponent<Props> = (props: Props) => {

    // var now: number = new Date().getTime()
    // var registrations = false
    // var registrationsOpened = false
    // if (props.race.registrations) {
    //     registrations = true;
    //     var startRegistrationTime = getDateFromTimestampOrDate(props.race.registrations.startTime).getTime()
    //     var endRegistrationTime = getDateFromTimestampOrDate(props.race.registrations.endTime).getTime()
    //     if (now < endRegistrationTime && now > startRegistrationTime) {
    //         registrationsOpened = true
    //     }
    // }

    // const getLink = () => {
    //     if (props.isAdmin) {
    //         return window.location.protocol + "//" + props.race.smarterEvent.eventId + "." + getDomain()
    //     } else {
    //         return ""
    //     }
    // }

    // const resolveSportImageURL = (sportName: string) => {
    //     if (SPORTS[sportName] !== undefined)
    //         return "/images/sports/" + sportName + ".jpg"
    //     else
    //         return "/images/sports/default.jpg"
    // }

    return <Card>
        <Card.Header>
            <h1>{props.race.name}</h1>
        </Card.Header>
        <Card.Data>
            <div>
                <Calendar2/> <span>Inscriptions: <Moment local locale="fr" format="LL">{getDateFromTimestampOrDate(props.race.registrations?.startTime!)!}</Moment> - <Moment local locale="fr" format="LL" >{getDateFromTimestampOrDate(props.race.registrations?.endTime!)!}</Moment></span>
            </div>
        </Card.Data>
        {/* <div style={{ position: "relative" }}>
            <Card.Img src={resolveSportImageURL(props.race.sport)} alt="Card image" style={{ objectFit: "cover", height: "110px", filter: "brightness(50%)" }} />
            <Card.ImgOverlay className="text-light" >
                <Card.Title>{props.race.name}</Card.Title>
                <Card.Subtitle>{SPORTS[props.race?.sport] ? SPORTS[props.race?.sport].name : null}</Card.Subtitle>
            </Card.ImgOverlay>
        </div> */}

        <Card.Body>
            <List>
                <List.Item>
                    <Link to={`/${props.race.references.eventId}/${props.race.references.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}`}>
                        <Bookmarks /> Éditer les catégories
            </Link>
                </List.Item>
                <List.Item>
                    <a href={window.location.protocol + `//${props.race.references.eventId}.${getDomain()}/${props.race.references.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}`}>
                        <Eye /> Voir la page course
            </a>
                </List.Item>
            </List>
        </Card.Body>
        {/* {props.isAdmin ?
            </Card.Body>
            :
            <Card.Body>
                {registrations && !registrationsOpened ? <Card.Text className="text-muted small">Les inscriptions sont actuellement fermées</Card.Text> : null}
                <ListGroup>
                    {registrations && registrationsOpened ?
                        <ListGroupItem className="border-0 px-0 py-0">
                            <Link to={`/${props.race.smarterEvent.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}><PersonPlus /> Inscription</Link><br />
                        </ListGroupItem>
                        : null}
                    <ListGroupItem className="border-0 px-0 py-0">
                        <Link to={`/${props.race.smarterEvent.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}${ROUTES.EVENTS_RACE_PARTICIPANTS}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                            <FileEarmarkText /> Liste de départ
                        </Link>
                    </ListGroupItem>
                    {props.race.chrono?.enabled ?
                        <ListGroupItem className="border-0 px-0 py-0">
                            <Link to={`/${props.race.smarterEvent.editionId}${ROUTES.EVENTS_RACE}/${props.race.id}${ROUTES.EVENTS_RACE_LIVERESULTS}`} style={{ textDecoration: 'none', verticalAlign: "middle" }}>
                                <Trophy /> Résultats live
                            </Link>
                        </ListGroupItem>
                        : null}
                </ListGroup>
            </Card.Body>
        } */}
    </Card>

}

export default RaceCard