import 'firebase/firestore';
import React, { Fragment } from 'react';
import Footer from '../../layouts/Footer';
import TopBarMenu from '../../layouts/user/TopBarMenu';
import MyParticipantsList from './components/MyParticipantsList';


const MyParticipantsPage: React.FunctionComponent = () => {

    return <Fragment>
        <TopBarMenu />
        <main className="main">
            <h1>Vos inscriptions</h1>
            <MyParticipantsList />
        </main>
        <Footer />
    </Fragment>

}

export default MyParticipantsPage