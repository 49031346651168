import { yupResolver } from '@hookform/resolvers/yup';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Fragment, useMemo, useState } from "react";
import { ClipboardPlus, Flag } from 'react-bootstrap-icons';
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Form from '../../../components/Form';
import * as FIRESTORE from '../../../constants/firestore';
import * as SMARTEREVENT from '../../../constants/smarterEvent';
import { Race, RaceIndividual, SoloCategory, Team } from "../../../types/race";

type Props = {
    raceIndividual: RaceIndividual
    handleSubmit: () => void
    smarterEventId: string
    smarterEventEditionId: string
}


const URL_SCANNER_GETEPC = 'http://localhost:4567/getEPC';

const CheckInForm: React.FunctionComponent<Props> = (props: Props) => {

    const [error, setError] = useState<string>()

    const [race] = useDocumentDataOnce<Race>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(props.smarterEventId)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEditionId)
        .collection(FIRESTORE.RACES_COL).doc(props.raceIndividual.race.id)
        , { idField: "id" })

    const [category] = useDocumentDataOnce<SoloCategory>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(props.smarterEventId)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEditionId)
        .collection(FIRESTORE.RACE_CATEGORIES_COL).doc(props.raceIndividual.category.id)
        , { idField: "id" })

    const validationSchema = useMemo(() => {
        return Yup.object({
            epc: (race?.chrono?.enabled ? Yup.number()
                .required('EPC requis')
                .typeError("Nombre non valide") : Yup.number()),
            bibNumber: Yup.number()
                .required('Numéro de dossard requis')
                .typeError("Nombre non valide"),
            paidCheckbox: Yup.boolean()
                .test('paidCheckbox', "Doit être payé", (checked: boolean | undefined) => { return checked || props.raceIndividual.status.billing === SMARTEREVENT.PARTICIPANT_BILLING_STATUS.PAID })
        })
    }, [props.raceIndividual.status.billing, race?.chrono?.enabled])

    const { register, handleSubmit, errors, getValues, setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const scan = (event: any) => {
        event.preventDefault();
        setError("")
        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest()
        xhr.responseType = 'json';

        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the state of the component with the result here
            setValue("epc", xhr.response["EPC"])
            if (xhr.response["Error"]["Message"]) {
                setError(xhr.response["Error"]["Message"])
            }
            console.log(xhr.response["EPC"])
        })

        xhr.onerror = function () { // only triggers if the request couldn't be made at all
            setError("Le serveur du lecteur RFID ne répond pas")
        };

        // open the request with the verb and the url
        xhr.open('GET', URL_SCANNER_GETEPC)
        // send the request
        xhr.send()
    }

    const checkIn = () => {
        let epc = getValues("epc")
        let bibNumber = getValues("bibNumber")
        let raceIndividual = props.raceIndividual
        if (raceIndividual.category.teamOrSolo === "solo")
            checkInIndividual(raceIndividual, epc, bibNumber)
        else
            checkInTeam(raceIndividual, epc, bibNumber)
    }

    const checkInIndividual = (raceIndividual: RaceIndividual, epc: string, bibNumber: number) => {
        raceIndividual.status!.billing = SMARTEREVENT.PARTICIPANT_BILLING_STATUS.PAID
        raceIndividual.bibNumber = bibNumber
        if (epc != null)
            raceIndividual.epc = epc.padStart(24, "0")
        firebase.firestore()
            .collection(FIRESTORE.EVENTS_COL).doc(props.smarterEventId)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEditionId)
            .collection(FIRESTORE.RACE_INDIVIDUALS_COL).doc(props.raceIndividual.id).set(raceIndividual).then(() => {
                props.handleSubmit()
            })
    }


    const checkInTeam = (raceIndividual: RaceIndividual, epc: string, bibNumber: number) => {

        var teamRef = firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(props.smarterEventId)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEditionId)
            .collection(FIRESTORE.RACE_TEAMS_COL).doc(raceIndividual.references.teamId)
        firebase.firestore().runTransaction((transaction) => {
            return transaction.get(teamRef).then((teamDS) => {

                var individualRef = firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(props.smarterEventId)
                    .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEditionId)
                    .collection(FIRESTORE.RACE_INDIVIDUALS_COL).doc(raceIndividual.id)
                return transaction.get(individualRef).then((individualDS) => {

                    var individual: RaceIndividual = individualDS.data() as RaceIndividual
                    if (raceIndividual.id === individualDS.id) {
                        individual.bibNumber = bibNumber
                    }
                    individual.status.billing = SMARTEREVENT.PARTICIPANT_BILLING_STATUS.PAID
                    if (epc != null) {
                        individual.epc = epc
                    }
                    transaction.set(individualRef, individual)

                    var team: Team = teamDS.data() as Team
                    team.status.billing = SMARTEREVENT.PARTICIPANT_BILLING_STATUS.PAID
                    transaction.set(teamRef, team)
                })
            })
        }).then(() => {
            props.handleSubmit()
        })
        //     team.teamMates.forEach((teamMate) => {
        //         firebase.firestore()
        //         .collection(FIRESTORE.EVENTS_COL).doc(props.smarterEventId)
        //         .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEditionId)
        //         .collection(FIRESTORE.RACE_INDIVIDUALS_COL).doc(teamMate.participantId).get().then((participantSnapshot) => {
        //             var participant = participantSnapshot.data() as RaceIndividual
        //             participant.status.billing = SMARTEREVENT.PARTICIPANT_BILLING_STATUS.PAID
        //             if (raceIndividual.id === participantSnapshot.id ) {
        //                 participant.epc = epc
        //             }
        //             firebase.firestore()
        //                 .collection(FIRESTORE.EVENTS_COL).doc(props.smarterEventId)
        //                 .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEditionId)
        //                 .collection(FIRESTORE.RACE_INDIVIDUALS_COL).doc(teamMate.participantId).set(participant)        
        //         })
        //     })
        // })
    }

    return <Fragment>
        <Form id="checkInForm" onSubmit={handleSubmit(checkIn)} errors={errors} yupSchema={validationSchema} register={register}>
            <h3>{props.raceIndividual.firstName} {props.raceIndividual.lastName}</h3>
            <div><Flag /> {race?.name} - {category?.name}</div>
            <div>{props.raceIndividual.status.billing === SMARTEREVENT.PARTICIPANT_BILLING_STATUS.PAID ?
                <div style={{ color: "green" }}>{category?.price}CHF - Payé</div>
                : <div style={{ color: "red" }}>{category?.price}CHF - Non payé</div>
            }</div>
            {props.raceIndividual.options?.map((option) => {
                return <div><ClipboardPlus className="ml-2" /> {option.name}</div>
            })}
            <Form.InputText id="bibNumber" label="Dossard" defaultValue={props.raceIndividual.bibNumber} />
            {race?.chrono?.enabled ?
                <Fragment><Form.InputText id="epc" label="Puce" defaultValue={props.raceIndividual.epc} /><button onClick={(event) => scan(event)}>Scan</button></Fragment  >
                : null}
            {error ? <div style={{ color: "red" }}>Erreur du lecteur</div> : null}
            {props.raceIndividual.status.billing !== SMARTEREVENT.PARTICIPANT_BILLING_STATUS.PAID ?
                <Form.InputCheckbox id="paidCheckbox" label="Paiement reçu" /> : null}
        </Form>
    </Fragment>
}

export default CheckInForm