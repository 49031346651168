import { Fragment } from 'react';
import PacmanLoader from "react-spinners/PacmanLoader";

export type LoaderProps = {
}

const Loader: React.FunctionComponent<LoaderProps> = (props: LoaderProps) => {

    return <Fragment><PacmanLoader color="#01a7c2"/></Fragment>
}

export default Loader