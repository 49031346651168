import classNames from 'classnames';

type BurgerProps = {
    menuOpen: boolean
    toggleMenu: () => void
}

const Burger: React.FunctionComponent<BurgerProps> = (props: BurgerProps) => {
    return <div id="topbar-menu-burger" onClick={props.toggleMenu} className={classNames({ "toggle": props.menuOpen })}>
        <span className="line1"></span>
        <span className="line2"></span>
        <span className="line3"></span>
    </div>
}

export default Burger;