import classNames from 'classnames'

type ListProps = {
    children: any,
    className?: string
}

interface ListSubComponents {
    Item: React.FunctionComponent<ListProps>
}

const List: React.FunctionComponent<ListProps> & ListSubComponents = (props: ListProps) => {
    return <div className={classNames("list", props.className)}>
        {props.children}
    </div>
}

const Item = (props: ListProps) => {
    return <div className={classNames("list-item", props.className)}>
        {props.children}
    </div>
}

List.Item = Item

export default List