import 'firebase/firestore';
import React, { Fragment } from 'react';
import TopBarMenu from '../../layouts/user/TopBarMenu';
import CLMParticipantsManagement from './components/CLMParticipantsManagement';

const CLMManagementPage: React.FunctionComponent = () => {

    return <Fragment>
        <TopBarMenu />
        <main className="main">

            <CLMParticipantsManagement />
        </main>
    </Fragment>

}

export default CLMManagementPage