import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Redirect } from 'react-router-dom';
import { SmarterEventContext } from '../constants/context';
import * as FIRESTORE from '../constants/firestore';
import { SmarterEventEdition } from '../types/smarterEvent';

const RedirectToLatestEdition: React.FunctionComponent = () => {

    const smarterEvent = useContext(SmarterEventContext);

    const [smarterEventEditions, loading] = useCollectionDataOnce<SmarterEventEdition>(
        firebase.firestore()
            .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).orderBy("createdAt", "desc"), { idField: "id" });

    if (loading || smarterEventEditions === null) return null
    if (smarterEventEditions === undefined || smarterEventEditions.length === 0) return <Fragment>No public edition exist</Fragment>
    return <Redirect to={`/${smarterEventEditions[0].id}`} />
}

export default RedirectToLatestEdition