import moment from "moment";
import React, { Fragment } from "react";
import { Calendar, Calendar2, Cash, Person } from 'react-bootstrap-icons';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import DateRange from '../../../components/DateRange';
import { SoloCategory } from "../../../types/race";
import { getDateFromTimestampOrDate } from "../../../utils";

type Props = {
    category: SoloCategory,
    handleRegistrations: {function: (category: SoloCategory) => void, buttonText:string}[]
}

const SoloCategoryCard: React.FunctionComponent<Props> = (props: Props) => {

    const { handleRegistrations } = props

    return <Fragment>
        <Card className="category">
            <Card.Header>
                <h1>{props.category.name}</h1>
            </Card.Header>
            <Card.Data>
                {props.category.date ?
                <p><Calendar2/>{moment(getDateFromTimestampOrDate(props.category.date)).format('LL')}</p>
                : null }
                <p><Person/>{props.category.gender === "male" ? "Hommes" : props.category.gender === "female" ? "Femmes" : props.category.gender === "any" ? "Mixte" : "???"}</p>
                <p><Calendar /><DateRange from={getDateFromTimestampOrDate(props.category.birthDateFrom)} to={getDateFromTimestampOrDate(props.category.birthDateTo)}/></p>
                <p><Cash />Frais d'inscription: {props.category.price} CHF</p>
            </Card.Data>
            <Card.Footer>
                {handleRegistrations.map((handleRegistration, idx) => {
                    return <Button key={idx} onClick={() => handleRegistration.function(props.category)}>{handleRegistration.buttonText}</Button>
                })}
            </Card.Footer>
        </Card>
    </Fragment>
}

export default SoloCategoryCard