import 'firebase/firestore';
import React, { Fragment, useContext } from 'react';
import { RaceContext } from '../../constants/context';
import ParticipantsList from './components/ParticipantsList';


const ParticipantsPage: React.FunctionComponent = () => {

    const race = useContext(RaceContext)!;

    if (race === undefined) return null

    return <Fragment>
        <h2>Participants</h2>
        <ParticipantsList race={race}/>
    </Fragment>

}

export default ParticipantsPage