import 'firebase/firestore';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import Homepage from '../pages/Homepage';
import ForgotPasswordPage from '../pages/user/ForgotPassword';
import MyParticipantsPage from '../pages/user/MyParticipantsPage';
import SignInPage from '../pages/user/SignIn';
import SignUpPage from '../pages/user/SignUp';
import { getSubdomain } from '../utils';
import AdminRouter from './admin/AdminRouter';
import ConsoleRouter from './console/ConsoleRouter';
import ServicesRouter from './ServicesRouter';
import SmarterEventRouter from './SmarterEventRouter';

const MainRouter: React.FunctionComponent = () => {

  // const [openSideMenu, setOpenSideMenu] = useState<boolean>(false)

    if (getSubdomain() === "www" || getSubdomain() === undefined) {
      return <Route path="*" component={Homepage} />
    }
    return <Switch>
      {/* Services without any UI (like an upload endpoint) */}
      <Route path={ROUTES.SERVICE} component={ServicesRouter} />

      {/* Full pages without header, footer... SignIn page, Registration page... */}
      <Route path={ROUTES.SIGNIN} component={SignInPage} />
      <Route path={ROUTES.SIGNUP} component={SignUpPage} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
      <Route path={ROUTES.MYPARTICIPANTS} component={MyParticipantsPage} />

      {(getSubdomain() === ROUTES.CONSOLE) ?
        <Switch>
          <Route path="*" component={ConsoleRouter} />
        </Switch>
        : null}
      {(getSubdomain() === ROUTES.ADMIN) ?
        <Switch>
          <Route path="*" component={AdminRouter} />
        </Switch>
        : null}

      <Route path="*" component={SmarterEventRouter} />
    </Switch>

}



export default MainRouter