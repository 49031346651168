import 'firebase/firestore';
import React, { Fragment } from 'react';
import Footer from '../layouts/Footer';
import HomepageHeader from '../layouts/HomepageHeader';

const Homepage: React.FunctionComponent = () => {

    return <Fragment>
        <HomepageHeader />
        <main>
            <section id="description">
                <h1>Description</h1>
                <div className="description-body">
                    <div className="description-list">
                        <div>
                            <h2>Simplicité</h2>
                            <p>SmarterEvents cherche constamment à garder la solution simple à utilisér à la fois pour
                                les organisateurs et pour les participants</p>
                        </div>
                        <div>
                            <h2>Modularité</h2>
                            <p>Choississez les composants dont vous avez besoin, au fur et à mesure du temps, différents
                                modules viendront faciliter la gestion de vos événements</p>
                        </div>
                        <div>
                            <h2>Autonomie</h2>
                            <p>Nous souhaitons que vous puissiez être le plus autonome possible, nous restons à votre
                                écoute si besoin</p>
                        </div>
                        <div>
                            <h2>Robustesse</h2>
                            <p>La solution repose sur des briques logicielles qui ont fait leurs preuves, les fondations
                                sont solides</p>
                        </div>
                        <div>
                            <h2>Tranquilité</h2>
                            <p>Vous devez pouvoir vous concentrer sur ce qui rend votre événement exceptionnel, déléguez
                            les tâches
                                communes à SmarterEvents sans vous en soucier</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features">
                <h1>Fonctionalités</h1>
                <div className="features-body">
                    <div className="features-group">
                        <h2>Courses</h2>
                        <div className="features-list">
                            <div className="feature-registrations">
                                <h3>Inscriptions</h3>
                                <p>Inscriptions des participants</p>
                            </div>
                            <div className="feature-categories">
                                <h3>Catégories</h3>
                                <p>Gestion des catégories de sport</p>
                            </div>
                            <div className="feature-check-in">
                                <h3>Check in</h3>
                                <p>Accueil des participants le jour de l'événement</p>
                            </div>
                        </div>
                    </div>
                    <div className="features-group">
                        <h2>Caisse enregistreuse</h2>
                        <div className="features-list">
                            <div className="feature-items-list">
                                <h3 className="coming-up">Gestion des articles</h3>
                                <p>Back-office pour l'ajout d'articles</p>
                            </div>
                            <div className="feature-android-app">
                                <h3 className="coming-up">Application</h3>
                                <p>Application smartphone pour la prise des commandes</p>
                            </div>
                        </div>
                    </div>
                    <div className="features-group">
                        <h2>Sponsors</h2>
                        <div className="features-list">
                            <div className="feature-sponsors">
                                <h3 className="coming-up">Gestion des sponsors</h3>
                                <p>Ajout des sponsors</p>
                            </div>
                            <div className="feature-logos">
                                <h3 className="coming-up">Gestion des logos</h3>
                                <p>Ajout et diffusion des logos des sponors</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing">
                <h1>Tarifs</h1>
                <div className="body">
                    <div>
                        <h2>Faible coûts et proportionnel au chiffre d'affaire pour un montant juste</h2>
                        <p>Notre meilleure rémunération à ce jour, ce sont vos retours d’expérience !
                        Comment pouvons nous améliorer la simplicité d’utilisation ? Quelle fonctionalité vous serait la
                        plus utile ?
                        Merci de nous informer, c'est avec plaisir que l'on fera évoluer cette platforme, ensemble.</p>
                    </div>
                </div>
            </section>
        </main>
        <Footer />
    </Fragment>
}

export default Homepage