import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import BillingStatus from '../../../components/BillingStatus';
import Card from '../../../components/Card';
import { UserProfileContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import * as ROUTES from '../../../constants/routes';
import { RaceIndividual } from '../../../types/race';
import { SmarterEventEdition } from '../../../types/smarterEvent';
import { getDateFromTimestampOrDate } from '../../../utils';
import ParticipantCard from '../../race/components/ParticipantCard';

type Props = {
}

const MyParticipantsList: React.FunctionComponent<Props> = (props: Props) => {

    // const [participants, loading] = useCollectionGroupData<RaceIndividual>(firebase.firestore()
    //     .collection(FIRESTORE.EVENTS_COL).doc(props.race.references.eventId)
    //     .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.race.references.editionId)
    //     .collection(FIRESTORE.RACE_INDIVIDUALS_COL), { idField: "id" })

    const [participants, setParticipants] = useState<{ raceIndividual: RaceIndividual, event: { eventName: string, editionName: string } }[]>([])
    const userProfile = useContext(UserProfileContext);
    const location = useLocation();

    useEffect(() => {
        if (userProfile != null) {
            firebase.firestore()
                .collectionGroup(FIRESTORE.RACE_INDIVIDUALS_COL).where("createdBy", "==", userProfile.id).orderBy("createdAt", "desc").get().then(async (data) => {
                    var allParticipants = Promise.all(data.docs.map((data) => {
                        console.log(data.ref)
                        console.log(data.ref.parent)
                        return data.ref.parent.parent!.get().then((editionData) => {
                            var edition = editionData.data() as SmarterEventEdition
                            return { raceIndividual: data.data() as RaceIndividual, event: { eventName: edition.references.eventName, editionName: edition.name } }
                        });
                    }))
                    setParticipants(await allParticipants)
                })
        }
    }, [userProfile])

    const columns: Column<any>[] = React.useMemo(
        () => [
            //   {
            //   // Build our expander column
            //   id: 'expander', // Make sure it has an ID
            //   Header: '👇',
            //   Cell: ({ row }) =>
            //     // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            //     // to build the toggle for expanding a row
            //     row.canExpand ? (
            //       <span
            //         {...row.getToggleRowExpandedProps({
            //           style: {
            //             // We can even use the row.depth property
            //             // and paddingLeft to indicate the depth
            //             // of the row
            //             paddingLeft: `${row.depth * 2}rem`,
            //           },
            //         })}
            //       >
            //         {row.isExpanded ? '👇' : '👉'}
            //       </span>
            //     ) : null,
            // },
            {
                Header: 'Date d\'inscription',
                accessor: (row) => { return moment(getDateFromTimestampOrDate(row.raceIndividual.createdAt)).format("DD/MM/YYYY HH:MM") },
            },
            {
                Header: 'Événement',
                accessor: (row) => { return row.event.eventName + " - " + row.event.editionName },
            },
            {
                Header: 'Nom',
                accessor: (row) => { return row.raceIndividual.firstName + " " + row.raceIndividual.lastName },
            },
            {
                Header: 'Course',
                accessor: (row) => { return row.raceIndividual.race.name },
            },
            {
                Header: 'Catégorie',
                accessor: 'raceIndividual.category.name',
            },
            {
                Header: 'Année',
                accessor: (row) => { return row.raceIndividual.birthdayYear }
            },
            {
                Header: 'Statut',
                accessor: (row) => { return <BillingStatus value={row.raceIndividual.status.billing} /> }
            },
        ],
        []
    )


    const { headerGroups, rows, prepareRow } = useTable({
        columns: columns,
        data: participants,
    })


    if (userProfile == null) {
        return <Redirect to={{
            pathname: ROUTES.SIGNIN,
            state: { redirect: location.pathname }
        }} />
    }

    return <Fragment>
        <div className="participants">
            {/* {loading ? <Loader/> : */}
            {participants != null && participants.length !== 0 ?
            <Fragment>
                <Card.Deck>
                    {participants?.map((participant: { raceIndividual: RaceIndividual, event: { eventName: string, editionName: string } }) => {
                        return <ParticipantCard key={participant.raceIndividual.id} participant={participant.raceIndividual} />
                    })}
                </Card.Deck>

                <table>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} className="py-0" style={{ verticalAlign: "middle" }}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>

                </table>
            </Fragment>
            :
            <div>Vous n'avez encore aucune inscription</div>}
        </div>
    </Fragment>
}

export default MyParticipantsList