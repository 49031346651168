import "firebase/firestore";
import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import TopBarMenu from "../layouts/user/TopBarMenu";
import CheckInPage from '../pages/checkIn/CheckInPage';

const CheckInRouter: React.FunctionComponent = () => {

    return <Fragment>
        <TopBarMenu />
        <main className="main">
            <Switch>
                <Route path={`/`} component={CheckInPage} />
            </Switch>
        </main>
    </Fragment >
}
export default CheckInRouter