import firebase from 'firebase/app';
import 'firebase/firestore';
import { useContext, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { RaceContext, SmarterEventEditionContext } from "../../../constants/context";
import * as FIRESTORE from '../../../constants/firestore';
import RaceBreadcrumb from "../../../layouts/RaceBreadcrumb";
import { SoloCategory } from "../../../types/race";
import CategoriesList from "./components/CategoriesList";
import SoloCategoryForm from "./SoloCategoryForm";

type Props = {
}

const RaceConsolePage: React.FunctionComponent<Props> = () => {

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<{show: boolean, category: SoloCategory|null}>({show: false, category:null});
    const [showEditModal, setShowEditModal] = useState<{show: boolean, category: SoloCategory|null}>({show: false, category:null});
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const race = useContext(RaceContext)!;

    const [soloCategories] = useCollectionData<SoloCategory>(firebase.firestore()
    .collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.references.eventId)
    .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
    .collection(FIRESTORE.RACE_CATEGORIES_COL).where("raceId", "==", race.id).orderBy("birthDateFrom"), { idField: "id" })


    const onSubmit = (values: any) => {
        console.log(values)

        var category: SoloCategory = {
            name: values.name,
            teamOrSolo: "solo",
            raceId: race.id,
            id: values.id,
            birthDateFrom: values.dateFrom,
            birthDateTo: values.dateTo,
            gender: values.gender,
            price: values.price,
            type: values.type
        }

        firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.references.eventId)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.RACE_CATEGORIES_COL).doc(values.id).set(category).then(()=> setShowAddModal(false))


        setShowEditModal({show: false, category:null})
        // var race: Race = {
        //     id: values.id,
        //     name: values.name,
        //     smarterEvent: {
        //         eventId: smarterEventEdition.smarterEvent.id,
        //         editionId: smarterEventEdition.id
        //     },
        //     sport: values.sport,
        //     registrations: {
        //         startTime: values.registrations.startTime,
        //         endTime: values.registrations.endTime
        //     }
        // }
        // firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.smarterEvent.id)
        // .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        // .collection(FIRESTORE.RACES_COL).doc(race.id).set(race)
    }

    const deleteCategory = (category: SoloCategory) => {
        var ref = firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.references.eventId)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.RACE_CATEGORIES_COL).doc(category.id);
            console.log(ref.path)
            
            ref.delete().then(() => {
                setShowDeleteModal({show: false, category:null})
                console.log("Document successfully deleted!");
            })
            .catch((error) => {
                console.error("Error removing document: ", error);
            });
    }

    return <main>
        <h1>{smarterEventEdition.references.eventName} {smarterEventEdition.name} - {race?.name}</h1>
        <RaceBreadcrumb isAdmin={true}/>
        <CategoriesList addCategory={() => setShowAddModal(true)} editCategory={(category) => setShowEditModal({show: true, category:category})} deleteCategory={(category) => setShowDeleteModal({show: true, category:category})} categories={soloCategories?soloCategories:[]} />
        <Modal show={showAddModal}>
            <Modal.Header>
                <h1>Nouvelle catégorie</h1>
            </Modal.Header>
            <Modal.Body>
                <SoloCategoryForm onSubmit={onSubmit} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowAddModal(false)}>
                    Annuler
                </Button>
                <Button type="submit" form="raceForm">Créer la catégorie</Button>
            </Modal.Footer>
        </Modal>
        {showEditModal.category ? <Modal show={showEditModal.show}>
            <Modal.Header>
                <h1>Modifier la catégorie</h1>
            </Modal.Header>
            <Modal.Body>
                <SoloCategoryForm onSubmit={onSubmit} category={showEditModal.category!}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={() => setShowEditModal({show: false, category:null})}>
                    Annuler
                </Button>
                <Button type="submit" form="raceForm">Modifier</Button>
            </Modal.Footer>
        </Modal>
        : null }
        {showDeleteModal.category ? <Modal show={showDeleteModal.show}>
            <Modal.Header>
                <h1>Effacer la catégorie</h1>
            </Modal.Header>
            <Modal.Body>
                Etes-vous sûr de vouloir effacer la catégorie: <strong>{showDeleteModal.category?.name}</strong>     ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={() => setShowDeleteModal({show: false, category:null})}>
                    Annuler
                </Button>
                <Button onClick={() => deleteCategory(showDeleteModal.category!)}>Effacer</Button>
            </Modal.Footer>
        </Modal>
        : null }
    </main>
}

export default RaceConsolePage