import classnames from 'classnames';
import React, { useContext } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import ObjectSchema from 'yup/lib/object';
import { SchemaObjectDescription } from 'yup/lib/schema';
import '../assets/stylesheets/components/form.scss';

export type FormProps = {
    id: string;
    children: any;
    register: any;
    errors: any;
    yupSchema: ObjectSchema<any, any, any>;
    onSubmit: ((event: React.FormEvent<HTMLFormElement>) => void);
}

type InputTextProps = {
    id: string;
    label: string;
    defaultValue?: string|number;
    placeholder?: string;
    autoComplete?: string;
    hint?: string;
    readOnly?: boolean;
}

type InputSelectProps = {
    id: string;
    label: string;
    defaultValue?: string;
    values: { id: string, name: string }[];
    readOnly?: boolean;
}

type InputCheckboxProps = {
    id: string;
    label: string;
    defaultChecked?: boolean;
}

interface FormSubComponents {
    InputText: React.FunctionComponent<InputTextProps>
    InputPassword: React.FunctionComponent<InputTextProps>
    InputDate: React.FunctionComponent<InputTextProps>
    InputDateTime: React.FunctionComponent<InputTextProps>
    InputSelect: React.FunctionComponent<InputSelectProps>
    InputCheckbox: React.FunctionComponent<InputCheckboxProps>
}

type FormContextData = {
    register: any,
    errors: DeepMap<any, FieldError>;
    yupSchema: ObjectSchema<any, any, any>
}

const getField: any = function (schema: SchemaObjectDescription, fieldName: string) {
    var split = fieldName.split('.');
    if (split.length > 1) {
        var tmpSchema: SchemaObjectDescription = schema.fields[split[0]] as SchemaObjectDescription;
        return getField(tmpSchema, split[1]) as SchemaObjectDescription;
    } else {
        var schemaObjectDescription = schema as SchemaObjectDescription;
        return schemaObjectDescription.fields[split[0]] as SchemaObjectDescription;
    }
}

const getError: any = function (errors: DeepMap<any, FieldError>, fieldName: string) {
    var split = fieldName.split('.');
    if (errors === undefined) {
        return null
    }
    if (split.length > 1) {
        var tmpSchema: FieldError = errors[split[0]] as FieldError;
        return getError(tmpSchema, split[1]) as FieldError;
    } else {
        return errors[split[0]] as FieldError;
    }
}


export const FormContext: React.Context<FormContextData | null> = React.createContext<FormContextData | null>(null);

const isRequired = (schemaDescription: ObjectSchema<any, any, any>, fieldName: string) => {
    // var schemaFieldDescription: SchemaObjectDescription = schemaDescription.describe().fields[fieldName] as SchemaObjectDescription;
    var schemaFieldDescription: SchemaObjectDescription = getField(schemaDescription.describe(), fieldName) as SchemaObjectDescription
    return schemaFieldDescription.tests.findIndex((test) => test.name === 'required') >= 0;
}

const Form: React.FunctionComponent<FormProps> & FormSubComponents = (props: FormProps) => {

    var formContextData: FormContextData = {
        register: props.register,
        errors: props.errors,
        yupSchema: props.yupSchema
    }

    return <form id={props.id} onSubmit={props.onSubmit} noValidate={true}>
        <FormContext.Provider value={formContextData}>
            {props.children}
        </FormContext.Provider>
    </form>
}

const getInputField = (type: string, props: InputTextProps, formContextData: FormContextData) => {

    var fieldError: FieldError = getError(formContextData.errors, props.id)
    var fieldRequired: boolean = isRequired(formContextData.yupSchema, props.id)

    return <div className="input-group">
        <label htmlFor={props.id} className={classnames({ "required": fieldRequired })}>{props.label}</label>
        <input type={type} name={props.id} id={props.id}
            placeholder={props.placeholder}
            className={fieldError && "is-valid"}
            defaultValue={props.defaultValue}
            ref={formContextData.register}
            readOnly={props.readOnly} />
        <span className="error">{fieldError?.message}</span>
        {props.hint ? <div className="hint">{props.hint}</div> : null}
        {/* <Form.Control.Feedback type="invalid">{errors.eventName?.message}</Form.Control.Feedback> */}
    </div>
}

const InputText = (props: InputTextProps) => {
    const formContextData = useContext(FormContext)!;
    return getInputField("text", props, formContextData)
}

const InputPassword = (props: InputTextProps) => {
    const formContextData = useContext(FormContext)!;
    return getInputField("password", props, formContextData)
}

const getCalendarInputField = (type: string, props: InputTextProps, formContextData: FormContextData) => {

    var fieldError: FieldError = getError(formContextData.errors, props.id)
    var fieldRequired: boolean = isRequired(formContextData.yupSchema, props.id)

    return <div className="input-group">
        <label htmlFor={props.id} className={classnames({ "required": fieldRequired })}>{props.label}</label>
        <input type={type} name={props.id} id={props.id}
            readOnly={props.readOnly}
            // isInvalid={fieldError && true}
            defaultValue={props.defaultValue}
            ref={formContextData.register} />
        <span className="error">{fieldError?.message}</span>

        {/* <Form.Control.Feedback type="invalid">{errors.eventName?.message}</Form.Control.Feedback> */}
    </div>
}

const InputDate = (props: InputTextProps) => {
    const formContextData = useContext(FormContext)!;
    return getCalendarInputField("date", props, formContextData);
}

const InputDateTime = (props: InputTextProps) => {
    const formContextData = useContext(FormContext)!;
    return getCalendarInputField("datetime-local", props, formContextData);
}

const InputSelect = (props: InputSelectProps) => {
    const formContextData = useContext(FormContext)!;

    var fieldError: FieldError = getError(formContextData.errors, props.id)
    var fieldRequired: boolean = isRequired(formContextData.yupSchema, props.id)
    return <div className="input-group">
        <label htmlFor={props.id} className={classnames({ "required": fieldRequired })}>{props.label}</label>
        <select name={props.id} id={props.id} className={classnames({ "disabled": props.readOnly })}
            // isInvalid={fieldError && true}
            defaultValue={props.defaultValue}
            // disabled={props.readOnly}
            ref={formContextData.register}>
            {props.values.map((value) =>
                <option key={value.id} value={value.id} disabled={props.readOnly}>{value.name}</option>
            )}
        </select>
        <span className="error">{fieldError?.message}</span>

        {/* <Form.Control.Feedback type="invalid">{errors.eventName?.message}</Form.Control.Feedback> */}
    </div>
}


const InputCheckbox = (props: InputCheckboxProps) => {
    const formContextData = useContext(FormContext)!;

    var fieldError: FieldError = getError(formContextData.errors, props.id)
    return <div className="input-group">&nbsp;
    <input type="checkbox" name={props.id} id={props.id}
            checked={props.defaultChecked}
            ref={formContextData.register}>
        </input>&nbsp;
    <label htmlFor={props.id} style={{ color: "black" }}>{props.label}</label>
        <span className="error">{fieldError?.message}</span>

        {/* <Form.Control.Feedback type="invalid">{errors.eventName?.message}</Form.Control.Feedback> */}
    </div>
}

Form.InputText = InputText
Form.InputPassword = InputPassword
Form.InputDate = InputDate
Form.InputDateTime = InputDateTime
Form.InputSelect = InputSelect
Form.InputCheckbox = InputCheckbox

export default Form