import cookie from 'cookie-parse';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import './assets/stylesheets/main.scss';
import firebaseConfig from './configs/firebaseConfig';
import { UserProfileContext } from './constants/context';
import * as FIRESTORE from './constants/firestore';
import MainRouter from './routes/MainRouter';
import { UserProfile } from './types/smarterEvent';

const SmarterEventsApp: React.FunctionComponent = () => {

    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig)
        if (window.location.hostname.indexOf("localhost.localdomain") >= 0 && window.location.hostname.indexOf("lahautfestival") === -1) {
            var db = firebase.firestore();
            db.useEmulator("localhost", 8080);
            var auth = firebase.auth();
            auth.useEmulator("http://localhost:9099");
            var storage = firebase.storage();
            storage.useEmulator("localhost", 9199);
        }
    }
 
    const [user, loading] = useAuthState(firebase.auth());

    const [userProfile, setUserProfile] = useState<UserProfile | null>(null)
    const [loadingCookie, setLoadingCookie] = useState<boolean>(false)

    useEffect(() => {
        if (user === null) {
            var cookies = cookie.parse(document.cookie);
            if (cookies.customToken != null) {
                setLoadingCookie(true)
                firebase.auth().signInWithCustomToken(cookies.customToken).then((user) => {
                    setLoadingCookie(false)
                })
            }
        }
    }, [user])

    useEffect(() => {
        if (user !== null) {
            var unsubscribe = firebase.firestore().collection(FIRESTORE.USERS_COL).doc(user.uid).onSnapshot((docSN) => {
                let userProfile = docSN.data() as unknown as UserProfile
                if (userProfile != null) {
                    userProfile!.id = docSN.id
                    setUserProfile(userProfile)
                 }
            })
            return unsubscribe
        } else if (!loading) {
            setUserProfile(null)
        }
    }, [user, loading])

    if (loading || loadingCookie || (user != null && userProfile == null)) return null

    return <UserProfileContext.Provider value={userProfile}>
        <MainRouter />
    </UserProfileContext.Provider>
}

export default SmarterEventsApp