import { yupResolver } from '@hookform/resolvers/yup';
import firebase from "firebase/app";
import "firebase/auth";
import React, { Fragment, FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import logo from '../../assets/images/logo/logo.svg';
import Button from '../../components/Button';
import Form from '../../components/Form';
import * as ROUTES from '../../constants/routes';

const ForgotPasswordPage: FunctionComponent = () => {

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('Adresse email requise')
            .email('Adresse Email invalide'),
    });

    const [message, setMessage] = useState<any>()
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (values: any) => {
        var auth = firebase.auth();
        auth.sendPasswordResetEmail(values.email.trim()).then(function () {
            setMessage("Un email vous a été envoyé")
        }).catch(function (error) {
            setMessage("Compte inexistant")
        });
    }

    return (
        <Fragment>
            <main>
                <section className="splash-screen">
                    <img className="logo" src={logo} alt="SmarterEvents logo" />
                    <div>
                        <h2>Mot de passe oublié ?</h2>
                        <h3>Utilisez le formulaire ci-dessous</h3>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)} errors={errors} id="forgotPasswordForm" register={register} yupSchema={validationSchema}>
                        <Form.InputText id="email" label="Email" placeholder="Email ici..." />
                        {message ?
                            <h4>
                                <span className="text-info">{message}</span>
                            </h4> : null}
                        <Button>Recréer un mot de passe</Button>
                        <Link to={ROUTES.SIGNIN} className="text-primary">Se connecter</Link>
                    </Form>
                </section>
            </main>
        </Fragment>
    );
}

export default ForgotPasswordPage;
