import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FileEarmarkExcel } from 'react-bootstrap-icons';
import { Redirect } from 'react-router-dom';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Cms from '../../components/Cms';
import Loader from '../../components/Loader';
import { SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from '../../constants/context';
import * as FIRESTORE from '../../constants/firestore';
import * as ROUTES from '../../constants/routes';
import TopBarMenu from '../../layouts/user/TopBarMenu';
import { hasAdminAccess } from '../../services/authorization';
import { Race, RaceIndividual } from '../../types/race';
import { exportToSheet } from './components/ExportParticipantsButton';
import RaceCard from './components/RaceCard';

const RacesPage: React.FunctionComponent = () => {

    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const userProfile = useContext(UserProfileContext);

    const [races, setRaces] = useState<Race[]>();

    // const [soloCategories] = useCollectionData<SoloCategory>(firebase.firestore()
    //     .collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.smarterEvent.id)
    //     .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
    //     .collection(FIRESTORE.RACE_CATEGORIES_COL).where("type", "==", "solo").orderBy("birthDateFrom"), { idField: "id" })

    // const [teamCategories] = useCollectionData<TeamCategory>(firebase.firestore()
    //     .collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.smarterEvent.id)
    //     .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
    //     .collection(FIRESTORE.RACE_CATEGORIES_COL).where("type", "==", "team"), { idField: "id" })

    useEffect(() => {
        var races: Race[] = []
        firebase.firestore()
            .collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.references.eventId)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.RACES_COL).get().then((querySN) => {
                querySN.forEach((documentData) => {
                    var race = documentData.data() as unknown as Race
                    race.id = documentData.id
                    races.push(race)
                })
                setRaces(races)
            })
    }, [smarterEventEdition])

    if (races?.length === 1) {
        return <Redirect to={`/${smarterEventEdition!.id}${ROUTES.EVENTS_RACE}/${races[0].id}`} />
    }

    // const newRegistration = (category: SoloCategory) => {
    //     // setNewRegistrationModal({ show: true, category: category, addContact: false })
    // }

    // const newTeamRegistration = (category: TeamCategory) => {
    //     // setNewTeamRegistrationModal({ show: true, category: category })
    // }

    // const newContactRegistration = (category: SoloCategory) => {
    //     // setNewRegistrationModal({ show: true, category: category, addContact: true })
    // }

    const exportRaceIndividualsToSheet = () => {
        firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_INDIVIDUALS_COL).get().then((querySN) => {
            var participants: RaceIndividual[] = querySN.docs.map((querySNDoc) => {
                var raceIndividualData = querySNDoc.data() as RaceIndividual
                return raceIndividualData
            })
            exportToSheet(participants, "Participants" + smarterEvent.name + " " + smarterEventEdition.name)

        })
    }
    
    return (
        <Fragment>
            <TopBarMenu />
            <main className="main">
                {/* <RaceBreadcrumb /> */}
                <Cms
                    allowedToEdit={userProfile != null && hasAdminAccess(smarterEvent, userProfile.id!)!}
                    defaultText={`${smarterEvent.name} ${smarterEventEdition.name}`}
                    cmsReference={firebase.firestore()
                        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
                        .collection("cms").doc("frontpage")} />
                        {races ===undefined ? <Loader/> :
                <Card.Deck className="four-columns">
                    {races?.map((race, index) => {
                        return <RaceCard key={race.id} race={race} />
                    })}
                </Card.Deck>
                }
                {/* <SoloCategoriesList newRegistration={newRegistration} newContactRegistration={newContactRegistration} categories={soloCategories ? soloCategories : []} />
                <TeamCategoriesList newTeamRegistration={newTeamRegistration} races={races} categories={teamCategories ? teamCategories : []} /> */}

                {userProfile != null && hasAdminAccess(smarterEvent, userProfile.id!) ?
                <Button className="exportButton" onClick={exportRaceIndividualsToSheet}><FileEarmarkExcel/>Exporter tous les participants</Button >
                :null}  
            </main>
        </Fragment >
    )
}

export default RacesPage