import 'firebase/firestore';
import React, { Fragment } from 'react';
import * as ROUTES from '../../constants/routes';

const Admin: React.FunctionComponent = () => {

    return <Fragment>
        <main>
            <h1>Admin</h1>

            <p><a href={ROUTES.BILLS}>Gestion des factures</a></p>
        </main>
    </Fragment>
}

export default Admin