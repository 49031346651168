import { yupResolver } from '@hookform/resolvers/yup';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import Form from '../../../components/Form';
import { Category } from "../../../types/race";
import { getDateFromTimestampOrDate, getIdFromName } from "../../../utils";

type Props = {
    onSubmit: (values: any) => void
    category?: Category
}

const SoloCategoryForm: React.FunctionComponent<Props> = (props: Props) => {

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Nom requis')
            .min(2, 'Au moins 2 caractères'),
        id: Yup.string()
            .required('Identifiant requis')
            .min(2, 'Au moins 2 caractères'),
        price: Yup.number()
            .typeError("Mettre le prix sans la monnaie. Par exemple: 10.50")
            .required('Prix requis'),
        gender: Yup.string()
            .required('Genre requis'),
        dateFrom: Yup.date()
            .typeError("Date de naissance requise")
            .required("Date de naissance requise"),
        dateTo: Yup.date()
            .typeError("Date de naissance requise")
            .required("Date de naissance requise")
    });

    function dateInputText(_value: any, originalValue: Date | firebase.firestore.Timestamp) {
        return moment(getDateFromTimestampOrDate(originalValue)).format('YYYY-MM-DD').toString()
    }

    const serializeSchema = validationSchema.shape({
        dateFrom: Yup.string().transform(dateInputText),
        dateTo: Yup.string().transform(dateInputText),
    });

    const { register, handleSubmit, errors, watch } = useForm({
        defaultValues: serializeSchema.cast(props.category),
        resolver: yupResolver(validationSchema)
    });

    var name = watch("name")

    return <Form onSubmit={handleSubmit(props.onSubmit)} id="raceForm" errors={errors} register={register} yupSchema={validationSchema}>

            <Form.InputText id="name" label="Nom de la catégorie"/>
            <Form.InputText id="id" label="Identifiant" placeholder="senior-dame" defaultValue={getIdFromName(name)}/>
            <Form.InputSelect id="gender" label="Genre" values={[{id:"male", name:"Homme"}, {id:"female", name:"Femme"}, {id:"any", name:"Mixte"}]}/>
            <Form.InputDate id="dateFrom" label="Date de naissance, du:"/>
            <Form.InputDate id="dateTo" label="Date de naissance, au:"/>
            <Form.InputText id="price" label="Montant d'inscription"/>
        </Form>


        /* <Form.Group>
            <Form.Label htmlFor="name"><span className="text-danger">*</span>{' '}Nom de la catégorie</Form.Label>
            <Form.Control type="text" name="name" id="name"
                isInvalid={errors.name && true}
                placeholder="Senior Dame"
                ref={register} />
            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="id"><span className="text-danger">*</span>{' '}Identifiant</Form.Label>
            <Form.Control type="text" name="id" id="id"
                isInvalid={errors.id && true}
                placeholder="senior-dame"
                defaultValue={getIdFromName(name)}
                ref={register} />
            <Form.Control.Feedback type="invalid">{errors.id?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Group>
                <Form.Label htmlFor="gender"><span className="text-danger">*</span>{' '}Genre</Form.Label>
                <Form.Check
                    isInvalid={errors.gender && true}
                    type="radio" name="gender" id="gender-male" value="male"
                    ref={register}
                    label="Homme"
                />
                <Form.Check>
                    <Form.Check.Input
                        type="radio"
                        isInvalid={errors.gender && true}
                        name="gender"
                        id="gender-female"
                        value="female"
                        ref={register} />
                    <Form.Check.Label>Femme</Form.Check.Label>
                    <Form.Control.Feedback type="invalid">{errors.gender?.message}</Form.Control.Feedback>
                </Form.Check>
            </Form.Group>
            <Form.Control.Feedback type="invalid">Genre requis</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="dateFrom"><span className="text-danger">*</span>{' '}Date de naissance, du:</Form.Label>
            <Form.Control
                id="dateFrom"
                isInvalid={errors.dateFrom && true}
                type="date"
                name="dateFrom"
                ref={register}
            />
            <Form.Control.Feedback type="invalid">{errors.dateFrom?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="dateTo"><span className="text-danger">*</span>{' '}Date de naissance, au:</Form.Label>
            <Form.Control
                id="dateTo"
                isInvalid={errors.dateTo && true}
                type="date"
                name="dateTo"
                ref={register}
            />
            <Form.Control.Feedback type="invalid">{errors.dateTo?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="price"><span className="text-danger">*</span>{' '}Montant d'inscription</Form.Label>
            <Form.Control type="text" name="price" id="price"
                isInvalid={errors.price && true}
                placeholder=""
                ref={register} />
            <Form.Control.Feedback type="invalid">{errors.price?.message}</Form.Control.Feedback>
        </Form.Group> */

}

export default SoloCategoryForm