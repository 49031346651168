import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Modal from '../../../components/Modal';
import { RacesContext, SmarterEventEditionContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import { Race, SoloCategory } from '../../../types/race';
import CategoriesList from '../../race/console/components/CategoriesList';
import RaceCard from '../../race/console/components/RaceCard';
import RaceForm from '../../race/console/RaceForm';

const SmarterEventEditionConsolePage: React.FunctionComponent = () => {

    const smarterEventEdition = useContext(SmarterEventEditionContext)!;
    const races = useContext(RacesContext)!;
    const [showModal, setShowModal] = useState<boolean>(false);


    const [soloCategories] = useCollectionData<SoloCategory>(firebase.firestore()
    .collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.references.eventId)
    .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
    .collection(FIRESTORE.RACE_CATEGORIES_COL), { idField: "id" })

    
    const onSubmit = (values: any) => {
        var race: Race = {
            id: values.id,
            name: values.name,
            references: {
                eventId: smarterEventEdition.references.eventId,
                editionId: smarterEventEdition.id
            },
            sport: values.sport,
            fromDate: values.fromDate,
            toDate: values.toDate,
            registrations: {
                startTime: values.registrations.startTime,
                endTime: values.registrations.endTime
            }
        }
        firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(smarterEventEdition.references.eventId)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
            .collection(FIRESTORE.RACES_COL).doc(race.id).set(race)
        setShowModal(false)
    }


    return <main>
        <h1>{smarterEventEdition.references.eventName} {smarterEventEdition.name}</h1>
        <div className="spread-apart">
            <h2>Courses</h2>
            <Button onClick={() => setShowModal(true)}>Nouvelle course</Button>
        </div>
        <Card.Deck>
            {races.length ===0 ? 
            <p>Vous n'avez pas encore créé de course.</p>
            : races?.map((race) => {
                return <RaceCard isAdmin={true} key={race.id} race={race} />
            })}
            {/* <PlusCard text="Nouvelle course" onClick={() => setShowModal(true)} /> */}
        </Card.Deck>

            {soloCategories ? 
        <CategoriesList categories={soloCategories}/>
        : null }

        <Modal show={showModal}>
            <Modal.Header>
                <h1>Nouvelle course</h1>
            </Modal.Header>
            <Modal.Body>
                <RaceForm eventId={smarterEventEdition.references.eventId} smarterEventEdition={smarterEventEdition} onSubmit={onSubmit} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={() => setShowModal(false)}>
                    Annuler
                </Button>
                <Button type="submit" form="raceForm">Créer la course</Button>
            </Modal.Footer>
        </Modal>
    </main>
}

export default SmarterEventEditionConsolePage