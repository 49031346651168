import firebase from "firebase/app";
import "firebase/firestore";
import React, { useState } from 'react';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useGlobalFilter, useTable } from 'react-table';
import Button from "../../components/Button";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import * as FIRESTORE from '../../constants/firestore';
import * as SMARTEREVENT from '../../constants/smarterEvent';
import { Bill } from "../../types/smarterEvent";

const BillsManagement: React.FunctionComponent = () => {

    const [confirmPaidModal, setConfirmPaidModal] = useState<{ isOpen: boolean, bill: Bill | null }>({ isOpen: false, bill: null });

    const [bills] = useCollectionData<Bill>(firebase.firestore()
        .collection(FIRESTORE.SMARTEREVENTS_COL).doc(FIRESTORE.DATA_DOC)
        .collection(FIRESTORE.BILLS_COL)
        , { idField: "id" });

    const filterTypes = React.useMemo(() => ({
        text: (rows: any, id: any, filterValue: string) => {
            return rows.filter((row: any) => {
                const rowValue = row.id
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
        },
    }),
        []
    )


    const getActions = ((bill: Bill) => {
        return <Button onClick={() => setConfirmPaidModal({ isOpen: true, bill: bill })}>Marquer payée</Button>
    })

    const columns = React.useMemo(
        () => [
            {
                Header: 'No',
                accessor: 'billNumber',
            },
            {
                Header: 'Nom',
                accessor: (row: any) => { return row.createdBy.firstName + " " + row.createdBy.lastName }
            },
            {
                Header: 'Montant',
                accessor: (row: any) => { return row.totalAmount + "CHF" },
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Actions',
                accessor: (row: any) => { return getActions(row) },
            }
        ],
        []
    )

    const data: any[] = React.useMemo(
        () => {
            if (bills == null) return []
            return bills
            // if (raceIndividuals == null) return []

            // const convertParticipant = (raceIndividual: RaceIndividual) => {
            //     var data: any = {}
            //     data.firstName = raceIndividual.firstName
            //     data.lastName = raceIndividual.lastName
            //     data.gender = raceIndividual.gender === "male" ? "Homme" : raceIndividual.gender === "female" ? "Femme" : "???"
            //     data.billingStatus = raceIndividual.status.billing
            //     data.buttons = <Button

            //         onClick={() => setCheckInParticipantModal({ isOpen: true, raceIndividual: raceIndividual })}>Check-in</Button>
            //     /* {newRegistration != null ? <Button onClick={() => newRegistration(category)}>S'inscrire</Button> : null}
            //     {editCategory ? <Button onClick={() => editCategory(category)}>Editer</Button> : null}
            //     {deleteCategory ? <Button onClick={() => deleteCategory(category)}>Effacer</Button> : null} */
            //     return data
            // }

            // return raceIndividuals.map((raceIndividual: RaceIndividual) => {
            //     return convertParticipant(raceIndividual)
            // })
        }
        , [bills]
    )

    const { headerGroups, rows, prepareRow, setGlobalFilter } = useTable({
        columns,
        data,
        filterTypes,
    }, useGlobalFilter)

    const markAsPaid = (bill: Bill | null) => {
        if (bill !== null) {
            firebase.firestore().runTransaction((transaction) => {
                return transaction.get(firebase.firestore()
                .collection(FIRESTORE.SMARTEREVENTS_COL).doc(FIRESTORE.DATA_DOC)
                .collection(FIRESTORE.BILLS_COL).doc(bill.id)).then((billDoc) => {
                    var bill2 = billDoc.data() as Bill
                    bill2.status = SMARTEREVENT.BILL_STATUS.PAID
                    return transaction.update(firebase.firestore()
                    .collection("smarterEvents").doc("data")
                    .collection(FIRESTORE.BILLS_COL).doc(bill.id), bill2)
                })
            })
        }
        setConfirmPaidModal({ isOpen: false, bill: null })
    }

    return <main>
        <h1>Gestion des factures</h1>
        <Card>
            <Card.Body>
                <label htmlFor="searchInput" />Chercher: <input id="searchInput" type="text" onChange={(value) => setGlobalFilter(value.target.value)} />
                <table>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} style={{ verticalAlign: "middle" }}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
        {/* <CheckInList lines={lines} /> */}
        <Modal show={confirmPaidModal.isOpen}>
            <Modal.Header><h1>Marquer facture comme payée</h1></Modal.Header>
            <Modal.Body>
                <div>#{confirmPaidModal!.bill?.billNumber}: {confirmPaidModal!.bill?.createdBy.firstName} {confirmPaidModal!.bill?.createdBy.lastName}</div>
                <div>{confirmPaidModal!.bill?.totalAmount}CHF</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={() => setConfirmPaidModal({ isOpen: false, bill: null })}>Fermer</Button>
                <Button onClick={() => markAsPaid(confirmPaidModal!.bill)}>Marquer comme payé</Button>
            </Modal.Footer>
        </Modal>
    </main>

}

export default BillsManagement
