import { yupResolver } from '@hookform/resolvers/yup';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import Form from '../../../components/Form';
import * as FIRESTORE from '../../../constants/firestore';
import * as ROUTES from '../../../constants/routes';
import { SmarterEventEdition } from '../../../types/smarterEvent';
import { getDateFromTimestampOrDate, getIdFromName } from "../../../utils";


type Props = {
    eventId: string,
    smarterEventEdition: SmarterEventEdition,
    onSubmit: (values: any) => void
}

const RaceForm: React.FunctionComponent<Props> = (props: Props) => {

    const validateRaceId = (value: string) => {
        return firebase.firestore().collection(FIRESTORE.EVENTS_COL).doc(props.eventId)
            .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(props.smarterEventEdition.id)
            .collection(FIRESTORE.RACES_COL).doc(value).get()
            .then((docSN) => {
                return !docSN.exists
            })
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Nom requis')
            .min(2, 'Au moins 2 caractères'),
        id: Yup.string()
            .required('Identifiant requis')
            .min(2, 'Au moins 2 caractères')
            .test("race-id-taken", "Vous avez déjà une course avec cet identifiant", validateRaceId),
        // type: Yup.string()
        //     .required('Type requis'),
        sport: Yup.string()
            .required('Sport requis'),
        fromDate: Yup.date()
            .typeError("Date du début de la course requis")
            .required("Date du début de la course requis"),
        toDate: Yup.date()
            .typeError("Date de la fin de la course requis")
            .required("Date de la fin de la course requis"),
        registrations: Yup.object({
            startTime: Yup.date()
                .typeError("Date et heure du début des inscriptions requise")
                .required("Date et heure du début des inscriptions requise"),
            endTime: Yup.date()
                .typeError("Date et heure du fin des inscriptions requise")
                .required("Date et heure de fin des inscriptions requise")
        })
    });

    function dateTimeInputText(_value: any, originalValue: Date) {
        // console.log(date.toDate) 
        return moment(originalValue).format('YYYY-MM-DDTHH:mm').toString()
    }

    function dateInputText(_value: any, originalValue: Date) {
        // console.log(date.toDate) 
        return moment(originalValue).format('YYYY-MM-DD').toString()
    }

    const serializeSchema = validationSchema.shape({
        registrations: Yup.object(
            {
                startTime: Yup.string().transform(dateTimeInputText),
                endTime: Yup.string().transform(dateTimeInputText),
            }
        ),
        fromDate: Yup.string().transform(dateInputText),
        toDate: Yup.string().transform(dateInputText)
    });

    console.log(getDateFromTimestampOrDate(props.smarterEventEdition.fromDate!))

    const { register, handleSubmit, errors, watch } = useForm({
        defaultValues: serializeSchema.cast(
            {
                registrations: {
                    startTime: new Date(),
                    endTime: getDateFromTimestampOrDate(props.smarterEventEdition.fromDate!)
                },
                fromDate: getDateFromTimestampOrDate(props.smarterEventEdition.fromDate!),
                toDate: getDateFromTimestampOrDate(props.smarterEventEdition.toDate!)
            }),
        resolver: yupResolver(validationSchema)
    });

    var name = watch("name")
    var id = watch("id")

    return <Form errors={errors} register={register} yupSchema={validationSchema} onSubmit={handleSubmit(props.onSubmit)} id="raceForm">
        <Form.InputText id="name" label="Nom de la course" placeholder="Ma course" />
        <Form.InputText id="id" label="Identifiant" placeholder="ma-course" defaultValue={getIdFromName(name)} hint={id?.length >= 2 ? `L'adresse de votre course sera: ${props.eventId}.smarterevents.ch/${props.smarterEventEdition.id}${ROUTES.EVENTS_RACE}/${id}` : undefined} />
        <Form.InputSelect id="sport" label="Sport" values={[{ id: "cycling", name: "Cyclisme" }, { id: "climbing", name: "Ecalade" }, { id: "trail", name: "Trail" }, { id: "mountain-bike", name: "VTT" },]} />
        <Form.InputDateTime id="registrations.startTime" label="Ouverture des inscriptions" />
        <Form.InputDateTime id="registrations.endTime" label="Fermeture des inscriptions" />
        <Form.InputDate id="fromDate" label="Début de la course" />
        <Form.InputDate id="toDate" label="Fin de la course" />

        {/* <Form.Group>
            <Form.Label htmlFor="name"><span className="text-danger">*</span>{' '}Nom de la course</Form.Label>
            <Form.Control type="text" name="name" id="name"
                isInvalid={errors.name && true} 
                placeholder="Ma course"
                ref={register} />
            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="id"><span className="text-danger">*</span>{' '}Identifiant</Form.Label>
            <Form.Control type="text" name="id" id="id"
                isInvalid={errors.id && true} 
                placeholder="ma-course"
                defaultValue={getIdFromName(name)}
                ref={register} />
            <Form.Control.Feedback type="invalid">{errors.id?.message}</Form.Control.Feedback>
            {id?.length >= 2 ? <span>L'adresse de votre course sera: {props.eventId}.smarterevents.ch/{props.editionId}{ROUTES.EVENTS_RACE}/{id}</span> : null}
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="sport"><span className="text-danger">*</span>{' '}Sport</Form.Label>
                <Form.Control type="text" name="sport" id="sport" as="select" isInvalid={errors.sport && true} ref={register}>
                    <option value="cycling">Cyclisme</option>
                    <option value="climbing">Escalade</option>
                    <option value="trail">Trail</option>
                    <option value="vtt">VTT</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.sport?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="registrations.startTime"><span className="text-danger">*</span>{' '}Ouverture des inscriptions</Form.Label>
            <Form.Control
                id="registrations.startTime"
                isInvalid={errors.registrations?.startTime && true}
                type="datetime-local"
                name="registrations.startTime"
                ref={register}
            />
            <Form.Control.Feedback type="invalid">{errors.registrations?.startTime?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
            <Form.Label htmlFor="registrations.endTime"><span className="text-danger">*</span>{' '}Fermeture des inscriptions</Form.Label>
            <Form.Control
                id="registrations.endTime"
                isInvalid={errors.registrations?.endTime && true}
                type="datetime-local"
                name="registrations.endTime"
                ref={register}
            />
            <Form.Control.Feedback type="invalid">{errors.registrations?.endTime?.message}</Form.Control.Feedback>
        </Form.Group> */}

    </Form>

}

export default RaceForm