import firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Modal from '../../../components/Modal';
import { SmarterEventContext, SmarterEventEditionContext } from '../../../constants/context';
import * as FIRESTORE from '../../../constants/firestore';
import { RaceIndividual } from '../../../types/race';
import ParticipantCard from './ParticipantCard';

type Props = {
}

const CLMParticipantsManagement: React.FunctionComponent<Props> = (props: Props) => {

    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;

    const [filteredParticipants, setFilteredParticipants] = useState<RaceIndividual[]>([])

    const [startCountdown, setStartCountdown] = useState<boolean>(false)
    const [countdown, setCountdown] = useState<number>()
    const [participant, setParticipant] = useState<RaceIndividual>()
    const [searchFilter, setSearchFilter] = useState<string>()
    // const [showConfirmStart, setShowConfirmStart] = useState<boolean>(false)

    const [allParticipants] = useCollectionData<RaceIndividual>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_INDIVIDUALS_COL).orderBy("lastName"), { idField: "id" })


    var timeout: NodeJS.Timeout

    useEffect(() => {
        // eslint-disable-next-line 
        timeout = setTimeout(() => {
            if (countdown) {
                var newCountdown = countdown - 1000
                setCountdown(newCountdown);
                if (newCountdown <= 0) {
                    setStartCountdown(false)
                    participant!.status.race = "STARTED"
                    participant!.results = {
                        startedAt: firebase.firestore.FieldValue.serverTimestamp() as unknown as firebase.firestore.Timestamp,
                        linecrosses: [{
                            time: new Date(),
                            checkpoint: "DEPARTURE"
                        }]
                    }
                    const id = participant!.id
                    delete participant!.id
                    firebase.firestore()
                        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
                        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
                        .collection(FIRESTORE.RACE_INDIVIDUALS_COL).doc(id).update(participant!)
                }
            }
        }, 1000);
    }, [countdown, smarterEvent.id, smarterEventEdition.id])

    useEffect(() => {
        let data: RaceIndividual[] = []
        if (allParticipants != null) {
            data = allParticipants.filter((participant) => {
                return searchFilter === "" || searchFilter == null || (participant.firstName.toLowerCase().includes(searchFilter.toLowerCase()) || participant.lastName.toLowerCase().includes(searchFilter.toLowerCase()) || participant.bibNumber?.toString().includes(searchFilter))
            })
        }
        setFilteredParticipants(data)
    }, [searchFilter, allParticipants])

    const startParticipant = (participant: RaceIndividual) => {
        setParticipant(participant)
        // setShowConfirmStart(false)
        setStartCountdown(true)
        setCountdown(5000)
        // setStartConfirmed(false)
    }

    return <Fragment>
        <h1>Contre la montre</h1>
        <section>
            <div className="searchBox">
                <label htmlFor="searchInput" />Chercher: <input id="searchInput" type="text" onChange={(value) => setSearchFilter(value.target.value)} />
            </div>
            <Card.Deck>
                {filteredParticipants.map((participant: RaceIndividual) => {
                    if (participant.epc != null) {
                        return <ParticipantCard key={participant.id} participant={participant} actions={[{ text: "Départ", action: () => { startParticipant(participant) } }]} />
                    } else {
                        return null
                    }
                })}
            </Card.Deck>
        </section>

        <Modal show={startCountdown}>
            <Modal.Header>Départ imminent</Modal.Header>
            <Modal.Body>
                <div>Départ dans: {countdown! / 1000}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" onClick={() => { setStartCountdown(false); clearTimeout(timeout) }}>Annuler</Button>
                {/* <Button type="submit" color="primary" form="menuItemForm" onClick={() => { reinitializeCategory(activeCategory) }}>Oui</Button> */}
            </Modal.Footer>
        </Modal>
        {/* <Modal show={showConfirmStart}>
            <Modal.Header>Démarrer</Modal.Header>
            <Modal.Body>
                <div>({participant?.bibNumber}) {participant?.firstName} {participant?.lastName} doit-il redemarrer ?</div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" color="link" onClick={() => setShowConfirmStart(false)}>Annuler</Button>
                <Button type="submit" color="primary" form="menuItemForm" onClick={() => { startParticipant(participant) }}>Oui</Button>
            </Modal.Footer>
        </Modal> */}
    </Fragment>

}

export default CLMParticipantsManagement