import classnames from 'classnames';
import { useState } from "react";
import Burger from "../components/Burger";

type Props = {
    isAdmin?: boolean
}


const HomepageHeader: React.FunctionComponent<Props> = (props: Props) => {

    const [menuOpen, setMenuOpen] = useState<boolean>(false)// 


    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const closeMenu = () => {
        setMenuOpen(false)
    }

    return <header className="homepage">
        <nav id="topbar-nav" className={classnames({ "menu-active": menuOpen })}>
            <img id="logo" src="/images/logo/logo_oxford_blue.svg" alt="Logo" />
            <Burger menuOpen={menuOpen} toggleMenu={toggleMenu} />
            <ul id="topbar-menu">
                <li className="nav-item"><a href="#description" onClick={closeMenu}>Description</a></li>
                <li className="nav-item"><a href="#features" onClick={closeMenu}>Fonctionalités</a></li>
                <li className="nav-item"><a href="#pricing" onClick={closeMenu}>Tarifs</a></li>
            </ul>
        </nav>
        <section id="splash-screen">
            <div className="spash-screen-text">
                <h1>Soyez SMART</h1>
                <h2>Gestion intelligente de vos événements</h2>
            </div>
            {/* <div className="cta">
                <a href={getConsoleURL()}><Button>Essayez gratuitement SmarterEvents</Button></a>
            </div> */}
        </section>
    </header>
}

export default HomepageHeader