import React, { Fragment } from "react";
import Card from '../../../components/Card';
import { SoloCategory } from "../../../types/race";
import SoloCategoryCard from "./SoloCategoryCard";

type Props = {
    categories: SoloCategory[],
    handleRegistrations: {function: (category: SoloCategory) => void, buttonText:string}[]
}

const SoloCategoriesList: React.FunctionComponent<Props> = (props: Props) => {
    return <Fragment>
        <h2>Catégories solo</h2>
        <Card.Deck>
            {(props.categories.length === 0) ? <p>Aucune catégorie solo</p> :
                props.categories.map((category: SoloCategory) => {
                    return <SoloCategoryCard key={category.id} handleRegistrations={props.handleRegistrations} category={category} />
                })}
        </Card.Deck>
    </Fragment>
}

export default SoloCategoriesList