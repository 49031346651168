export const SPORTS: Record<string, any>={
    cycling: {
        name: "Cyclisme"
    },
    climbing: {
        name: "Escalade"
    },
    mountainBike: {
        name: "VTT"
    },
    paragliding: {
        name: "Parapente"
    },
    trailRunning: {
        name: "Trail"
    },
    running: {
        name: "Course à pied"
    }

}