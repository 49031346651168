import firebase from "firebase/app";
import "firebase/firestore";
import React, { useContext } from 'react';
import { Cart2 } from "react-bootstrap-icons";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import { SmarterEventContext, SmarterEventEditionContext, UserProfileContext } from "../constants/context";
import * as FIRESTORE from '../constants/firestore';
import * as ROUTES from '../constants/routes';
import { CartItem } from "../types/smarterEvent";

type Props = {
    userId: string
    smarterEventId: string
    smarterEventEditionId: string
}

const CartButton: React.FunctionComponent<Props> = (props: Props) => {

    const smarterEvent = useContext(SmarterEventContext);
    const smarterEventEdition = useContext(SmarterEventEditionContext);
    const userProfile = useContext(UserProfileContext);

    const [cartItems] = useCollectionData<CartItem>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent!.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition!.id)
        .collection(FIRESTORE.CARTS_COL).doc(userProfile?.id)
        .collection(FIRESTORE.CARTS_ITEMS_COL))

    const { smarterEventEditionId } = props;

    if (cartItems && cartItems.length !== 0)
        return <Link to={`/${smarterEventEditionId}${ROUTES.USER_CART}`}>
            <span className="cart"><Cart2 size="1.5rem" color="#7e8c82" fontWeight="bold" /><span className="badge">{cartItems.length}</span></span>
        </Link>
    else return null
}

export default CartButton
