export const PAYMENT_MODE_ONSITE = 'ONSITE';

// export const BILL_OPEN = "OPEN";
// export const BILL_BILLED = 'billed';
// export const BILL_PAID = 'PAID';

export const EVENT_EDITION_UNLISTED = "UNLISTED"
export const EVENT_EDITION_PUBLIC = "PUBLIC"

// export const PARTICIPANT_INCART = "INCART";
// export const PARTICIPANT_BILLED = "BILLED";
// export const PARTICIPANT_PAID = "PAID";

export enum BILL_STATUS {
    OPEN = "OPEN",
    PAID = "PAID"
}

export enum PARTICIPANT_BILLING_STATUS {
    BILLED = "BILLED",
    PAID = "PAID"
}