import firebase from 'firebase/app';
import 'firebase/firestore';
import * as FIRESTORE from '../../../constants/firestore';
import { ParticipantCartItem, Race, SoloCategory } from "../../../types/race";
import { SmarterEventEdition, UserProfile } from "../../../types/smarterEvent";
import { newUser } from "../../user/SignUp";
import RegistrationBaseForm, { getParticipantCartItem, getRegistrationFormDataFromUserProfile, RegistrationFormData } from "./RegistrationBaseForm";

type Props = {
    smarterEventEdition: SmarterEventEdition,
    race: Race,
    category: SoloCategory,
    userProfile?: UserProfile | null,
    handleAddParticipantInCart: (participantCartItem: ParticipantCartItem, createdBy: string) => void
}

const RegistrationAddMyselfForm: React.FunctionComponent<Props> = (props: Props) => {

    const { smarterEventEdition, race, category, userProfile } = props


    const getUserProfile = (registrationFormData: RegistrationFormData) => {
        var userProfile: Partial<UserProfile> = {
            address: registrationFormData.address,
            dateOfBirth: registrationFormData.dateOfBirth,
            gender: registrationFormData.gender,
            phoneNumber: registrationFormData.phoneNumber
        }
        return userProfile
    }

    const updateUserProfile = (registrationFormData: RegistrationFormData) => {
        firebase.firestore().collection(FIRESTORE.USERS_COL).doc(userProfile!.id).update(getUserProfile(registrationFormData))
    }

    const updateExistingUserAndAddToCart = (registrationFormData: RegistrationFormData) => {
        updateUserProfile(registrationFormData)
        props.handleAddParticipantInCart(getParticipantCartItem(race, category, registrationFormData, userProfile!.id!), userProfile!.id!)
    }

    const createUserAndAddToCart = (registrationFormData: RegistrationFormData) => {
        return newUser(
            registrationFormData.email,
            registrationFormData.password!,
            registrationFormData.firstName,
            registrationFormData.lastName,
            registrationFormData.gender,
            registrationFormData.dateOfBirth,
            registrationFormData.address,
            registrationFormData.phoneNumber)
            .then((userId) => {
                props.handleAddParticipantInCart(getParticipantCartItem(race, category, registrationFormData, userId), userId)
            })
            .catch((error) => {
                if (error.code === "auth/email-already-in-use") {
                    console.log("Email already exists")
                    // The account already exists
                    // if (props.handleAccountAlreadyExists)
                    //     props.handleAccountAlreadyExists()
                } else {
                    console.log(error.message)
                }
            })
    }

    if (props.userProfile == null) {
        // User isn't login, he'll create an account
        return <RegistrationBaseForm id="participantForm" smarterEventEdition={smarterEventEdition} category={category} newUserAccount={true} restrictEditingUserInfo={false} handleSubmit={createUserAndAddToCart} />
    } else {
        // User is logged in
        return <RegistrationBaseForm id="participantForm" smarterEventEdition={smarterEventEdition} category={category} newUserAccount={false} restrictEditingUserInfo={true} defaultValues={getRegistrationFormDataFromUserProfile(userProfile!)} handleSubmit={updateExistingUserAndAddToCart} options={race.options}/>
    }
}

export default RegistrationAddMyselfForm