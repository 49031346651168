import React from 'react'
import '../assets/stylesheets/components/modal.scss'

type ChildrenProps = {
    children: any
}

export type ModalProps = {
    show: boolean,
    children: any
}

interface ModalSubComponents {
    Header: React.FunctionComponent<ChildrenProps>
    Body: React.FunctionComponent<ChildrenProps>
    Footer: React.FunctionComponent<ChildrenProps>
}

const Modal: React.FunctionComponent<ModalProps> & ModalSubComponents = (props: ModalProps) => {
    if (props.show)
        return <div className="modal__bg">
            <div className="modal">
                {props.children}
            </div>
        </div>
    else
        return null
}

const Header = (props: ChildrenProps) => {
    return <div className="modal__header">
        {props.children}
    </div>
}
const Body = (props: ChildrenProps) => {
    return <div className="modal__body">
        {props.children}
    </div>
}

const Footer = (props: ChildrenProps) => {
    return <div className="modal__footer">
        {props.children}
    </div>
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer

export default Modal
