import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import slugify from "slugify";


export const getDateFromTimestampOrDate = (date: firebase.firestore.Timestamp | Date) => {
    if (date == null) { return null }
    if ("toDate" in date) {
        return date.toDate();
    }
    return date
}

export const getDurationStringFromMS = (durationInMS: number) => {
    return moment.duration(durationInMS).format("h:m'ss\"")
}

export const upperCaseFirstLetter = (text: string) => {
    return text.trim().charAt(0).toUpperCase() + text.trim().toLowerCase().slice(1);
}

export const dateFromString = (dateString: string) => {
    return moment(dateString, "D/M/YYYY", true).toDate()
}

export const stringFromDate = (date: Date | undefined) => {
    if (date === undefined) return undefined
    return moment(date).format("DD/MM/YYYY").toString()
}

export const stringFromTime = (date: Date | undefined) => {
    if (date === undefined) return undefined
    return moment(date).format("LTS")
}

export const pad = (num: number, size: number): string => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export const getSubdomain = () => {
    let host = window.location.host;
    let parts = host.split(".");
    if (parts.length >= 3) {
        return parts[0];
    }
}

export const getDomain = (trimPort?: true) => {
    let host = window.location.host;
    let parts = host.split(".");
    if (trimPort && host.indexOf(":") !== -1)
        return parts[parts.length - 2] + "." + parts[parts.length - 1].substring(0, parts[parts.length - 1].indexOf(":"));
    else
        return parts[parts.length - 2] + "." + parts[parts.length - 1];
}

export const getConsoleURL = () => {
    return window.location.protocol + "//console." + getDomain()
}

export const getIdFromName = (text: string) => {
    if (text != null) {
        var str = text.trim();
        return slugify(
            str,
            {
                replacement: '-',
                remove: undefined,
                lower: true,
                strict: false,
                locale: 'fr'
            }
        );
    }
}