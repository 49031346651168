import { Fragment } from 'react';
import { RaceIndividual } from '../../../types/race';

export type RacerStatusProps = {
    participant: RaceIndividual,
}

const RacerStatus: React.FunctionComponent<RacerStatusProps> = (props: RacerStatusProps) => {

    if (props.participant.results?.startedAt != null) {
        if (props.participant.results?.finishedAt != null) {
            return <Fragment>Arrivé</Fragment>
        } else {
            return <Fragment>Parti</Fragment>
        }
    } else {
        return <Fragment>Non parti</Fragment>
    }
}

export default RacerStatus