import firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext, useState } from 'react';
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useGlobalFilter, useTable } from 'react-table';
import Button from "../../components/Button";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import { SmarterEventContext, SmarterEventEditionContext } from "../../constants/context";
import * as FIRESTORE from '../../constants/firestore';
import { RaceIndividual } from "../../types/race";
import AddTeamModal from "./components/AddTeamModal";
import AddUserModal from "./components/AddUserModal";
import CheckInForm from './components/CheckInForm';

const CheckInPage: React.FunctionComponent = () => {

    const smarterEvent = useContext(SmarterEventContext)!;
    const smarterEventEdition = useContext(SmarterEventEditionContext)!;

    const [checkInParticipantModal, setCheckInParticipantModal] = useState<{ isOpen: boolean, raceIndividual: RaceIndividual | null }>({ isOpen: false, raceIndividual: null });
    const [addUserModal, setAddUserModal] = useState<boolean>(false);
    const [addTeamModal, setAddTeamModal] = useState<boolean>(false);

    const [raceIndividuals] = useCollectionData<RaceIndividual>(firebase.firestore()
        .collection(FIRESTORE.EVENTS_COL).doc(smarterEvent.id)
        .collection(FIRESTORE.EVENTS_EDITIONS_COL).doc(smarterEventEdition.id)
        .collection(FIRESTORE.RACE_INDIVIDUALS_COL)
        , { idField: "id" });

    const filterTypes = React.useMemo(() => ({
        text: (rows: any, id: any, filterValue: string) => {
            return rows.filter((row: any) => {
                const rowValue = row.id 
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
        },
    }),
        []
    )


    const columns = React.useMemo(
        () => [
            {
                Header: 'Dossard',
                accessor: 'bibNumber',
            },
            {
                Header: 'Prénom',
                accessor: 'firstName',
            },
            {
                Header: 'Nom',
                accessor: 'lastName',
            },
            {
                Header: 'Genre',
                accessor: 'gender',
            },
            {
                Header: 'Statut',
                accessor: 'billingStatus',
            },
            {
                Header: 'Actions',
                accessor: 'buttons',
            }

        ],
        []
    )

    const data: any[] = React.useMemo(
        () => {

            if (raceIndividuals == null) return []

            const convertParticipant = (raceIndividual: RaceIndividual) => {
                var data: any = {}
                data.bibNumber = raceIndividual.bibNumber
                data.firstName = raceIndividual.firstName
                data.lastName = raceIndividual.lastName
                data.gender = raceIndividual.gender === "male" ? "Homme" : raceIndividual.gender === "female" ? "Femme" : "???"
                data.billingStatus = raceIndividual.status.billing
                data.buttons = <Button

                    onClick={() => setCheckInParticipantModal({ isOpen: true, raceIndividual: raceIndividual })}>Check-in</Button>
                /* {newRegistration != null ? <Button onClick={() => newRegistration(category)}>S'inscrire</Button> : null}
                {editCategory ? <Button onClick={() => editCategory(category)}>Editer</Button> : null}
                {deleteCategory ? <Button onClick={() => deleteCategory(category)}>Effacer</Button> : null} */
                return data
            }

            return raceIndividuals.map((raceIndividual: RaceIndividual) => {
                return convertParticipant(raceIndividual)
            })
        }
        , [raceIndividuals]
    )
    const { headerGroups, rows, prepareRow, setGlobalFilter } = useTable({
        columns,
        data,
        filterTypes,
    }, useGlobalFilter)

    const handleSubmit = () => {
        setCheckInParticipantModal({ isOpen: false, raceIndividual: checkInParticipantModal.raceIndividual })
    }

    const handleCancel = () => {
        setAddUserModal(false)
        setAddTeamModal(false)
    }

    return <Fragment>
        <h1>Check in participants</h1>
        <div className="buttonBox">
            <Button onClick={() => {setAddUserModal(true)}}>Nouveau participant</Button>
            <Button onClick={() => {setAddTeamModal(true)}}>Nouvelle équipe</Button>
        </div>
        <Card>
            <Card.Body>
                <div className="searchBox">
                    <label htmlFor="searchInput" />Chercher: <input id="searchInput" type="text" onChange={(value) => setGlobalFilter(value.target.value)} />
                </div>
                <table>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} style={{ verticalAlign: "middle" }}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
        {/* <CheckInList lines={lines} /> */}
        <Modal show={checkInParticipantModal.isOpen}>
            <Modal.Header><h1>Check-in</h1></Modal.Header>
            <Modal.Body>
                <CheckInForm
                    smarterEventId={smarterEventEdition.references.eventId}
                    smarterEventEditionId={smarterEventEdition.id}
                    raceIndividual={checkInParticipantModal.raceIndividual!}
                    handleSubmit={handleSubmit} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={() => setCheckInParticipantModal({ isOpen: false, raceIndividual: checkInParticipantModal.raceIndividual })}>Fermer</Button>
                <Button type="submit" form="checkInForm">Enregistrer</Button>
            </Modal.Footer>
        </Modal>
        <AddUserModal show={addUserModal} handleCancel={handleCancel} handleSubmit={(raceIndividual) => {handleCancel(); setCheckInParticipantModal({ isOpen: true, raceIndividual: raceIndividual })}}/>
        <AddTeamModal show={addTeamModal} handleCancel={handleCancel}/>
    </Fragment>

}

export default CheckInPage
